import styled from 'styled-components';
import VerifyPageContainer from '../../components/VerifyPageContainer';
import PageLoading from '../../components/PageLoading';

const StyledOAuthCallbackPage = styled(VerifyPageContainer)`
  & h1 {
    margin-top: calc(37vh - 106px);
  }
  .redirect-description {
    font-size: 20px;
    padding-top: 22px;
  }
  
  @media (max-width: 767px) {
    & h1 {
      margin-top: 60px;
    }
    
    .redirect-description {
      font-size: 16px;
    }
  }
`;

const StyledOAuthCallbackPageLoading = styled(PageLoading)`
  left: 0;
`;

export {
  StyledOAuthCallbackPage,
  StyledOAuthCallbackPageLoading
}