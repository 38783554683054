import React from 'react';

const HomeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.5834 1.47946C7.82688 1.28468 8.17285 1.28468 8.41633 1.47946L15.083 6.81279C15.3705 7.0428 15.4171 7.46233 15.1871 7.74984C14.9571 8.03734 14.5376 8.08396 14.2501 7.85395L13.6665 7.38712V14C13.6665 14.3682 13.3681 14.6667 12.9999 14.6667H2.99986C2.63167 14.6667 2.3332 14.3682 2.3332 14V7.38712L1.74966 7.85395C1.46215 8.08396 1.04262 8.03734 0.812616 7.74984C0.58261 7.46233 0.629224 7.0428 0.916732 6.81279L7.5834 1.47946ZM6.33317 9.00004C5.96498 9.00004 5.6665 9.29852 5.6665 9.66671V13.3334H10.3332V9.66671C10.3332 9.29852 10.0347 9.00004 9.6665 9.00004H6.33317Z"
          fill="#262626"/>
  </svg>
);

export default HomeIcon;
