import styled from 'styled-components';
import {Button, Segmented, Space} from 'antd';
import CopyField from '../../../../../CopyField';

const StyledLegalRepresentativeInformation = styled(Space)`
  width: 100%;
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .ant-space {
    width: 100%;
  }
`;

const StyledLegalRepresentativeInformationSegmented = styled(Segmented)`
  &.ant-segmented.ant-segmented-lg {
    background-color: ${props => props.theme.colors.neutral_4};
    padding: 4px;
    .ant-segmented-item-label {
      line-height: 64px;
      min-height: 64px;
    }
  }
  .ant-segmented-item-selected {
    color: ${props => props.theme.colors.primary_6};
  }
`;

const StyledLegalRepresentativeInformationCopyField = styled(CopyField)`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_5};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 24px 16px;
`;

const StyledLegalRepresentativeInformationLinkButton = styled(Button)`
  display: block;
  margin: 24px auto;
`;

export {
  StyledLegalRepresentativeInformation,
  StyledLegalRepresentativeInformationSegmented,
  StyledLegalRepresentativeInformationCopyField,
  StyledLegalRepresentativeInformationLinkButton
};
