import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {useNavigate} from 'react-router-dom';
import {StyledNotFoundPage} from './StyledNotFoundPage';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import {ReactComponent as ErrorSvgImage} from '../../static/images/pages/errors/404.svg';
import {history} from '../../state/history';

const NotFoundPage = ({...rest}) => {
  const [trans] = useTranslation(['settings', 'main']);
  const navigate = useNavigate();

  const t = (key) => trans(`pages.notFound.${key}`);

  const onBack = () => history.action.toLowerCase() !== 'pop' ? navigate(-1) : navigate('/');

  const title = t('title');

  return (
    <StyledNotFoundPage {...rest}>
      <PageDocumentDetails title={trans('main:pageTitles.notFound')} />
      <Space
        align='center'
        direction='vertical'
        size='middle'
      >
        <ErrorSvgImage />
        <h1>{title}</h1>
        <p>{t('description')}</p>
        <Button
          className='green-btn'
          onClick={onBack}
          size='large'
        >
          {t('button')}
        </Button>
      </Space>
    </StyledNotFoundPage>
  );
}

export default NotFoundPage;
