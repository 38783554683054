import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useLastLocation} from 'react-router-dom-last-location';
import {StyledSubscriptionTabs} from './StyledSubscriptionTabs';
import {
  backendWebsocketActionsConstants,
  subscriptionPaymentTypesConstants,
  subscriptionViewTypesConstants,
  SCAActionsConstants,
  subscriptionRowActionConstants,
} from '../../../../constants';
import AddButton from '../AddButton';
// import AllTab from '../tabs/AllTab';
import AuthorizedTab from '../tabs/AuthorizedTab';
import NeedReviewTab from '../tabs/NeedReviewTab';
import DeletedTab from '../tabs/DeletedTab';
import AddSingleSubscriptionModal from '../AddSingleSubscriptionModal/AddSingleSubscriptionModal';
import AddMultipleSubscriptionsModal from '../AddMultipleSubscriptionsModal/AddMultipleSubscriptionsModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import EmptySubscriptions from '../EmptySubscriptions';
import DeleteModal from '../../SubscriptionPage/DeleteModal';
import AuthorizeSubscriptionModal from '../AuthorizeSubscriptionModal';
import WebSocket from '../../../WebSocket';
import {useIsMount} from '../../../../hooks';
import {cardActions, serviceStatsActions, subscriptionActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';
import {tabHelpers} from '../../../../tabHelpers';
import routes from '../../../../routes/routes.json';
import {scaHelpers} from '../../../../scaHelpers';
import {subscriptionsHelpers} from '../subscriptionsHelpers';

const {ALL, AUTHORIZED, DELETED, NEED_REVIEW, RESTRICTED, UNMANAGED} = subscriptionViewTypesConstants;
const tabKeys = {
  ALL: 'ALL',
  AUTHORIZED: 'AUTHORIZED',
  DELETED: 'DELETED',
  NEED_REVIEW: 'NEED_REVIEW',
  RESTRICTED: 'RESTRICTED',
  UNMANAGED: 'UNMANAGED'
}

const {CARD} = subscriptionPaymentTypesConstants;

const {
  SUBSCRIPTION_CREATION_WITH_CARD_ACTION,
  SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION,
  SUBSCRIPTION_DELETE,
  SUBSCRIPTION_SUBMIT,
  SUBSCRIPTION_SUBMIT_IN_CREATION,
} = SCAActionsConstants;

const {AUTHORIZE, DELETE, IGNORE, RESTRICT, VIEW} = subscriptionRowActionConstants;

const perPagePagination = 25;

const gObjProp = helpers.getObjProp;

const defaultTableData = {
  loaded: false,
  loadedPages: 1,
  pagination: null,
  page: 1,
  rows: []
}

const defaultQuery = {
  order_by: 'created_at',
  per_page: perPagePagination,
}

const defaultTabKey = tabKeys.AUTHORIZED.toLowerCase();
const defaultView = AUTHORIZED;

const defaultModalProps = {loading: false, open: false};

const SubscriptionTabs = ({
  dispatch,
  createSubscription,
  deleteSubscription,
  bulkCreateSubscriptions,
  getCompanyDetails,
  getSubscriptions,
  getSuggestServices,
  getDiscoveredSubscriptions,
  updateSubscription,
  subscriptionQuickAction,
  handleUpdateSubscriptionTotal,
  employees,
  isAdmin,
  total,
  totalLoading,
  storeSubscriptions,
  storedSubscriptions,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {lastLocation} = useLastLocation();
  const [t] = useTranslation(['main', 'subscriptions']);
  const [activeTabKey, setActiveTabKey] = useState(helpers.getHash(location) || defaultTabKey);
  const [tableData, setTableData] = useState({
    [ALL]: defaultTableData,
    [AUTHORIZED]: defaultTableData,
    [DELETED]: defaultTableData,
    [NEED_REVIEW]: defaultTableData,
    [RESTRICTED]: defaultTableData,
    [UNMANAGED]: defaultTableData
  });
  const [queries, setQueries] = useState({
    [ALL]: defaultQuery,
    [AUTHORIZED]: defaultQuery,
    [DELETED]: defaultQuery,
    [NEED_REVIEW]: defaultQuery,
    [RESTRICTED]: defaultQuery,
    [UNMANAGED]: defaultQuery
  });
  const [loading, setLoading] = useState({
    [ALL]: false,
    [AUTHORIZED]: false,
    [DELETED]: false,
    [NEED_REVIEW]: false,
    [RESTRICTED]: false,
    [UNMANAGED]: false
  });
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [singleModalProps, setSingleModalProps] = useState(defaultModalProps);
  const [multipleModalProps, setMultipleModalProps] = useState(defaultModalProps);
  const [storedSingleSubscriptionData, setStoredSingleSubscriptionData] = useState(null);
  const [storedMultipleSubscriptionsData, setStoredMultipleSubscriptionsData] = useState([]);
  const [activeAuthAction, setActiveAuthAction] = useState(null);
  const [websocketWaitSubscriptions, setWebsocketWaitSubscriptions] = useState([]);
  const [tempData, setTempData] = useState({
    action: undefined,
    data: undefined,
    subscription: undefined,
    view: undefined
  });
  const [deleteModalProps, setDeleteModalProps] = useState(defaultModalProps);
  const [subsAuthorizeModalProps, setSubsAuthorizeModalProps] = useState(defaultModalProps);
  const isMounted = !useIsMount();

  // enable websocket connection only when some subscriptions in wait status
  const enabledWebSocketConnection = useMemo(() => websocketWaitSubscriptions.length > 0, [websocketWaitSubscriptions]);

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const isExistsSubscriptions = useMemo(() => {
    let exist = total && total.all > 0;
    if (!exist) {
      exist = Object.keys(tableData).map(key => tableData[key]).map(o => o.rows.length).some(o => o > 0);
    }
    return exist;
  }, [total, tableData]);

  useEffect(() => {
    if (lastLocation && lastLocation.pathname === location.pathname) return;
    if (!isMounted && lastLocation === null) return;
    let view = getActiveView();

    if (lastLocation) {
      let detailsPath = routes.subscriptionDetails;
      let detailsPreIdRoute = detailsPath.slice(0, detailsPath.lastIndexOf('/') + 1);
      try {
        if (lastLocation.pathname.startsWith(detailsPreIdRoute) && storedSubscriptions) {
          const viewName = helpers.getHash(location) || defaultTabKey;
          const view = subscriptionViewTypesConstants[viewName.toUpperCase()];
          setTableData(storedSubscriptions.tableData);
          setQueries(storedSubscriptions.queries);

          const finishTableLoading = () => setLoading({...loading, [view]: false});

          setLoading({...loading, [view]: true});

          const viewQuery = storedSubscriptions.queries[view];
          getSubscriptions(
            {...viewQuery, view},
            (response) => {
              const updatedTableData = tabHelpers.handleStoreStoredTableData({
                tableData: storedSubscriptions.tableData,
                setTableData,
                view,
                perPagePagination,
                response,
                rowsKey: 'subscriptions'
              });
              storeSubscriptions({
                ...storedSubscriptions,
                tableData: updatedTableData
              });
              finishTableLoading();
            },
            finishTableLoading
          )
          return;
        }
      } catch (e) {
        const viewName = helpers.getHash(location) || defaultTabKey;
        view = subscriptionViewTypesConstants[viewName.toUpperCase()];
        setLoading({...loading, [view]: true});
      }
    }
    loadData({initial: true, view});
  }, [lastLocation, isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getCompanyDetails(), [getCompanyDetails]);

  const loadData = ({
    view,
    query = null,
    initial = false,
    placeholderSubscriptions = [],
    latestTableData = undefined,
    loadingCompleteCallback = undefined
  }) => {
    const viewQuery = tabHelpers.getViewQuery({initial, query, queries, view});
    const updatedQueries = {
      ...queries,
      [view]: viewQuery
    };

    setQueries(updatedQueries);
    setLoading({...loading, [view]: true});

    const finishTableLoading = () => setLoading({...loading, [view]: false});

    getSubscriptions(
      {
        ...viewQuery,
        view
      },
      (response) => {
        const rowsKey = 'subscriptions';
        const placeholderSubscriptionsIds = placeholderSubscriptions.map(s => s.id);
        response = {
          ...response,
          [rowsKey]: response[rowsKey].map(s => placeholderSubscriptionsIds.includes(s.id)
            ? getSubscriptionPlaceholder({owner: s.owner, service: s.service, id: s.id}) : s)
        }
        const updatedTableData = tabHelpers.handleStoreTableData({
          tableData: latestTableData || tableData, setTableData, view, response, initial, rowsKey
        });
        storeSubscriptions({
          tableData: updatedTableData,
          queries: updatedQueries
        });

        if (loadingCompleteCallback) {
          loadingCompleteCallback();
        } else {
          finishTableLoading();
        }
      },
      finishTableLoading
    )
  }

  const getLabel = (key) => tabHelpers.getLabel({key, total, t: trans});

  const handleOnPaginate = (view, activePage) =>
    tabHelpers.handleOnPaginate({view, activePage, tableData, setTableData, loadDataFunc: loadData})

  const handleOnFilter = (view, filterQuery) =>
    tabHelpers.handleOnFilter({view, filterQuery, loadDataFunc: loadData, queries, setQueries});

  const handleOnTableChange = (view, pagination, filters, sorter, extra) =>
    tabHelpers.handleOnTableChange({view, pagination, filters, sorter, extra, loadDataFunc: loadData});

  const getTableDataValue = (view, key, defaultValue) => tableData.hasOwnProperty(view) ? tableData[view][key] : defaultValue;

  const getFilterFields = (view) => {
    const fields = ['search', 'tags'];
    if (isAdmin && view !== NEED_REVIEW) fields.push('owners');
    return fields;
  }

  const handleOnRowEdit = (view, {id, data, successCallback, errorCallback}) => {
    updateSubscription({
      id,
      data,
      successCallback: (subscription) => {
        const viewTableData = tableData[view];
        setTableData({
          ...tableData,
          [view]: {
            ...viewTableData,
            rows: viewTableData.rows.map(row => row.id === id ? subscription : row)
          }
        });
        successCallback && successCallback();
      },
      errorCallback
    });
  }

  const getTabProps = (key, totalKey) => {
    const data = getTableDataValue(key, 'rows', []);
    return {
      data,
      defaultQuery: queries.hasOwnProperty(key) ? queries[key] : {},
      handleAction: (props) => handleAction(key, props),
      onFilter: (value) => handleOnFilter(key, value),
      filterProps: {
        filterFields: getFilterFields(key),
        rightSideContent: addButton,
      },
      tableProps: {
        tableOrder: tabHelpers.getTableOrder(key, queries),
        loading: loading[key] || false,
        handleAction: (props) => handleAction(key, props),
        onChange: (pagination, filters, sorter, extra) => handleOnTableChange(key, pagination, filters, sorter, extra),
        onRowEdit: (data) => handleOnRowEdit(key, data),
        pagination: {
          current: getTableDataValue(key, 'page', 1),
          disabled: data.length === 0,
          onChange: (page) => handleOnPaginate(key, page),
          pageSize: perPagePagination,
          showSizeChanger: false,
          total: total && total.hasOwnProperty(totalKey) ? total[totalKey] : undefined
        },
        expandable: key !== AUTHORIZED && subscriptionsHelpers.getExpandableProps()
      }
    }
  }

  const handleSubscriptionQuickAction = ({action, authAction, data, id, errorCallback, successCallback, view} = {}) => {
    (action === DELETE ? deleteSubscription : subscriptionQuickAction)({
      action,
      headers: authAction ? scaHelpers.getAuthHeaders(authAction) : undefined,
      id,
      data,
      successCallback: () => {
        const activeView = view || getActiveView();
        const viewTableData = tableData[activeView];
        const latestTableData = {
          ...tableData,
          [activeView]: {
            ...viewTableData,
            rows: viewTableData.rows.filter(row => row.id !== id)
          }
        };
        setTableData(latestTableData);
        successCallback && successCallback();

        // update list of subscriptions in specific tab after subscription forget/authorize actions
        const views = {
          [ALL]: ALL,
          [AUTHORIZE]: AUTHORIZED,
          [DELETE]: DELETED,
          [IGNORE]: UNMANAGED,
          [RESTRICT]: RESTRICTED
        };
        if (views.hasOwnProperty(action)) {
          loadData({
            initial: true,
            view: views[action],
            latestTableData,
            loadingCompleteCallback: () => {
              loadData({
                initial: true,
                view: defaultView,
                latestTableData
              });
            }
          });
        }

        // update total with timeout, backend has some delay to update counters
        setTimeout(() => updateSubscriptionTotal(), 2000);
      },
      errorCallback: (response) => {
        scaResponseCallback({
          response,
          errorCallback: () => {
            setSingleModalProps({...singleModalProps, loading: false});
            errorCallback && errorCallback(response);
          }
        });
      }
    })
  }

  const handleAction = (view, {action, id, e, successCallback}) => {
    const subscription = tableData[view].rows.find(row => row.id === id);
    e.stopPropagation();

    if (action) action = action.toUpperCase();

    const quickAction = () => handleSubscriptionQuickAction({action, id, successCallback, view});

    const handleOpenDeleteModal = () => {
      setTempData({...tempData, action, subscription, view});
      setDeleteModalProps({
        ...deleteModalProps,
        loading: false,
        open: true,
      });
    }

    const handleOpenAuthorizeModal = () => {
      setTempData({...tempData, action, subscription, view});
      setSubsAuthorizeModalProps({
        ...subsAuthorizeModalProps,
        loading: false,
        open: true,
      });
    }

    const actions = {
      [AUTHORIZE]: handleOpenAuthorizeModal,
      [DELETE]: handleOpenDeleteModal,
      [IGNORE]: quickAction,
      [RESTRICT]: quickAction,
      [VIEW]: () => navigate(`${routes.subscriptionsList}/${id}`)
    }
    if (actions.hasOwnProperty(action)) actions[action]();
  }

  const getTabs = () => {
    return [
      // {
      //   key: 'all',
      //   label: getLabel('all'),
      //   children: (
      //     <AllTab
      //       {...getTabProps(ALL, 'all')}
      //     />
      //   )
      // },
      {
        key: 'authorized',
        label: getLabel('authorized'),
        children: (
          <AuthorizedTab
            {...getTabProps(AUTHORIZED, 'authorized')}
          />
        )
      },
      {
        key: 'need_review',
        label: getLabel('need_review'),
        children: (
          <NeedReviewTab
            {...getTabProps(NEED_REVIEW, 'need_review')}
          />
        )
      },
      {
        key: 'restricted',
        label: getLabel('restricted'),
        children: (
          <NeedReviewTab
            rowActions={[AUTHORIZE, IGNORE, DELETE]}
            {...getTabProps(RESTRICTED, 'restricted')}
          />
        )
      },
      {
        key: 'unmanaged',
        label: getLabel('unmanaged'),
        children: (
          <NeedReviewTab
            rowActions={[AUTHORIZE, RESTRICT, DELETE]}
            {...getTabProps(UNMANAGED, 'unmanaged')}
          />
        )
      },
      {
        key: 'deleted',
        label: getLabel('deleted'),
        children: (
          <DeletedTab
            {...getTabProps(DELETED, 'deleted')}
          />
        )
      }
    ]
  }

  const getActiveView = () => subscriptionViewTypesConstants[activeTabKey.toUpperCase()] || undefined;

  const getSubscriptionCreationView = () => {
    let view = getActiveView();
    if (![ALL, AUTHORIZED].includes(view)) {
      view = defaultView;
      updateTab(defaultTabKey);
    }
    return view;
  }

  const onChangeTab = (key) => {
    const view = subscriptionViewTypesConstants[key.toUpperCase()];
    updateTab(key);

    if (!getTableDataValue(view, 'loaded', false)) {
      loadData({view, initial: true});
    }
  }

  const updateTab = (key) => {
    navigate(`${location.pathname}#${key}`);
    setActiveTabKey(key);
  }

  const handleCloseMultipleModal = () => setMultipleModalProps(defaultModalProps);

  const handleCloseSingleModal = () => setSingleModalProps(defaultModalProps);

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    storedSingleSubscriptionData && setStoredSingleSubscriptionData(null);
    singleModalProps.loading && setSingleModalProps({...singleModalProps, loading: false});
    multipleModalProps.loading && setMultipleModalProps({...multipleModalProps, loading: false});
    deleteModalProps.loading && setDeleteModalProps({...deleteModalProps, loading: false});
    subsAuthorizeModalProps.loading && setSubsAuthorizeModalProps({...subsAuthorizeModalProps, loading: false});
  }

  const handleOnAddClick = ({key}) => {
    const func = {
      'multiple': () => setMultipleModalProps({...multipleModalProps, open: true}),
      'single': () => setSingleModalProps({...singleModalProps, open: true})
    }
    func.hasOwnProperty(key) && func[key]();
  }

  const updateSubscriptionTotal = () => handleUpdateSubscriptionTotal && handleUpdateSubscriptionTotal();

  const getSubscriptionPlaceholder = ({owner, service, ...rest}) => ({
    ...rest,
    placeholder: true,
    owner,
    service: {
      ...service,
      category: `${trans('creatingNewSubscription')}, ${t('loading')}...`,
    }
  });

  const scaResponseCallback = ({response, errorCallback}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
      errorCallback
    });
  }

  const handleCreateSingleSubscription = ({action, data} = {}) => {
    const subscriptionData = data || Object.assign(storedSingleSubscriptionData);
    setSingleModalProps({...singleModalProps, loading: true});

    // submit subscription if the service is already exist
    if (tempData.subscription) {
      handleSubmitModal({
        action: AUTHORIZE,
        authAction: SUBSCRIPTION_SUBMIT_IN_CREATION,
        data: subscriptionData,
        getter: singleModalProps,
        setter: setSingleModalProps
      });
    } else {
      createSubscription({
        headers: scaHelpers.getAuthHeaders(action || activeAuthAction),
        data: subscriptionData,
        successCallback: (subscription) => {
          let placeholderSubscriptions = [...websocketWaitSubscriptions];
          const isCardPaymentType = subscription.payment_type === CARD;
          let view = getSubscriptionCreationView();
          if (isCardPaymentType) {
            placeholderSubscriptions.push(subscription);
            // store subscription with card to wait websocket response
            setWebsocketWaitSubscriptions(placeholderSubscriptions);
          }
          loadData({view, initial: true, placeholderSubscriptions});
          updateSubscriptionTotal();
          handleCloseSingleModal();
        },
        errorCallback: (response) => {
          scaResponseCallback({
            response,
            errorCallback: () => setSingleModalProps({...singleModalProps, loading: false})
          });
        }
      });
    }
  }

  const handleOnSuccessAuth = () => {
    const actions = {
      [SUBSCRIPTION_CREATION_WITH_CARD_ACTION]: handleCreateSingleSubscription,
      [SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION]: handleCreateMultipleSubscriptions,
      [SUBSCRIPTION_DELETE]: handleOkDeleteModal,
      [SUBSCRIPTION_SUBMIT]: handleOkSubsAuthModal,
      [SUBSCRIPTION_SUBMIT_IN_CREATION]: handleOkSingleModal
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (actions.hasOwnProperty(activeAuthAction)) actions[activeAuthAction]();
  }

  const handleCreateMultipleSubscriptions = ({action, data} = {}) => {
    const subscriptions = data || storedMultipleSubscriptionsData;
    setMultipleModalProps({...multipleModalProps, loading: true});
    bulkCreateSubscriptions({
      data: subscriptions,
      headers: scaHelpers.getAuthHeaders(action || activeAuthAction),
      successCallback: (response) => {
        const cardSubscriptions = response.subscriptions.filter(d => d.payment_type === CARD);
        const placeholderSubscriptions = [...websocketWaitSubscriptions, ...cardSubscriptions];
        const isExistCardSubscriptions = cardSubscriptions.length > 0;
        let view = getSubscriptionCreationView();

        // store subscription with card to wait websocket response
        if (isExistCardSubscriptions) {
          setWebsocketWaitSubscriptions(placeholderSubscriptions);
        }
        // update subscriptions list if authorized view selected
        loadData({view, initial: true, placeholderSubscriptions});
        setMultipleModalProps({...multipleModalProps, loading: false, open: false});
        updateSubscriptionTotal();
      },
      errorCallback: (response) => {
        scaResponseCallback({
          response,
          errorCallback: () => setMultipleModalProps({...multipleModalProps, loading: false})
        });
      }
    });
  }

  const handleOkSingleModal = (data) => {
    setActiveAuthAction(SUBSCRIPTION_CREATION_WITH_CARD_ACTION);
    setStoredSingleSubscriptionData(data);
    handleCreateSingleSubscription({action: SUBSCRIPTION_CREATION_WITH_CARD_ACTION, data});
  }

  const handleOkMultipleModal = (data) => {
    setActiveAuthAction(SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION);
    setStoredMultipleSubscriptionsData(data);
    handleCreateMultipleSubscriptions({action: SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION, data});
  }

  const handleCloseDeleteModal = () => setDeleteModalProps({...deleteModalProps, open: false});

  const handleSubmitModal = ({action, authAction, data, getter, setter}) => {
    const formData = data || tempData.data;
    if (data) setTempData({...tempData, data});
    setter({...getter, loading: true});
    setActiveAuthAction(authAction);
    handleSubscriptionQuickAction({
      action,
      authAction,
      data: formData,
      id: tempData.subscription?.id || undefined,
      successCallback: () => setter({...getter, loading: false, open: false}),
      errorCallback: () => setter({...getter, loading: false})
    });
  }

  const handleOkDeleteModal = (data) => {
    handleSubmitModal({
      action: tempData.action,
      authAction: SUBSCRIPTION_DELETE,
      data,
      getter: deleteModalProps,
      setter: setDeleteModalProps
    });
  }

  const handleCloseSubsAuthModal = () => setSubsAuthorizeModalProps({...subsAuthorizeModalProps, open: false});

  const handleOkSubsAuthModal = (data) => {
    handleSubmitModal({
      action: AUTHORIZE,
      authAction: SUBSCRIPTION_SUBMIT,
      data,
      getter: subsAuthorizeModalProps,
      setter: setSubsAuthorizeModalProps
    });
  }

  const handleWebsocketMessage = (data) => {
    const gRespProp = (key) => gObjProp(data, key);

    const action = gRespProp('action');
    const subscriptionId = gRespProp('subscription_id');
    // update subscription after receiving card details
    if ([backendWebsocketActionsConstants.CARD_ACTIVATE, backendWebsocketActionsConstants.CARD_CREATE_ERROR].includes(action) && subscriptionId) {
      let waitSubscription = websocketWaitSubscriptions.find(s => s.id === subscriptionId);
      setWebsocketWaitSubscriptions(websocketWaitSubscriptions.filter(s => s.id !== subscriptionId));
      if (waitSubscription) {
        const waitSubscriptionId = waitSubscription.id;
        waitSubscription = {
          ...waitSubscription,
          card_id: gRespProp('card_id'),
          card_masked_pan: gRespProp('card_masked_pan'),
          status: parseInt(gRespProp('status'))
        }
        setTableData({
          ...tableData,
          [defaultView]: {
            ...tableData[defaultView],
            rows: tableData[defaultView].rows.map(r => r.id === waitSubscriptionId ? waitSubscription : r)
          }
        });
      }
    }
  }

  const handleSearchService = (query, successCallback, errorCallback) =>
    getSuggestServices({query}, successCallback, errorCallback);

  const handleSelectSingleService = (service) => {
    // check if selected service already exist in discovered
    getDiscoveredSubscriptions(
      {search: service},
      (data) => {
        const subscriptions = data?.subscriptions || [];
        const discoveredSubscription = Boolean(subscriptions.length) ? subscriptions[0] : undefined;
        setTempData({...tempData, subscription: discoveredSubscription});
      },
      () => setTempData({...tempData, subscription: undefined})
    );
  }

  const addButton = (
    <AddButton
      className='green-btn'
      menuItems={[
        {
          key: 'single',
          label: `${t('add')} ${t('single')}`
        },
        {
          key: 'multiple',
          label: `${t('add')} ${t('multiple')}`
        }
      ]}
      onClick={handleOnAddClick}
      size='large'
    >
      {`${t('add')} ${t('new')}`}
    </AddButton>
  );

  const tabs = getTabs();

  const emptyDescription = !isAdmin && (
    <>
      {trans('empty.variants.empty.employeeDescription1')}
      <br />
      {trans('empty.variants.empty.employeeDescription2')}
    </>
  );

  return (
    <>
      {!isExistsSubscriptions && !totalLoading && (
        <EmptySubscriptions
          description={emptyDescription}
          enableButton={isAdmin}
          onClick={() => handleOnAddClick({key: 'single'})}
        />
      )}
      {isExistsSubscriptions && (
        <StyledSubscriptionTabs
          {...rest}
          activeKey={activeTabKey}
          items={tabs}
          onChange={onChangeTab}
          type='card'
        />
      )}

      {isAdmin && (
        <>
          <AddSingleSubscriptionModal
            {...singleModalProps}
            handleClose={handleCloseSingleModal}
            handleOk={handleOkSingleModal}
            onSearch={handleSearchService}
            handleSelectService={handleSelectSingleService}
          />
          <AddMultipleSubscriptionsModal
            {...multipleModalProps}
            handleClose={handleCloseMultipleModal}
            handleOk={handleOkMultipleModal}
            onSearch={handleSearchService}
          />
        </>
      )}

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={activeAuthAction}
      />

      <DeleteModal
        onCancel={handleCloseDeleteModal}
        onOk={handleOkDeleteModal}
        data={tempData.subscription}
        {...deleteModalProps}
      />

      <AuthorizeSubscriptionModal
        handleClose={handleCloseSubsAuthModal}
        handleOk={handleOkSubsAuthModal}
        subscription={tempData.subscription}
        {...subsAuthorizeModalProps}
      />

      {enabledWebSocketConnection && <WebSocket onMessage={handleWebsocketMessage} />}
    </>
  );
}

SubscriptionTabs.propTypes = {
  handleUpdateSubscriptionTotal: PropTypes.func,
  total: PropTypes.shape({
    all: PropTypes.number,
    authorized: PropTypes.number,
    deleted: PropTypes.number,
    need_review: PropTypes.number,
    restricted: PropTypes.number,
    unmanaged: PropTypes.number,
  }),
  totalLoading: PropTypes.bool
}

SubscriptionTabs.defaultProps = {
  totalLoading: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {isAdmin} = state.user;
  const {storedSubscriptions} = state.subscription;

  return {
    employees,
    isAdmin,
    storedSubscriptions
  }
}

const mapDispatchToProps = {
  createSubscription: subscriptionActions.createSubscription,
  deleteSubscription: subscriptionActions.deleteSubscription,
  getSubscriptions: subscriptionActions.getSubscriptionsList,
  updateSubscription: subscriptionActions.updateSubscription,
  subscriptionQuickAction: subscriptionActions.subscriptionQuickAction,
  storeSubscriptions: subscriptionActions.storeSubscriptions,
  bulkCreateSubscriptions: subscriptionActions.bulkCreateSubscriptions,
  getCompanyDetails: cardActions.getCompanyDetails,
  getSuggestServices: serviceStatsActions.getSuggestServices,
  getDiscoveredSubscriptions: subscriptionActions.getDiscoveredSubscriptions
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionTabs);
