import {Space, Table} from 'antd';
import styled from 'styled-components';

const StyledTransactionsTable = styled(Table)`
  border: 1px solid ${props => props.theme.colors.neutral_3};

  .ant-table-container {
    overflow: auto;
  }

  .ant-table-thead > tr {
    & > th, & > td {
      background: transparent;
      &.ant-table-column-sort {
        background-color: transparent;
      }
    }
  }

  tbody {
    tr {
      &:not(.grouped-row) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  thead {
    th {
      &:last-of-type {
        &:before {
          content: none !important;
        }
      }
    }
  }

  .danger-text {
    color: ${props => props.theme.colors.danger} !important;
  }

  .total-amount {
    color: ${props => props.theme.colors.neutral_8};
    font-size: 16px;
    text-wrap: nowrap;
  }

  .grouped-row {
    background: linear-gradient(0deg, ${props => props.theme.colors.neutral_2}, ${props => props.theme.colors.neutral_2}), linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
  }

  .grouped-date {
    align-items: center;
    color: ${props => props.theme.colors.neutral_8};
    display: flex;
    justify-content: space-between;
    text-wrap: nowrap;
    text-transform: uppercase;
  }
  
  .download-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    
    .ant-spin {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-100%, 2px);
    }

    button {
      background-color: transparent;
      border: none;

      &:hover {
        background-color: ${props => props.theme.colors.light_darker};

        svg {
          path {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }

      svg {
        path {
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }

  &.collapsed {
    .card-number {
      display: none;
    }
  }

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 32px;
      width: 32px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }

    .card-number {
      margin-left: 12px;
    }
  }


  .transaction-icon {
    svg {
      height: 32px;
      width: 32px;

      path {
        &:not([fill-rule="evenodd"]) {
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
  
  .returned-price {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: line-through;
  }
`;

const StyledTransactionTableDownloadSpace = styled(Space)`
  & > .ant-space-item {
    display: flex;
  }
  
  button {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: ${props => props.theme.colors.light_darker};

      svg {
        path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }

    svg {
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

export {
  StyledTransactionsTable,
  StyledTransactionTableDownloadSpace
};
