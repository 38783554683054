import styled from 'styled-components';
import {Tabs} from 'antd';

const StyledSubscriptionPageContainer = styled.div`
  padding: 16px 24px 24px 24px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-tab {
      font-weight: 500;
      padding: 9px 32px;

      &:not(.ant-tabs-tab-active) {
        background: unset;
        border-color: transparent;
      }
    }
  }
`;

export {
  StyledSubscriptionPageContainer,
  StyledTabs
};
