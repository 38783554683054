import styled from 'styled-components';
import {Space} from 'antd';

const StyledTransferList = styled(Space)`
  width: 100%;
  & > div {
    width: calc(50% - 8px);
    &:nth-of-type(1) {
      .icon {
        svg path {
          fill: ${props => props.theme.colors.success};
        }
      }
    }
    &:nth-of-type(2) {
      .icon {
        svg path {
          fill: ${props => props.theme.colors.danger};
        }
      }
    }
  }
`;

const StyledEmptyState = styled.div`
  padding: 12px 0;
  color: ${props => props.theme.colors.neutral_7};
  text-align: center;
`;

export {
  StyledTransferList,
  StyledEmptyState
}
