import React from 'react';
import {useTranslation} from 'react-i18next';
import ContentLoader from 'react-content-loader';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  StyledSubscriptionTables,
  StyledSubscriptionTablesCard,
  StyledSubscriptionTablesTable
} from './StyledSubscriptionTables';
import {subscriptionsHelpers} from '../../SubscriptionsPage/subscriptionsHelpers';
import VendorDetails from '../../SubscriptionsPage/VendorDetails';
import {DragIndicatorIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';

const SubscriptionTables = ({
  loading,
  created,
  upcoming,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const navigate = useNavigate();

  const getTableTitle = (key) => `${t(`subscriptions:overview.tables.${key}`)} ${t('subscriptions')}`;

  const getPeriod = (billed) => t(subscriptionsHelpers.getBillType(billed));

  const getAmount = (value) => (
    <span className='amount'>
      {helpers.getMoneyView(value || 0)}
    </span>
  );

  const getSkeletonValue = ({list, skeletonShape, value}) => {
    if (list.length > 0 && !loading) {
      return value;
    } else {
      return skeletonShape;
    }
  }

  const shapeProps = {
    animate: loading,
    backgroundColor: '#f3f3f3',
    foregroundColor: '#ecebeb',
    speed: 2,
    title: loading ? undefined : ''
  }

  const avatarShape = (
    <ContentLoader
      height={32}
      width={32}
      {...shapeProps}
    >
      <circle cx='16' cy='16' r='16' />
    </ContentLoader>
  );

  const vendorShape = (
    <ContentLoader
      height={32}
      width={132}
      {...shapeProps}
    >
      <rect x='40' y='8' rx='3' ry='3' width='66' height='4' />
      <rect x='40' y='20' rx='3' ry='3' width='93' height='4' />
      <circle cx='16' cy='16' r='16' />
    </ContentLoader>
  );

  const textShape = (
    <ContentLoader
      height={4}
      width={32}
      {...shapeProps}
    >
      <rect x='0' y='0' rx='3' ry='3' width='32' height='4' />
    </ContentLoader>
  );

  const newTableColumns = [
    {
      dataIndex: 'vendor',
      title: t('name'),
      render: (_, record) => getSkeletonValue({
        list: created,
        skeletonShape: vendorShape,
        value: <VendorDetails data={record} />
      }),
    },
    {
      dataIndex: 'last_payment',
      title: t('value'),
      render: (value) => getSkeletonValue({
        list: created,
        skeletonShape: textShape,
        value: getAmount(value)
      })
    },
    {
      dataIndex: 'billed',
      title: t('Period'),
      render: (value) => getSkeletonValue({
        list: created,
        skeletonShape: textShape,
        value: getPeriod(value)
      })
    },
    {
      align: 'center',
      dataIndex: 'owner',
      title: t('Owner'),
      render: (_, record) => getSkeletonValue({
        list: created,
        skeletonShape: avatarShape,
        value: subscriptionsHelpers.getSubscriptionOwner(record)
      }),
    },
  ];

  const upcomingTableColumns = [
    {
      dataIndex: 'vendor',
      title: t('name'),
      render: (_, record) => getSkeletonValue({
        list: upcoming,
        skeletonShape: vendorShape,
        value: <VendorDetails data={record} />
      }),
    },
    {
      className: 'expected-payment',
      dataIndex: 'expected_payment',
      title: `${t('expected')} ${t('payment')}`,
      render: (value) => getSkeletonValue({
        list: upcoming,
        skeletonShape: textShape,
        value: getAmount(value)
      })
    },
    {
      dataIndex: 'billed',
      title: t('Period'),
      render: (value) => getSkeletonValue({
        list: upcoming,
        skeletonShape: textShape,
        value: getPeriod(value)
      })
    },
    {
      align: 'right',
      className: 'date',
      dataIndex: 'next_payment',
      title: `${t('next')} Pay. ${t('date')}`,
      render: (_, record) => getSkeletonValue({
        list: upcoming,
        skeletonShape: textShape,
        value: subscriptionsHelpers.getSubscriptionDate(record, 'next_payment', 'N.A.')
      })
    },
  ];

  const getDataSource = (list) => {
    return loading || (helpers.isNaV(list) || list.length === 0)
      ? [...Array(5).keys()].map(id => ({id}))
      : list;
  };

  const handleOnRowClick = (e, record) => {
    if (record.id && record.service) navigate(subscriptionsHelpers.getSubscriptionDetailsPageUrl(record));
  }

  const tableProps = {
    onRow: (record) => ({
      onClick: (e) => handleOnRowClick(e, record)
    }),
    pagination: false,
    rowClassName: (record) => record.id && record.service ? 'pointer': '',
    rowKey: 'id'
  }

  return (
    <StyledSubscriptionTables
      align='start'
      size='middle'
      {...rest}
    >
      <StyledSubscriptionTablesCard
        extra={<DragIndicatorIcon />}
        title={getTableTitle('upcoming')}
      >
        <StyledSubscriptionTablesTable
          {...tableProps}
          columns={upcomingTableColumns}
          dataSource={getDataSource(upcoming)}
        />
      </StyledSubscriptionTablesCard>
      <StyledSubscriptionTablesCard
        extra={<DragIndicatorIcon />}
        title={getTableTitle('new')}
      >
        <StyledSubscriptionTablesTable
          {...tableProps}
          columns={newTableColumns}
          dataSource={getDataSource(created)}
        />
      </StyledSubscriptionTablesCard>
    </StyledSubscriptionTables>
  );
}

SubscriptionTables.propTypes = {
  created: PropTypes.array,
  loading: PropTypes.bool,
  upcoming: PropTypes.array
}

SubscriptionTables.defaultProps = {
  created: [],
  loading: true,
  upcoming: []
}

export default SubscriptionTables;
