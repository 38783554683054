import styled from 'styled-components';
import {Button, Space} from 'antd';

const StyledInvoiceTabContainer = styled(Space)`
  position: relative;
  width: 100%;
  
  .amount {
    text-wrap: nowrap;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTypeSpace = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    height: 32px;
    width: 32px;

    path {
      fill: ${props => props.theme.colors.neutral_6};
    }
  }
`;

export {
  StyledInvoiceTabContainer,
  StyledButton,
  StyledTypeSpace
};
