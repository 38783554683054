import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Form, Select, Spin} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import {StyledAddCardModal, StyledCardInfo, StyledAddCardModalFormSpace} from './StyledAddCardModal';
import {StyledInput} from '../PaymentDetails/StyledPaymentDetails';
import IncreaseLimitAlert from '../../../../SubscriptionsPage/IncreaseLimitAlert';
import {ReactComponent as CardSvgImage} from '../../../../../../static/images/pages/subscriptions/card-template.svg';
import {cardsHelpers} from '../../../../CardsPage/cardsHelpers';
import {StyledBudgetDetailsFormSpace} from '../BudgetDetails/StyledBudgetDetails';
import {helpers} from '../../../../../../helpers';
import {EuroIcon} from '../../../../../../icons';
import {subscriptionFormFields, subscriptionFormValues} from '../../../../../../constants';
import {CardLimitPeriodTypeOptions} from '../../../../SubscriptionsPage/subscriptionsHelpers';

const {Item} = Form;

const {
  defaultBudgetLimit,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const {
  cardLimitFieldName,
  cardLimitPeriodFieldName
} = subscriptionFormFields;

const defaultFormValues = {
  [cardLimitFieldName]: defaultBudgetLimit,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  card_owner: ''
}


const AddCardModal = ({
  dispatch,
  employees,
  employeeEmail,
  handleCancel,
  handleOk,
  loading,
  open,
  subscriptionOwner,
  paymentCardLimit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [ownerOptions, setOwnerOptions] = useState([]);

  const budgetLimit = Form.useWatch(cardLimitFieldName, {form});

  const isTooHighLimit = useMemo(() => {
    let isHigh = false;
    if (budgetLimit) {
      isHigh = budgetLimit > paymentCardLimit;
    }
    return isHigh;
  }, [budgetLimit, paymentCardLimit]);

  const disabledCreateButton = useMemo(() => loading || isTooHighLimit, [isTooHighLimit, loading]);

  useEffect(() => {
    if (open) {
      const formFieldValues = {
        ...defaultFormValues,
        card_owner: subscriptionOwner
      };
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = helpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key) => t(`subscriptions:${key}`);

  const handleSubmit = (data) => (handleOk && !isTooHighLimit) && handleOk(data);

  const footer = [
    <Button
      className='green-btn'
      key='submit'
      disabled={disabledCreateButton}
      onClick={() => !disabledCreateButton && form.submit()}
      size='large'
    >
      {t('create')}
    </Button>
  ];

  const formItemRules = [{required: true, message: t('validation.fieldIsRequired')}];

  return (
    <StyledAddCardModal
      footer={footer}
      open={open}
      onCancel={handleCancel}
      title={`${t('card')} ${t('details')}`}
      width={472}
      {...rest}
    >
      <Spin spinning={loading}>
        <StyledAddCardModalFormSpace
          direction='vertical'
          size='middle'
        >
          <StyledCardInfo align='start'>
            <InfoCircleOutlined />
            {trans('cardCreationDescription')}
          </StyledCardInfo>
          <CardSvgImage />
          <Form
            initialValues={initialFormValues}
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark={false}
          >
            <Item
              label={`${t('card')} ${t('Owner')}`}
              name='card_owner'
              rules={formItemRules}
            >
              <Select
                options={ownerOptions}
                size='large'
              />
            </Item>
            <StyledBudgetDetailsFormSpace size='middle'>
              <Item
                label={`${t('card')} ${t('limit')}`}
                name={cardLimitFieldName}
                rules={formItemRules}
                required
              >
                <StyledInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={cardLimitPeriodFieldName}
                rules={formItemRules}
              >
                <Select
                  options={cardLimitPeriodTypeOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>
            <Collapse isOpened={isTooHighLimit}>
              <IncreaseLimitAlert
                limit={paymentCardLimit}
              />
            </Collapse>
          </Form>
        </StyledAddCardModalFormSpace>
      </Spin>
    </StyledAddCardModal>
  );
}

AddCardModal.propTypes = {
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  subscriptionOwner: PropTypes.string
}

AddCardModal.defaultProps = {
  loading: false,
  open: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {email: employeeEmail} = state.user.employee;
  const paymentCardLimit = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employeeEmail,
    paymentCardLimit
  }
}

export default connect(mapStateToProps, null)(AddCardModal);
