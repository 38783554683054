import {createTheme} from '@mui/material';

export const colors = {
  // functional colors
  danger: '#ff4d4f',
  danger_d: '#ff7875',
  warning: '#faad14',
  success: '#52c41a',

  // neutral colors
  neutral_1: '#fff',
  neutral_2: '#fafafa',
  neutral_3: '#f5f5f5',
  neutral_4: '#f0f0f0',
  neutral_5: '#d9d9d9',
  neutral_6: '#bfbfbf',
  neutral_7: '#8c8c8c',
  neutral_8: '#595959',
  neutral_9: '#434343',
  neutral_10: '#262626',
  neutral_11: '#1f1f1f',
  neutral_12: '#141414',
  neutral_13: '#000',

  // tertiary colors
  tertiary_1: '#8B8F99',
  tertiary_2: '#69728C',
  tertiary_3: '#4B5780',
  tertiary_4: '#313D73',
  tertiary_5: '#1C2766',
  tertiary_6: '#0A145A',
  tertiary_7: '#030733',
  tertiary_8: '#00010D',

  // secondary colors
  secondary_1: '#E6FFF3',
  secondary_2: '#A0FAD1',
  secondary_3: '#72EDBA',
  secondary_4: '#48E0A6',
  secondary_5: '#22D495',
  secondary_6: '#00C887',
  secondary_7: '#00A173',
  secondary_8: '#007A5C',
  secondary_9: '#005442',
  secondary_10: '#002E25',

  // primary colors
  primary_1: '#F0F4FF',
  primary_2: '#F0F3FF',
  primary_3: '#CCD5FF',
  primary_4: '#A3B1FF',
  primary_5: '#7A8AFF',
  primary_6: '#515FFF',
  primary_7: '#3B42D9',
  primary_8: '#272AB3',
  primary_9: '#1A188C',
  primary_10: '#151066',
}

const standardBorderRadius = 4;

const styles = {
  border_radius: {
    standard: `${standardBorderRadius}px`,
  },
  colors: {
    ...colors,
    secondary: colors.tertiary_6,
    secondary_hover: '#122292',
    secondary_text: '#0C165C',
    primary: '#03ab93',
    primary_hover: '#059b86',
    light_darker: '#e9e9e9',
    violet: colors.tertiary_6,
    modal_header_bg: 'rgba(18, 34, 146, 0.2)'
  }
};


const styledTheme = Object.assign(
  {},
  styles
);

export const antdTheme = {
  token: {
    borderRadius: standardBorderRadius,
    colorPrimary: colors.primary_6
  }
}

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: styledTheme.colors.secondary
    }
  }
});

export default styledTheme;
