import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {StyledTeamsTabContainer} from './StyledTeamsTab';
import TableFilters from '../../tabComponents/TableFilters';
import TeamsTable from '../../tabComponents/TeamsTable';
import NoSearchResults from '../../../../NoSearchResults';


const TeamsTab = ({
  columns,
  data,
  filtersRightSideContent,
  onFilter,
  tableProps,
  ...rest
}) => {
  const [query, setQuery] = useState({
    search: ''
  });
  const [emptyDetails, setEmptyDetails] = useState({
    text: '',
    enabled: false
  });

  const handleOnFilter = (filterParams) => {
    setQuery({...query, ...filterParams});
    onFilter && onFilter(filterParams);
  }

  useEffect(() => {
    const isEmptyList = data.length === 0;
    const search = query?.search || '';
    if (search !== '' && isEmptyList) {
      setEmptyDetails({
        text: search,
        enabled: true
      });
    } else if (emptyDetails.enabled && !isEmptyList) {
      setEmptyDetails({...emptyDetails, enabled: false});
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTeamsTabContainer
      {...rest}
      size='large'
      direction='vertical'
    >
      <TableFilters
        onFilter={handleOnFilter}
        rightSideContent={filtersRightSideContent}
      />
      {emptyDetails.enabled ? (
        <NoSearchResults value={emptyDetails.text} />
      ) : (
        <TeamsTable
          columns={columns}
          dataSource={data}
          {...tableProps}
        />
      )}
    </StyledTeamsTabContainer>
  );
}

TeamsTab.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  filtersRightSideContent: PropTypes.any,
  onFilter: PropTypes.func,
  tableProps: PropTypes.object
}

TeamsTab.defaultProps = {
  columns: [],
  data: []
}

export default TeamsTab;
