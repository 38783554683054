import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ResponsiveContainer, PieChart as PChart, Cell, Pie} from 'recharts';
import {BarLegend, ChartContainer, StyledPieChart, StyledTotal, StyledLegendDot} from './StyledPieChart';
import {helpers} from '../../../helpers';

const PieChart = ({
  barWidth,
  chartHeight,
  cornerRadius,
  data,
  isAnimationActive,
  isEnableLegend,
  isEnableTotal,
  outerRadius,
  totalTitle,
  ...rest
}) => {
  const [chartData, setChartData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data) {
      setChartData(data.map(d => ({
        color: d?.color || helpers.generateRandomColor(),
        name: d.name,
        value: d.value
      })));
      setTotal(data.map(d => d.value).reduce((acc, currentValue) => acc + currentValue, 0));
    }
  }, [data]);

  return (
    <StyledPieChart {...rest}>
      <ChartContainer>
        <ResponsiveContainer height={chartHeight} width='100%'>
          <PChart>
            <Pie
              cornerRadius={cornerRadius}
              innerRadius={outerRadius - barWidth}
              outerRadius={outerRadius}
              data={chartData}
              dataKey='value'
            >
              {chartData.map((entry, index) =>
                <Cell fill={entry.color || 'transparent'} />)}
            </Pie>
          </PChart>
        </ResponsiveContainer>
        {isEnableTotal && (
          <StyledTotal>
            <label>{totalTitle}</label>
            {total}
          </StyledTotal>
        )}
      </ChartContainer>
      {isEnableLegend && (
        <BarLegend>
          {chartData.map((item, key) => (
            <div className='d-flex align-center justify-space-between' key={key}>
              <span className='d-flex align-center'>
                <StyledLegendDot color={item.color}/>
                {item.name}
              </span>
              <b>{item.value}</b>
            </div>
          ))}
        </BarLegend>
      )}
    </StyledPieChart>
  );
}

PieChart.propTypes = {
  barWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  cornerRadius: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  isAnimationActive: PropTypes.bool,
  isEnableTotal: PropTypes.bool,
  isEnableLegend: PropTypes.bool,
  outerRadius: PropTypes.number,
  totalTitle: PropTypes.string
}

PieChart.defaultProps = {
  barWidth: 40,
  chartHeight: 300,
  cornerRadius: 0,
  isAnimationActive: true,
  isEnableLegend: true,
  isEnableTotal: false,
  outerRadius: 110,
  totalTitle: 'Total'
}

export default PieChart;
