import Card from '../../../../Card';
import styled from 'styled-components';

const StyledCompanyInfo = styled(Card)`
  .ant-card-head {
    flex-direction: unset;
    padding: 24px;
  }

  .company-info-details {
    padding: 0;
  }
  
  .title-error-message {
    color: ${props => props.theme.colors.danger} !important;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }
`;

const StyledTitle = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

export {
  StyledCompanyInfo,
  StyledTitle
}
