import styled from 'styled-components';

const StyledHeaderDetails = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 24px;
  & > div {
    width: calc((100% - 48px) / 3);
  }
`;

export {StyledHeaderDetails};
