import React from 'react';

const InvoiceSuggestIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2976_18867)">
      <path
        d="M13.3333 10.7667H21.3333C21.687 10.7667 22.0261 10.6245 22.2761 10.3713C22.5262 10.1181 22.6667 9.77474 22.6667 9.4167C22.6667 9.05866 22.5262 8.71528 22.2761 8.4621C22.0261 8.20893 21.687 8.0667 21.3333 8.0667H13.3333C12.9797 8.0667 12.6406 8.20893 12.3905 8.4621C12.1405 8.71528 12 9.05866 12 9.4167C12 9.77474 12.1405 10.1181 12.3905 10.3713C12.6406 10.6245 12.9797 10.7667 13.3333 10.7667ZM10.6667 16.1667H17.3333C17.687 16.1667 18.0261 16.0245 18.2761 15.7713C18.5262 15.5181 18.6667 15.1747 18.6667 14.8167C18.6667 14.4587 18.5262 14.1153 18.2761 13.8621C18.0261 13.6089 17.687 13.4667 17.3333 13.4667H10.6667C10.313 13.4667 9.97391 13.6089 9.72386 13.8621C9.47381 14.1153 9.33333 14.4587 9.33333 14.8167C9.33333 15.1747 9.47381 15.5181 9.72386 15.7713C9.97391 16.0245 10.313 16.1667 10.6667 16.1667ZM10.6667 21.5667H14.6667C15.0203 21.5667 15.3594 21.4245 15.6095 21.1713C15.8595 20.9181 16 20.5747 16 20.2167C16 19.8587 15.8595 19.5153 15.6095 19.2621C15.3594 19.0089 15.0203 18.8667 14.6667 18.8667H10.6667C10.313 18.8667 9.97391 19.0089 9.72386 19.2621C9.47381 19.5153 9.33333 19.8587 9.33333 20.2167C9.33333 20.5747 9.47381 20.9181 9.72386 21.1713C9.97391 21.4245 10.313 21.5667 10.6667 21.5667Z"
        fill="#7A8AFF"/>
      <path
        d="M26.6667 2.6667H5.33333C4.97971 2.6667 4.64057 2.80893 4.39052 3.0621C4.14048 3.31528 4 3.65866 4 4.0167V28.3167C4 28.5674 4.06895 28.8132 4.19913 29.0264C4.32931 29.2397 4.51557 29.4121 4.73705 29.5242C4.95852 29.6363 5.20646 29.6838 5.45307 29.6612C5.69969 29.6387 5.93524 29.5471 6.13333 29.3967L8.89333 27.3042L11.64 29.3967C11.8708 29.572 12.1515 29.6667 12.44 29.6667C12.7285 29.6667 13.0092 29.572 13.24 29.3967L16 27.3042L16.6834 27.8405C17.4881 28.4719 18.6667 27.8986 18.6667 26.8757C18.6667 26.5454 18.5334 26.2291 18.2971 25.9983L16.8 24.5367C16.5692 24.3614 16.2885 24.2667 16 24.2667C15.7115 24.2667 15.4308 24.3614 15.2 24.5367L12.44 26.6292L9.69333 24.5367C9.46254 24.3614 9.18183 24.2667 8.89333 24.2667C8.60484 24.2667 8.32413 24.3614 8.09333 24.5367L6.66667 25.6167V5.3667H25.3333V12.0238C25.3333 12.7602 25.9303 13.3572 26.6667 13.3572C27.403 13.3572 28 12.7602 28 12.0238V4.0167C28 3.65866 27.8595 3.31528 27.6095 3.0621C27.3594 2.80893 27.0203 2.6667 26.6667 2.6667Z"
        fill="#7A8AFF"/>
      <path
        d="M21.0587 14.865C21.0778 14.8073 21.1147 14.7571 21.1639 14.7215C21.2132 14.6859 21.2725 14.6667 21.3333 14.6667C21.3941 14.6667 21.4534 14.6859 21.5027 14.7215C21.552 14.7571 21.5888 14.8073 21.6079 14.865L22.1238 16.414C22.3544 17.1044 22.8956 17.6456 23.5861 17.8762L25.135 18.3921C25.1927 18.4112 25.2429 18.448 25.2785 18.4973C25.3141 18.5466 25.3333 18.6059 25.3333 18.6667C25.3333 18.7275 25.3141 18.7868 25.2785 18.8361C25.2429 18.8854 25.1927 18.9222 25.135 18.9413L23.5861 19.4572C23.2455 19.5706 22.9361 19.7618 22.6823 20.0156C22.4284 20.2695 22.2372 20.5789 22.1238 20.9194L21.6079 22.4684C21.5888 22.5261 21.552 22.5763 21.5027 22.6119C21.4534 22.6475 21.3941 22.6667 21.3333 22.6667C21.2725 22.6667 21.2132 22.6475 21.1639 22.6119C21.1147 22.5763 21.0778 22.5261 21.0587 22.4684L20.5429 20.9194C20.4294 20.5789 20.2382 20.2695 19.9844 20.0156C19.7306 19.7618 19.4211 19.5706 19.0806 19.4572L17.5316 18.9413C17.4739 18.9222 17.4237 18.8854 17.3881 18.8361C17.3525 18.7868 17.3333 18.7275 17.3333 18.6667C17.3333 18.6059 17.3525 18.5466 17.3881 18.4973C17.4237 18.448 17.4739 18.4112 17.5316 18.3921L19.0806 17.8762C19.4211 17.7628 19.7306 17.5716 19.9844 17.3177C20.2382 17.0639 20.4294 16.7545 20.5429 16.414L21.0587 14.865Z"
        fill="#7A8AFF"/>
      <path
        d="M28.7935 13.4797C28.8084 13.437 28.8361 13.3999 28.873 13.3737C28.9099 13.3475 28.954 13.3334 28.9992 13.3334C29.0445 13.3334 29.0886 13.3475 29.1255 13.3737C29.1624 13.3999 29.1901 13.437 29.205 13.4797L29.5923 14.6416C29.765 15.161 30.1719 15.5678 30.6914 15.7404L31.8536 16.1277C31.8964 16.1425 31.9334 16.1703 31.9597 16.2072C31.9859 16.244 32 16.2881 32 16.3334C32 16.3786 31.9859 16.4227 31.9597 16.4596C31.9334 16.4964 31.8964 16.5242 31.8536 16.539L30.6914 16.9263C30.4354 17.0115 30.2028 17.1551 30.012 17.3458C29.8212 17.5366 29.6775 17.7692 29.5923 18.0251L29.205 19.187C29.1901 19.2297 29.1624 19.2668 29.1255 19.293C29.0886 19.3193 29.0445 19.3334 28.9992 19.3334C28.954 19.3334 28.9099 19.3193 28.873 19.293C28.8361 19.2668 28.8084 19.2297 28.7935 19.187L28.4062 18.0251C28.321 17.7692 28.1773 17.5366 27.9865 17.3458C27.7957 17.1551 27.5631 17.0115 27.3071 16.9263L26.1464 16.539C26.1036 16.5242 26.0666 16.4964 26.0403 16.4596C26.0141 16.4227 26 16.3786 26 16.3334C26 16.2881 26.0141 16.244 26.0403 16.2072C26.0666 16.1703 26.1036 16.1425 26.1464 16.1277L27.3086 15.7404C27.8281 15.5678 28.235 15.161 28.4077 14.6416L28.7935 13.4797Z"
        fill="#7A8AFF"/>
      <path
        d="M25.4698 20.4897C25.6042 20.1116 26.1737 20.1116 26.3081 20.4897L27.0964 22.7092C27.2699 23.1971 27.5622 23.6404 27.9502 24.004C28.3381 24.3675 28.8111 24.6414 29.3316 24.8038L31.6975 25.5428C32.1008 25.6689 32.1008 26.2028 31.6975 26.3289L29.3303 27.068C28.81 27.2306 28.3372 27.5047 27.9494 27.8685C27.5616 28.2322 27.2696 28.6757 27.0964 29.1637L26.3081 31.382C26.2791 31.4648 26.223 31.5369 26.1477 31.5881C26.0725 31.6392 25.9819 31.6667 25.8889 31.6667C25.796 31.6667 25.7054 31.6392 25.6301 31.5881C25.5549 31.5369 25.4987 31.4648 25.4698 31.382L24.6815 29.1625C24.5081 28.6748 24.216 28.2315 23.8282 27.868C23.4405 27.5044 22.9678 27.2305 22.4475 27.068L20.0803 26.3289C19.992 26.3017 19.9151 26.2491 19.8606 26.1785C19.8061 26.1079 19.7767 26.023 19.7767 25.9359C19.7767 25.8487 19.8061 25.7638 19.8606 25.6932C19.9151 25.6226 19.992 25.57 20.0803 25.5428L22.4475 24.8038C22.9678 24.6412 23.4405 24.3673 23.8282 24.0037C24.216 23.6402 24.5081 23.197 24.6815 22.7092L25.4698 20.4897Z"
        fill="#7A8AFF"/>
    </g>
    <defs>
      <clipPath id="clip0_2976_18867">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default InvoiceSuggestIcon;
