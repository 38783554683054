import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {StyledSubscriptionTabContainer} from './StyledSubscriptionTab';
import TableFilters from '../../TableFilters';
import SubscriptionTable from '../../tabComponents/SubscriptionTable';
import NoSearchResults from '../../../../NoSearchResults';


const SubscriptionTab = ({
  columns,
  data,
  defaultQuery,
  editable,
  filterProps,
  onFilter,
  tableProps,
  ...rest
}) => {
  const [emptyDetails, setEmptyDetails] = useState({
    text: '',
    enabled: false
  });

  const [query, setQuery] = useState({
    search: defaultQuery?.search || ''
  });

  useEffect(() => {
    const isEmptyList = data.length === 0;
    const search = query?.search || '';
    if (search !== '' && isEmptyList) {
      setEmptyDetails({
        text: search,
        enabled: true
      });
    } else if (emptyDetails.enabled && !isEmptyList) {
      setEmptyDetails({...emptyDetails, enabled: false});
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnFilter = (filterParams) => {
    setQuery({...query, ...filterParams});
    onFilter && onFilter(filterParams);
  }

  return (
    <StyledSubscriptionTabContainer
      {...rest}
      size='large'
      direction='vertical'
    >
      <TableFilters
        {...filterProps}
        defaultQuery={defaultQuery}
        onFilter={handleOnFilter}
      />
      {emptyDetails.enabled ? (
        <NoSearchResults value={emptyDetails.text} />
      ) : (
        <SubscriptionTable
          columns={columns}
          dataSource={data}
          {...tableProps}
        />
      )}
    </StyledSubscriptionTabContainer>
  );
}

SubscriptionTab.propTypes = {
  defaultQuery: PropTypes.object,
  filterProps: PropTypes.object,
  onFilter: PropTypes.func,
  tableProps: PropTypes.object
}

SubscriptionTab.defaultProps = {
  defaultQuery: {}
}

export default SubscriptionTab;
