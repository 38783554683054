import styled from 'styled-components';
import {Card} from 'antd';

const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 0 16px;
  }
  .ant-card-head-title {
    font-size: 16px;
    font-weight: 500;
  }
`;

export {
  StyledCard
};
