import {cardConstants} from '../constants';
import {cardsHelpers} from '../../components/pages/CardsPage/cardsHelpers';

const defaultVerification = {
  isCreatedUser: false,
  isRequired: false,
  status: null, // light_none, light_pending, light_validated, light_refused
}

const cardState = {
  cardDetails: null,
  cards: [],
  users: [],
  companyDetails: null,
  companyState: null,
  isEnabledBanking: false,
  invitedUser: null,
  legalPerson: undefined,
  verification: defaultVerification
};

const cardReducer = (state = cardState, action) => {
  const {type, data} = action;
  switch (type) {
    case cardConstants.CLEAR_CARDS:
      return {
        ...state,
        cards: [],
        users: []
      }
    case cardConstants.CLEAR_CARDS_ADDITIONAL_DATA:
      return {
        ...state,
        companyState: null,
        isEnabledBanking: false,
        invitedUser: null,
        verification: defaultVerification
      }
    case cardConstants.GET_CARD_LIST:
    case cardConstants.GET_USER_CARD_LIST:
      return {
        ...state,
        cards: data
      }
    case cardConstants.GET_CARD_USERS:
      return {
        ...state,
        users: data
      }
    case cardConstants.GET_USER_CARD_DETAILS:
      return {
        ...state,
        cardDetails: data
      }
    case cardConstants.SET_VERIFICATION_STATUS:
      return {
        ...state,
        verification: {
          ...state.verification,
          ...action.verification
        }
      }
    case cardConstants.CREATE_USER:
      return {
        ...state,
        isCreatedUser: true
      }
    case cardConstants.GET_COMPANY_STATE:
      return {
        ...state,
        companyState: data,
        isEnabledBanking: cardsHelpers.checkIsAvailableBanking(data)
      }
    case cardConstants.GET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: data
      }
    case cardConstants.CLEAR_CARDS_DATA:
      return {
        ...state,
        companyState: null,
        companyDetails: null,
        isEnabledBanking: false,
        invitedUser: null,
        legalPerson: undefined,
        verification: defaultVerification
      }
    case cardConstants.SET_INVITED_USER:
      return {
        ...state,
        invitedUser: data
      }
    case cardConstants.SET_LEGAL_PERSON_DETAILS:
      return {
        ...state,
        legalPerson: data.user
      }
    default:
      return state;
  }
};

export default cardReducer;
