import React from 'react';
import PropTypes from 'prop-types';
import {StyledSpinnerImage} from './StyledSpinnerImage';
import loaderImg from '../../static/images/H1_loader_darkblue.png';

const SpinnerImage = ({
  image,
  ...rest
}) => {

  return (
    <StyledSpinnerImage {...rest}>
      <img src={image || loaderImg} alt='loading' />
    </StyledSpinnerImage>
  );
};

SpinnerImage.defaultProps = {
  size: 'medium'
}

SpinnerImage.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large'])
};

export default SpinnerImage;
