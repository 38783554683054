import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import TotalCounter from '../TotalCounter';
import {StyledHeaderDetails} from './StyledHeaderDetails';
import DirectionLine from '../../../../SubscriptionsPage/DirectionLine';
import {helpers} from '../../../../../../helpers';
import {subscriptionsHelpers} from '../../../../SubscriptionsPage/subscriptionsHelpers';

const TransactionHeaderDetails = ({
  companyCountry,
  currentCurrency,
  data,
  transactionsCount,
  dispatch,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [spends, setSpends] = useState({
    ytg: 0,
    lastPayment: 0,
    nextPayment: null,
    trend: 0,
  });
  const gObjProp = (key, defaultValue = '') => helpers.getObjProp(data, key, defaultValue);

  const {lastPayment, ytg} = spends;
  const paymentTrans = t('payment');
  const billType = t(subscriptionsHelpers.getBillType(gObjProp('billed')));

  useEffect(() => {
    if (data) {
      setSpends({
        ytg: data.ytg,
        lastPayment: data.last_payment,
        nextPayment: data.next_payment,
        trend: data.trend,
      });
    }
  }, [data]);

  const getPrice = (value) => value ? helpers.getMoneyView(value, companyCountry) : 'N.A.';

  const getNextPaymentDate = () => {
    const {nextPayment} = spends;
    if (helpers.isNaV(nextPayment)) return 'on --/--/----';

    return subscriptionsHelpers.getNextPaymentDate({next_payment: nextPayment}, 'DD/MM/YYYY');
  }

  const getTrend = () => {
    const {trend} = spends;
    const isPositive = trend < 0;
    return {
      description: trend === 0 ? 'N.A' : <span className={`trend ${isPositive ? 'success' : 'error'}`}>{isPositive ? '-' : '+'}{Math.abs(trend)}%</span>,
      extra: trend !== 0 ? <DirectionLine direction={isPositive ? 'up' : 'down'} /> : undefined
    }
  }

  const transactionDescription = transactionsCount
    ? `${transactionsCount} ${t(transactionsCount > 1 ? 'payments' : 'payment')}`
    : `- ${t('payments')}`;

  const nextPayment = getNextPaymentDate();

  return (
    <StyledHeaderDetails {...rest}>
      <TotalCounter
        title={`${t('last')} ${paymentTrans}`}
        value={getPrice(lastPayment)}
        {...getTrend()}
      />

      <TotalCounter
        description={billType}
        title={`${t('next')} ${paymentTrans}`}
        value={nextPayment}
      />

      <TotalCounter
        description={transactionDescription}
        title={`${t('spending')} ${t('ytd')}`}
        value={getPrice(ytg)}
      />
    </StyledHeaderDetails>
  );
}

TransactionHeaderDetails.propTypes = {
  data: PropTypes.shape({
    trend: PropTypes.number.isRequired,
    ytg: PropTypes.number.isRequired,
    next_payment: PropTypes.number,
    last_payment: PropTypes.number
  }),
  transactionsCount: PropTypes.number
}

TransactionHeaderDetails.defaultProps = {
  transactionsCount: 0
}

const mapStateToProps = state => {
  const {country, currentCurrency} = state.company;
  return {
    companyCountry: country,
    currentCurrency
  }
}

export default connect(mapStateToProps, null)(TransactionHeaderDetails);
