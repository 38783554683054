import React, {useEffect, useMemo, useState} from 'react';
import {Form, Space, Spin} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledLegalRepresentativeDocuments,
  StyledLegalRepresentativeDocumentsSpace,
  StyledLegalRepresentativeDocumentsInformation,
  StyledLegalRepresentativeDocumentsUploadContainer,
  StyledLegalRepresentativeDocumentsUploadNumber
} from './StyledLegalRepresentativeDocuments';
import Upload from '../../stepsComponents/Upload';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import DocumentUploadRules from '../../stepsComponents/DocumentUploadRules';
import {kycHelpers} from '../../kycHelpers';
import {ReactComponent as DocumentUploadSvgImage} from '../../../../../../static/images/pages/kyc/document-upload-front.svg';

const {Item} = Form;

const initialFormValues = {
  document_1: undefined,
  document_2: undefined
}

const acceptFileTypes = kycHelpers.getAcceptFileTypes();

const getDocuments = (key) => {
  const counts = {
    'driving-license': 2,
    'id-card': 2,
    'passport': 1
  }
  const count = counts[key] || 1;
  return [...Array(count).keys()].map(i => `document-${i + 1}`);
}

const formKey = 'lrDocuments';

const LegalRepresentativeDocuments = React.forwardRef(({
  defaultValues,
  onSubmit,
  variant,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();
  const [values, setValues] = useState({});

  const trans = (key) => t(`quickStart:kyc.${key}`);

  const rule = (key) => trans(`documentUploadRules.${key}`);

  const documentUploadRules = [
    {label: rule('governmentIssued'), variant: 'success'},
    {label: rule('originalFullSizedUnedited'), variant: 'success'},
    {label: rule('readableColouredImages'), variant: 'success'},
    {label: rule('notBlackAndWhiteImages'), variant: 'error'},
    {label: rule('notCutBlurryOrReflective'), variant: 'error'},
    {label: rule('notEditedOrExpiredDocument'), variant: 'error'}
  ];

  const documents = useMemo(() => getDocuments(variant), [variant]);

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues);
      form.setFieldsValue(defaultValues);
    }
  }, [defaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    validationRequiredRules,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey}), [t]);

  const handleChangeDocument = ({name, files}) => {
    const value = files.length > 0 ? files[0] : undefined;
    setValues({
      ...values,
      [name]: value
    });
    form.setFieldValue(name, value);
  }

  const onFinish = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (errors) => {
          setLoading(false);
          errors && form.setFields(errors);
        }
      );
    }
  }

  return (
    <StyledLegalRepresentativeDocuments {...rest}>
      <Spin spinning={loading}>
        <StyledLegalRepresentativeDocumentsSpace
          className='lr-documents-content'
          direction='horizontal'
          size={32}
        >
          <StyledLegalRepresentativeDocumentsInformation
            direction='vertical'
            size='middle'
          >
            <StyledKYCModalContentStepTitle>
              {`${title} ${trans(`steps.${formKey}.documentTypes.${variant}`)}`}
            </StyledKYCModalContentStepTitle>
            <Space
              direction='vertical'
              size='large'
              style={{width: '100%'}}
            >
              <DocumentUploadRules rules={documentUploadRules} />
            </Space>
          </StyledLegalRepresentativeDocumentsInformation>
          <Form
            className='file-upload-form'
            initialValues={initialFormValues}
            form={form}
            layout='vertical'
            onFinish={onFinish}
            ref={ref}
            requiredMark={false}
          >
            {documents.map((name, index) => {
              const file = values[name] || undefined;
              return (
                <Item
                  key={name}
                  name={name}
                  rules={validationRequiredRules}
                >
                  <StyledLegalRepresentativeDocumentsUploadContainer>
                    {documents.length > 1 && (
                      <StyledLegalRepresentativeDocumentsUploadNumber>
                        {index + 1}
                      </StyledLegalRepresentativeDocumentsUploadNumber>
                    )}
                    <Upload
                      image={index === 0 && <DocumentUploadSvgImage />}
                      defaultFileList={file ? [file] : []}
                      onChange={(files) => handleChangeDocument({name, files})}
                      uploadProps={{accept: acceptFileTypes}}
                    />
                  </StyledLegalRepresentativeDocumentsUploadContainer>
                </Item>
              )
            })}
          </Form>
        </StyledLegalRepresentativeDocumentsSpace>
      </Spin>
    </StyledLegalRepresentativeDocuments>
  );
});

LegalRepresentativeDocuments.propTypes = {
  onSubmit: PropTypes.func,
  variant: PropTypes.oneOf(['driving-license', 'id-card', 'passport'])
}

LegalRepresentativeDocuments.defaultProps = {
  variant: 'id-card'
}

export default LegalRepresentativeDocuments;
