import styled from 'styled-components';

const StyledUsage = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUsageDot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: ${props => props.filled ? props.theme.colors.primary_4 : props.theme.colors.primary_5 };
  border-radius: 50%;

  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;

export {StyledUsage, StyledUsageDot};
