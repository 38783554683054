import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {cardActions, transactionActions} from '../../../../state/actions';
import {bankingUserTypesConstants, SCAActionsConstants} from '../../../../constants';
import useIsEmployeeChanged from '../../../../hooks/useIsEmployeeChanged';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import {scaHelpers} from '../../../../scaHelpers';

const SCA_OPERATION_NAME = SCAActionsConstants.BALANCE_LOADING;

const BalanceLoading = ({
  getUserWalletsMinimal,
  getCardUsers,
  getWalletBalance,
  isAdmin,
  isKnownEmployee,
  isEnabledBanking,
  legalPersonId,
  storeBalanceDetails,
  storeBankingUserId,
  storeLegalPersonDetails,
  storeWireDetails,
}) => {
  const [startLoading, setStartLoading] = useState(false);
  const [t] = useTranslation('main');
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const isEmployeeChanged = useIsEmployeeChanged(true);

  useEffect(() => {
    if (isEnabledBanking && isKnownEmployee) {
      setStartLoading(true);
      getCardUsers(
        {user_type: bankingUserTypesConstants.LEGAL_PERSON},
        (users) => {
          if (users.length > 0) {
            const legalPersonUser = users[0];
            const userId = legalPersonUser.id;
            storeLegalPersonDetails(legalPersonUser);
            isAdmin && loadWalletDetails(userId);
          } else {
            storeBalanceDetails(undefined);
            setStartLoading(false);
          }
        },
        () => {
          storeBalanceDetails(undefined);
          storeWireDetails(undefined);
          setStartLoading(false);
        }
      )
    }
  }, [isEnabledBanking, isKnownEmployee, isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadWalletDetails = (userId) => {
    let walletUserid = userId || legalPersonId;
    const headers = scaHelpers.getAuthHeaders(SCA_OPERATION_NAME);
    !startLoading && setStartLoading(true);
    storeBankingUserId(walletUserid);
    getUserWalletsMinimal({
      id: walletUserid,
      successCallback: (wallets) => {
        if (wallets.length > 0) {
          const wallet = wallets[0];
          storeWireDetails(wallet, true);

          getWalletBalance({
            headers,
            userId: walletUserid,
            walletId: wallet.id,
            successCallback: (balance) => {
              storeBalanceDetails(balance, true);
              setStartLoading(false);
            },
            errorCallback: (response) => {
              scaHelpers.SCAResponseCallback({
                response,
                scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
                errorCallback: () => {
                  storeBalanceDetails(undefined);
                  setStartLoading(false);
                }
              });
            }
          });
        } else {
          storeWireDetails(undefined);
          setStartLoading(false)
        }
      },
    })
  }

  const handleCloseAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    loadWalletDetails();
  }

  return (
    <>
      <AuthenticationWindow
        {...authWindowProps}
        authModalProps={{title: t('balanceInquiry')}}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />
    </>
  );
}

const mapStateToProps = state => {
  const {isKnownEmployee, isAdmin} = state.user;
  const {cards, isEnabledBanking, legalPerson} = state.card;
  const legalPersonId = legalPerson?.id || undefined;

  return {
    cards,
    legalPersonId,
    isAdmin,
    isEnabledBanking,
    isKnownEmployee
  }
}

const mapDispatchToProps = {
  getUserWalletsMinimal: cardActions.getUserWalletsMinimal,
  getCardUsers: cardActions.getCardsUsers,
  getWalletBalance: cardActions.getUserWalletBalance,
  storeBalanceDetails: transactionActions.storeBalanceDetails,
  storeBankingUserId: transactionActions.storeBankingUserId,
  storeLegalPersonDetails: cardActions.storeLegalPersonDetails,
  storeWireDetails: transactionActions.storeWireDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceLoading);
