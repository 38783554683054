import styled from 'styled-components';

const StyledArrowIcon = styled.div`
  svg {
    height: 32px;
    width: 32px;
    path {
      fill: ${props => props.direction === 'up' ? props.theme.colors.danger : props.theme.colors.success};
    }
  }
`;

export {
  StyledArrowIcon
}
