import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Modal, Spin} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import {teamActions} from '../../state/actions';
import {StyledTeamUserPage, StyledTeamUserPageSpace} from './StyledTeamUserPage';
import HeaderDetails from '../../components/pages/TeamUserPage/HeaderDetails';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import UserSubscriptions from '../../components/pages/TeamUserPage/UserSubscriptions';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import DisableUserModal from '../../components/pages/TeamPage/DisableUserModal';
import routes from '../../routes/routes.json';
import {teamsHelpers} from '../../components/pages/TeamPage/teamsHelpers';
import {SCAActionsConstants, userStatusesConstants} from '../../constants';
import {scaHelpers} from '../../scaHelpers';

const {logUserEvent} = teamsHelpers;

const {DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION} = SCAActionsConstants;
const {DISABLED, ACTIVE} = userStatusesConstants;

const TeamUserPage = ({
  deleteEmployee,
  getEmployee,
  updateEmployee,
  updateEmployeeStatus,
  employeeEmail,
  resendInvite
}) => {
  const {email} = useParams();
  const [t] = useTranslation(['main', 'teams']);
  const [employee, setEmployee] = useState(null);
  const [pageLoading, setPageLoading] = useState({loading: true, isExist: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeAction, setActiveAction] = useState('');
  const [terminateRelatedSubscriptions, setTerminateRelatedSubscriptions] = useState(false);
  const [isOpenDisableModal, setIsOpenDisableModal] = useState(false);

  const subscriptionsCount = employee?.subscriptions_owned_qty || 0;

  const hasSubscriptions = subscriptionsCount > 0;

  const navigate = useNavigate();
  const finishLoading = (isExist = true) => setPageLoading({...pageLoading, isExist, loading: false});

  const handleStartLoading = () => setPageLoading({...pageLoading, loading: true});

  useEffect(() => {
    email && getUserInfo(email);
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserInfo = (email) => {
    if (!pageLoading.loading) handleStartLoading();
    getEmployee(
      email,
      (employee) => {
        setEmployee(employee);
        finishLoading(true);
      },
      () => finishLoading(false)
    )
  }

  const getConfirmModalProps = (props) => teamsHelpers.getConfirmModalProps({t, employee, ...props})

  const getSCAProps = (action) => ({
    headers: scaHelpers.getAuthHeaders(action || activeAction),
    errorCallback: (response) => {
      scaHelpers.SCAResponseCallback({
        response,
        scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
        errorCallback: finishLoading
      });
    }
  })

  const handleOnRoleChange = (role, successCallback, errorCallback) => {
    updateEmployee(
      email,
      {role},
      successCallback,
      errorCallback
    );
  }

  const handleDeleteUser = () => {
    Modal.confirm({
      ...getConfirmModalProps({action: 'delete'}),
      onOk() {
        setPageLoading({...pageLoading, loading: true});
        deleteEmployee(
          email,
          () => {
            finishLoading();
            logUserEvent('deleted');
            navigate(routes.team);
          },
          finishLoading
        )
      }
    });
  }

  const handleResendInvite = (action) => {
    Modal.confirm({
      ...getConfirmModalProps({action}),
      onOk() {
        setPageLoading({...pageLoading, loading: true});
        resendInvite(
          email,
          null,
          () => {
            const updatedEmployee = teamsHelpers.getReInvitedUserData(employee);
            setEmployee(updatedEmployee);
            finishLoading();
            logUserEvent('invited');
          },
          finishLoading
        )
      }
    });
  }

  const handleDisableUser = () => setIsOpenDisableModal(true);

  const handleEnableUser = () => {
    Modal.confirm({
      ...getConfirmModalProps({action: 'enable'}),
      onOk() {
        setActiveAction(ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION);
        onEnableUser({action: ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION});
      }
    });
  }

  const handleUpdateUserStatus = ({
    bodyProps = {},
    headers,
    logStatusKey,
    status,
    errorCallback
  }) => {
    setPageLoading({...pageLoading, loading: true});
    updateEmployeeStatus({
      headers,
      email,
      data: {status, ...bodyProps},
      successCallback: () => {
        // update manually employee
        setEmployee({
          ...employee,
          account_status: status
        });
        finishLoading();
        logUserEvent(logStatusKey);
      },
      errorCallback: (response) => {
        errorCallback && errorCallback(response);
        finishLoading();
      }
    });
  }

  const onEnableUser = ({action} = {}) => {
    handleUpdateUserStatus({
      status: ACTIVE,
      logStatusKey: 'enabled',
      ...getSCAProps(action)
    });
  }

  const onDisableUser = ({action, terminate = undefined} = {}) => {
    const isTerminateUserSubscriptions = terminate !== undefined ? terminate : terminateRelatedSubscriptions;
    handleUpdateUserStatus({
      bodyProps: {is_terminate_user_subscriptions: isTerminateUserSubscriptions},
      logStatusKey: 'disabled',
      status: DISABLED,
      ...getSCAProps(action)
    });
  }

  const handleOnActionClick = ({action}) => {
    const actions = {
      disable: handleDisableUser,
      delete: handleDeleteUser,
      enable: handleEnableUser,
      invite: () => handleResendInvite('invite'),
      'resend-invite': () => handleResendInvite('resend invitation')
    }
    actions.hasOwnProperty(action) && actions[action]();
  }

  const handleCancelAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const handleCancelDisableModal = () => setIsOpenDisableModal(false);

  const handleOkDisableModal = (_, {terminate}) => {
    setTerminateRelatedSubscriptions(terminate);
    setIsOpenDisableModal(false);
    setActiveAction(DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION);
    onDisableUser({action: DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, terminate});
  }

  const onSuccessAuth = () => {
    const actions = {
      [DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: onDisableUser,
      [ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: onEnableUser,
    }
    handleCancelAuthModal();
    if (actions.hasOwnProperty(activeAction)) actions[activeAction]();
  }

  const getPageTitle = () => {
    let title = email;
    if (employee && employee.full_name) title = employee.full_name;
    return title;
  }

  const pageTitle = getPageTitle();
  const documentTitle = `${t('pageTitles.teamUserDetails')} ${pageTitle}`;

  const breadcrumbs = [
    {title: t('navigation.team'), href: routes.team},
    {title: pageTitle}
  ];

  const {isExist, loading} = pageLoading;

  return (
    <>
      <PageDocumentDetails title={documentTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <StyledTeamUserPage>
        <Spin spinning={loading}>
          <StyledTeamUserPageSpace
            direction='vertical'
            size='large'
          >

            {isExist ? (
              <>
                <HeaderDetails
                  data={employee}
                  enableActions={email !== employeeEmail}
                  onActionClick={handleOnActionClick}
                  onRoleChange={handleOnRoleChange}
                />
              </>
            ) : !loading && <NotFoundPage />}

            {hasSubscriptions && (
              <UserSubscriptions
                email={email}
                title={`${t('softwareUsage')} (${subscriptionsCount})`}
              />
            )}

          </StyledTeamUserPageSpace>
        </Spin>

        <AuthenticationWindow
          {...authWindowProps}
          handleCancel={handleCancelAuthModal}
          onSuccess={onSuccessAuth}
          operationName={activeAction}
        />

        <DisableUserModal
          handleCancel={handleCancelDisableModal}
          handleOk={handleOkDisableModal}
          open={isOpenDisableModal}
        />
      </StyledTeamUserPage>
    </>
  );
}

const mapStateToProps = state => {
  const {employee} = state.user;

  return {
    employeeEmail: employee.email,
  }
}

const mapDispatchToProps = {
  getEmployee: teamActions.getEmployee,
  deleteEmployee: teamActions.deleteEmployee,
  updateEmployee: teamActions.updateEmployee,
  resendInvite: teamActions.resendInvite,
  updateEmployeeStatus: teamActions.updateEmployeeStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserPage);
