import styled from 'styled-components';

const StyledLoginPage = styled.div`
  .image-content {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
    
    & > div {
      text-align: center;
    }

    &.step-2 {
      align-items: end;
      
      svg {
        max-width: 551px;
      }
    }

    .leaf {
      display: inline-flex;
      position: absolute;
      
      svg {
        width: unset;
      }

      &.top-left {
        align-items: start;
        left: 0;
        top: 9px;
        max-width: 210px;
        width: 30.75%;
      }
      &.top-right {
        align-items: start;
        justify-content: end;
        top: 24px;
        right: 0;
        max-width: 160px;
        width: 23.43%;
      }
      &.bottom-left {
        align-items: end;
        left: 28px;
        bottom: 0;
        max-width: 180px;
        width: 26.56%;
      }
      &.bottom-right {
        align-items: end;
        justify-content: end;
        right: 0;
        bottom: 0;
        max-width: 165px;
        width: 24.17%;
      }
    }
  }
  
  a.ant-input-password-icon {
    font-size: 14px;
  }
`;


export {
  StyledLoginPage
}
