import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, DatePicker, Divider, Form, Select, Spin} from 'antd';
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Collapse, UnmountClosed} from 'react-collapse';
import moment from 'moment';
import {EuroIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionReviewTypesConstants,
} from '../../../../constants';
import {StyledAuthorizeSubscriptionModal,} from './StyledAuthorizeSubscriptionModal';
import {
  StyledAddSingleSubscriptionModalSegmented,
  StyledAddSingleSubscriptionModalCardInfo,
  StyledAddSingleSubscriptionModalAccordion
} from '../AddSingleSubscriptionModal/StyledAddSingleSubscriptionModal';
import CompanyTagSelect from '../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import {
  BillOptions,
  CardLimitPeriodTypeOptions,
  PaymentOptions,
  SubscriptionReviewTypeOptions
} from '../subscriptionsHelpers';
import {StyledInput} from '../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import {StyledBudgetDetailsFormSpace} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import IncreaseLimitAlert from '../IncreaseLimitAlert';
import {cardsHelpers} from '../../CardsPage/cardsHelpers';
import SubscriptionInfo from '../../SubscriptionPage/SubscriptionInfo';

const {Item} = Form;

const {CARD, FREE} = subscriptionPaymentTypesConstants;
const {
  budgetLimitFieldName,
  cardLimitPeriodFieldName,
  cardLimitFieldName,
  datePickerFormat
} = subscriptionFormFields;

const {
  defaultBudgetLimit,
  defaultBilled,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const defaultFormValues = {
  billed: defaultBilled,
  [budgetLimitFieldName]: defaultBudgetLimit,
  [cardLimitFieldName]: defaultBudgetLimit,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  contract_renewal_date: undefined,
  owner: null,
  tags: []
};

const AuthorizeSubscriptionModal = ({
  dispatch,
  loading,
  employees,
  employeeEmail,
  isEnabledBanking,
  paymentCardLimit,
  handleClose,
  handleOk,
  open,
  subscription,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState(isEnabledBanking ? CARD : FREE);
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [defaultPaymentOptions,] = useState(PaymentOptions());
  const [defaultSubscriptionTypeOptions, ] = useState(SubscriptionReviewTypeOptions());
  const [subscriptionType, setSubscriptionType] = useState(subscriptionReviewTypesConstants.FREE);

  const paymentOptions = useMemo(() => {
    let options = [...defaultPaymentOptions];
    if (!isEnabledBanking) options = options.filter(o => o.value !== CARD);
    return options;
  }, [defaultPaymentOptions, isEnabledBanking]);

  const subscriptionTypeOptions = useMemo(() => {
    let options = [...defaultSubscriptionTypeOptions];
    if (!isEnabledBanking) options = options.filter(o => o.value !== subscriptionReviewTypesConstants.PAID);
    return options;
  }, [defaultSubscriptionTypeOptions, isEnabledBanking]);

  const isCardPaymentType = useMemo(() => paymentType === CARD, [paymentType]);

  const isPaidSubscriptionType = useMemo(() => subscriptionType === subscriptionReviewTypesConstants.PAID, [subscriptionType]);

  const budgetLimit = Form.useWatch(budgetLimitFieldName, {form});

  const isTooHighLimit = useMemo(() => {
    let isHigh = false;
    if (isCardPaymentType && budgetLimit) isHigh = budgetLimit > paymentCardLimit;
    return isHigh;
  }, [budgetLimit, paymentCardLimit, isCardPaymentType]);

  const disabledCreateButton = useMemo(() => loading || isTooHighLimit, [loading, isTooHighLimit]);

  useEffect(() => {
    form.setFieldValue('owner', employeeEmail);
  }, [employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const formFieldValues = {
        ...defaultFormValues,
        owner: employeeEmail
      };
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
      setPaymentType(isEnabledBanking ? CARD : FREE);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = helpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key) => t(`subscriptions:${key}`);

  const formT = (key) => trans(`modal.addSingleSubscription.${key}`);

  const placeholderT = (key) => formT(`placeholder.${key}`);

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}]

  const footer = (
    <Button
      className='green-btn'
      disabled={disabledCreateButton}
      onClick={() => !disabledCreateButton && form.submit()}
      size='large'
    >
      {t('create')}
    </Button>
  );

  const onChangeTags = (tags) => {
    form.setFieldValue('tags', tags);
    setInitialFormValues({...initialFormValues, tags});
  }

  const handleSubmit = (data) => {
    const getFieldValue = (key, defaultValue) => helpers.getObjProp(data, key, defaultValue) || defaultValue;
    const contractRenewalDate = data.contract_renewal_date;
    const {owner} = data;
    let formData = {
      owner: owner,
      payment_type: isPaidSubscriptionType ? paymentType : FREE,
      tags: data.tags
    }

    if (isPaidSubscriptionType) {
      formData = {
        ...formData,
        [budgetLimitFieldName]: getFieldValue(budgetLimitFieldName, defaultBudgetLimit),
        billed: data.billed,
        contract_renewal_date: contractRenewalDate ? helpers.getMomentUnixTimestamp(contractRenewalDate) : contractRenewalDate,
      }
      if (isCardPaymentType) {
        formData = {
          ...formData,
          [cardLimitFieldName]:getFieldValue(cardLimitFieldName, defaultBudgetLimit),
          [cardLimitPeriodFieldName]: getFieldValue(cardLimitPeriodFieldName, defaultCardLimitPeriod),
          card_owner: owner,
          expected_first_payment: helpers.getMomentUnixTimestamp(moment())
        }
      }
    }

    handleOk && handleOk(formData);
  }

  const onClose = (e) => handleClose && handleClose(e);

  return (
    <StyledAuthorizeSubscriptionModal
      forceRender
      footer={footer}
      onClose={onClose}
      open={open}
      title={`${trans('authorize')} ${trans('subscription')}`}
      width={472}
      {...rest}
    >
      <Spin spinning={loading}>
        <Form
          initialValues={initialFormValues}
          onFinish={handleSubmit}
          layout='vertical'
          form={form}
          requiredMark={false}
        >
          <SubscriptionInfo
            data={subscription}
            enabledStatus={false}
          />
          <StyledAddSingleSubscriptionModalSegmented
            block={true}
            onChange={setSubscriptionType}
            options={subscriptionTypeOptions}
            size='large'
            value={subscriptionType}
          />
          <Item
            label={t('Owner')}
            name='owner'
          >
            <Select
              options={ownerOptions}
              size='large'
            />
          </Item>
          <Item
            label={t('costCentre')}
            name='tags'
            tooltip={{ icon: <InfoCircleOutlined /> }}
          >
            <CompanyTagSelect
              inputProps={{
                placeholder: placeholderT('costCentre'),
                size: 'large'
              }}
              onChange={onChangeTags}
              selected={initialFormValues.tags}
            />
          </Item>
          <UnmountClosed isOpened={isPaidSubscriptionType}>
            <StyledBudgetDetailsFormSpace size='middle'>
              <Item
                label={t('Budget')}
                name={budgetLimitFieldName}
                required
              >
                <StyledInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name='billed'
                rules={requiredRules}
              >
                <Select
                  options={billOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>
            <Divider />
            <Item
              label={t('paymentMethod')}
              required
            >
              <StyledAddSingleSubscriptionModalSegmented
                block={true}
                onChange={setPaymentType}
                options={paymentOptions}
                size='large'
                value={paymentType}
              />
            </Item>
            <Collapse isOpened={isTooHighLimit}>
              <IncreaseLimitAlert
                limit={paymentCardLimit}
                handleBeforeRedirect={onClose}
              />
            </Collapse>
            <Collapse isOpened={isCardPaymentType}>
              <StyledAddSingleSubscriptionModalCardInfo align='start'>
                <InfoCircleOutlined />
                {placeholderT('card')}
              </StyledAddSingleSubscriptionModalCardInfo>
            </Collapse>
            <Divider />
            <StyledAddSingleSubscriptionModalAccordion
              ghost
              expandIconPosition='end'
              expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined /> }
              items={[
                {
                  key: '1',
                  label: `${t('advanced')} ${t('settings')}`,
                  children: (
                    <>
                      <UnmountClosed isOpened={isCardPaymentType}>
                        <StyledBudgetDetailsFormSpace size='middle'>
                          <Item
                            label={`${t('card')} ${t('limit')}`}
                            name={cardLimitFieldName}
                            required
                          >
                            <StyledInput
                              addonBefore={<EuroIcon />}
                              min={1}
                              size='large'
                              type='number'
                            />
                          </Item>
                          <Item
                            label=' '
                            name={cardLimitPeriodFieldName}
                            rules={requiredRules}
                          >
                            <Select
                              options={cardLimitPeriodTypeOptions}
                              size='large'
                            />
                          </Item>
                        </StyledBudgetDetailsFormSpace>
                      </UnmountClosed>
                      <Item
                        label={trans('renewalReminder')}
                        name='contract_renewal_date'
                      >
                        <DatePicker
                          format={datePickerFormat}
                          size='large'
                        />
                      </Item>
                    </>
                  )
                }
              ]}
            />
          </UnmountClosed>
        </Form>
      </Spin>
    </StyledAuthorizeSubscriptionModal>
  );
}

AuthorizeSubscriptionModal.propTypes = {
  subscription: PropTypes.object,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func
}

AuthorizeSubscriptionModal.defaultProps = {
  loading: false
}

const mapStateToProps = state => {
  const {isEnabledBanking} = state.card;
  const {employees} = state.company;
  const {employee} = state.user;
  const paymentCardLimit = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employeeEmail: employee.email,
    isEnabledBanking,
    paymentCardLimit
  }
}

export default connect(mapStateToProps, null)(AuthorizeSubscriptionModal);

