import styled from 'styled-components';
import {Modal, Space} from 'antd';

const StyledKYCModal = styled(Modal)`
  height: 100vh;
  max-width: 100vw;
  top: 0;
  padding-bottom: 0;
  width: 100vw !important;
  
  .ant-modal-content {
    border-radius: 0;
    height: 100vh;
    padding: 0;
  }
  
  .ant-modal-close {
    top: 32px;
    inset-inline-end: 32px;
  }
  
  .ant-modal-body {
    display: flex;
    height: 100vh;
    
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

const StyledKYCModalContent = styled.div`
  color: ${props => props.theme.colors.neutral_10};
  overflow: auto;
  
  .left-side-content {
    max-width: 400px;
  }
  
  .column-form-content {
    max-width: 832px;
  }
`;


const StyledKYCModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.neutral_4};
  height: 104px;
  padding: 24px 40px;
  
  .ant-btn {
    height: 56px;
  }
`;

const StyledKYCModalMenu = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  width: 24%;
  min-width: 336px;
  max-width: 400px;
  
  ${StyledKYCModalContent} {
    padding: 56px 40px;
  }
  
  ${StyledKYCModalFooter} {
    padding: 24px 40px;
  }
`;

const StyledKYCModalContentStepTitle = styled.div`
  color: ${props => props.theme.colors.neutral_10};
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
`;

const StyledKYCModalContentColumnFormSpace = styled(Space)`
  width: 100%;
  
  & > div {
    width: calc(50% - 16px);
  }
  
  .ant-picker {
    width: 100%;
  }
`;

const StyledKYCModalTitleNameSpace = styled(Space)`
  width: 100%;
  
  & > div {
    width: 100%;
    &:first-of-type {
      min-width: 88px;
      width: 88px;
    }
  }
  
  .ant-picker {
    width: 100%;
  }
`;

export {
  StyledKYCModal,
  StyledKYCModalMenu,
  StyledKYCModalContent,
  StyledKYCModalFooter,
  StyledKYCModalContentStepTitle,
  StyledKYCModalContentColumnFormSpace,
  StyledKYCModalTitleNameSpace
};
