import styled from 'styled-components';
import {Menu, Tag} from 'antd';


const StyledTagSelect = styled.div`
  .ant-input-affix-wrapper  > .ant-input {
    font-size: 16px;
  }
`;

const DropdownContainer = styled.div``;

const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  label {
    font-size: 16px;
  }
  &.ant-dropdown-menu-item-selected {
    background-color: unset !important;
  }
`;

const DropdownContent = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 4px;
  position: relative;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 3px 6px -4px rgba(0, 0, 0, 0.12),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-dropdown-menu {
    box-shadow: unset;
    max-height: 250px;
    overflow: auto;
  }

  .dropdown-searched-content .ant-typography {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    margin-bottom: 0;
    padding: 8px 12px;
  }
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-top: 8px;
  padding: 5px 8px;
`;

const TagsList = styled.div`
  margin-top: 8px;
`;

const SearchContainer = styled.div`
  position: absolute;
  left: 4px;
  width: calc(100% - (4px * 2));
  & > div {
    width: 100%;
    button.ant-btn {
      background-color: ${props => props.theme.colors.secondary_6};
      color: ${props => props.theme.colors.neutral_1};
      font-size: 14px;

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: ${props => props.theme.colors.secondary_5};
        border-color: ${props => props.theme.colors.secondary_5};
        color: ${props => props.theme.colors.neutral_1};
      }

      &:not(:disabled):not(.ant-btn-disabled):active {
        background-color: ${props => props.theme.colors.secondary_7};
        border-color: ${props => props.theme.colors.secondary_7};
        color: ${props => props.theme.colors.neutral_1};
      }
      &:disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export {
  DropdownContent,
  DropdownContainer,
  SearchContainer,
  StyledTagSelect,
  StyledMenu,
  StyledMenuItem,
  StyledTag,
  TagsList
};
