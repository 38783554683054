import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Modal, Spin} from 'antd';
import moment from 'moment/moment';
import {StyledAddMultipleSubscriptionsModal} from './StyledAddMultipleSubscriptionsModal';
import TransferList from '../SubscriptionBulkCreation/TransferList';
import SelectedServicesList from '../SubscriptionBulkCreation/SelectedServiceList/SelectedServiceList';
import {helpers} from '../../../../helpers';
import {subscriptionFormFields, subscriptionFormValues, subscriptionPaymentTypesConstants} from '../../../../constants';
import {subscriptionsHelpers} from '../subscriptionsHelpers';

const {getVisibleClassName: gVClassName} = helpers;

const {cardLimitFieldName, cardLimitPeriodFieldName} = subscriptionFormFields;
const {defaultBilled, defaultBudgetLimit, defaultCardLimitPeriod} = subscriptionFormValues;

const minSearchSymbolsLength = 3;

const optionPropName= 'name';

const AddMultipleSubscriptionsModal = ({
  loading,
  handleClose,
  handleOk,
  onSearch,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [step, setStep] = useState(1);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  // need for recreate transfer elements
  const [enabledTransfers, setEnabledTransfers] = useState(true);

  const submitServicesRef = useRef(null);

  const handleSubmitModal = () => {
    if (submitServicesRef && submitServicesRef.current) {
      submitServicesRef.current.click();
    }
  }

  const handleBack = () => setStep(step - 1);

  const handleNext = () => setStep(step + 1);

  useEffect(() => {
    (open && !enabledTransfers) && setEnabledTransfers(true);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAfterClose = () => {
    if (selectedServices.length > 0) {
      setSelectedServices([]);
      step > 1 && setStep(1);
      setEnabledTransfers(false);
    }
    setServices([]);
  }

  const trans = (key) => t(`subscriptions:${key}`);

  const formT = (key) => trans(`modal.addMultipleSubscription.${key}`);

  const onClose = () => {
    const handleOnClose = () => handleClose && handleClose();
    if (selectedServices.length > 0) {
      Modal.confirm({
        cancelText: t('no'),
        cancelButtonProps: {className: 'green-btn-outlined', size: 'large'},
        centered: true,
        okButtonProps: {className: 'green-btn', size: 'large'},
        okText: t('yes'),
        onOk: handleOnClose,
        title: formT('confirm.title')
      });
    } else {
      handleOnClose();
    }
  }

  const clearServices = () => setServices([]);

  const handleOnSearch = (value) => {
    value = subscriptionsHelpers.getSearchedDomainName(value);
    if (value.length < minSearchSymbolsLength && Boolean(services.length)) {
      clearServices();
    } else if (value !== '' && onSearch) {
      onSearch(value, setServices, clearServices);
    }
  }

  const handleServiceRemove = (service) => setSelectedServices(selectedServices.filter(s => s.id !== service.id));

  const getModalProps = () => {
    let props = {};
    switch (step) {
      case 1:
        const disabled = selectedServices.length === 0;
        props = {
          footer: [
            <Button
              className='green-btn'
              disabled={disabled}
              key='next'
              onClick={(...props) => !disabled && handleNext(...props)}
              size='large'
            >
              {t('next')}
            </Button>
          ],
          okText: t('next'),
          handleOk: handleNext,
        }
        break;
      case 2:
        props = {
          footer: [
            <Button
              key='back'
              onClick={handleBack}
              size='large'
            >
              {t('back')}
            </Button>,
            <Button
              className='green-btn'
              key='confirm'
              onClick={handleSubmitModal}
              size='large'
            >
              {t('confirm')}
            </Button>
          ],
        }
        break;
      default:
        break;
    }
    return props;
  }

  const handleServicesSubmit = (data) => {
    const services = data.map(service => {
      const owner = service.owner;
      const paymentType = service.payment_type;
      let serviceData = {
        card_owner: owner,
        owner: owner,
        status: service.status,
        target_status: service.status,
        payment_type: paymentType,
        service: service[optionPropName]
      }
      if (paymentType === subscriptionPaymentTypesConstants.CARD) {
        serviceData = {
          ...serviceData,
          billed: defaultBilled,
          [cardLimitFieldName]: defaultBudgetLimit,
          [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
          expected_first_payment: helpers.getMomentUnixTimestamp(moment())
        }
      }
      return serviceData;
    });
    handleOk && handleOk(services);
  }

  const modalProps = getModalProps();

  return (
    <StyledAddMultipleSubscriptionsModal
      forceRender
      onCancel={onClose}
      open={open}
      title={`${t('add')} ${t('subscriptions')}`}
      width={1088}
      afterClose={handleAfterClose}
      {...modalProps}
      {...rest}
    >
      <Spin spinning={loading}>
        {enabledTransfers && (
          <TransferList
            className={gVClassName('transfer-list', step === 1)}
            onChange={setSelectedServices}
            optionPropName={optionPropName}
            onSearch={handleOnSearch}
            services={services}
            selected={selectedServices}
          />
        )}
        <SelectedServicesList
          className={gVClassName('selected-services-list', step === 2)}
          services={selectedServices}
          onSubmit={handleServicesSubmit}
          onRemove={handleServiceRemove}
          optionPropName={optionPropName}
          ref={submitServicesRef}
        />
      </Spin>
    </StyledAddMultipleSubscriptionsModal>
  );
}

AddMultipleSubscriptionsModal.propTypes = {
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  onSearch: PropTypes.func
}

AddMultipleSubscriptionsModal.defaultProps = {
  loading: false
}

export default AddMultipleSubscriptionsModal;
