import React, {useEffect, useMemo} from 'react';
import {DatePicker, Form, Input, Select} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledUserDetailsForm} from './StyledUserDetailsForm';
import {StyledKYCModalContentColumnFormSpace, StyledKYCModalTitleNameSpace} from '../../../quickStart/KYCModal/StyledKYCModal';
import {kycHelpers} from '../../../quickStart/components/KYCModal/kycHelpers';
import PhoneNumberInput from '../../../PhoneNumberInput';
import useLocation from '../../../../hooks/useLocation';
import AntdCountriesSelect from '../../../Select/AntdCountriesSelect';
import {
  StyledLegalRepresentativeInformationFormCheckbox
} from '../../../quickStart/components/KYCModal/stepsComponents/LegalRepresentativeInformationForm/StyledLegalRepresentativeInformationForm';
import {helpers} from '../../../../helpers';
import {titleOptions} from '../../CardsPage/cardsOptions';

const {Item} = Form;

const defaultTitle = titleOptions[0].value;

const initialFormValues = {
  birthday: undefined,
  birth_country: undefined,
  firstname: '',
  email: '',
  lastname: '',
  nationality: undefined,
  phone: '',
  place_of_birth: '',
  title: defaultTitle,

  confirm_accept: false,
  confirm_seen: false,
}

const formSpaceProps = {
  align: 'start',
  size: 32
}

const countrySelectProps = kycHelpers.getCountrySelectProps();
const datePickerProps = kycHelpers.getDatePickerProps('birthday');

const UserDetailsForm = React.forwardRef(({
  enableConfirmation,
  defaultFormValues,
  handleEnableSubmit,
  onSubmit,
  ...rest
}, ref) => {
  const [t] = useTranslation(['main', 'userInvite']);
  const [form] = Form.useForm();
  const {phone_code: defaultPhoneCode, code: defaultCountryCode} = useLocation();

  const trzTermsUrl = useMemo(() => helpers.getTrzTermsUrl(defaultCountryCode), [defaultCountryCode]);

  const termsOfConditionsLink = (
    <a href={trzTermsUrl} target='_blank' rel='noreferrer'>
      {t('treezorsTermsAndConditions')}
    </a>
  );

  useEffect(() => {
    if (defaultCountryCode && helpers.isEmptyObject(defaultFormValues)) {
      form.setFieldsValue({
        country_of_birth: defaultCountryCode,
        nationality: defaultCountryCode
      });
    }
  }, [defaultCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultFormValues) {
      form.setFieldsValue({
        ...defaultFormValues,
        title: helpers.getObjProp(defaultFormValues, 'title') || defaultTitle
      });
    }
  }, [defaultFormValues, form]);

  const {
    label,
    lengthFieldMessage,
    placeholder,
    validationPhoneRules,
    validationRequiredRules,
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, fullFormKey: 'userInvite:steps.userDetails'}), [t]);

  const onFinish = (fields) => {
    if (fields.hasOwnProperty('confirm_accept') && fields.hasOwnProperty('confirm_seen')) {
      const {confirm_accept, confirm_seen, ...data} = fields;
      onSubmit && onSubmit(data);
    } else {
      onSubmit && onSubmit(fields);
    }
  }

  const onValuesChange = (changedFields, allValues) => {
    kycHelpers.handleOnValuesChangeLRForm({allValues, changedFields, handleEnableSubmit});
  }

  return (
    <StyledUserDetailsForm {...rest}>
      <Form
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        ref={ref}
        requiredMark={false}
      >
        <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
          <StyledKYCModalTitleNameSpace {...formSpaceProps}>
            <Item
              label={label('title')}
              name='title'
              required
            >
              <Select
                options={titleOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('firstName')}
              name='firstname'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('firstName')}
                size='large'
              />
            </Item>
          </StyledKYCModalTitleNameSpace>
          <Item
            label={label('lastName')}
            name='lastname'
            rules={[
              ...validationRequiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')}
            ]}
          >
            <Input
              minLength={2}
              maxLength={50}
              placeholder={placeholder('lastName')}
              size='large'
            />
          </Item>
        </StyledKYCModalContentColumnFormSpace>

        <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
          <Item
            label={label('dateOfBirth')}
            name='birthday'
            rules={validationRequiredRules}
          >
            <DatePicker
              {...datePickerProps}
              placeholder={placeholder('dateOfBirth')}
            />
          </Item>
          <Item
            label={label('placeOfBirth')}
            name='place_of_birth'
            rules={validationRequiredRules}
          >
            <Input
              placeholder={placeholder('placeOfBirth')}
              size='large'
            />
          </Item>
        </StyledKYCModalContentColumnFormSpace>

        <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
          <Item
            label={label('countryOfBirth')}
            name='birth_country'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              placeholder={placeholder('countryOfBirth')}
              {...countrySelectProps}
            />
          </Item>
          <Item
            label={label('nationality')}
            name='nationality'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              placeholder={placeholder('nationality')}
              {...countrySelectProps}
            />
          </Item>
        </StyledKYCModalContentColumnFormSpace>
        <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
          <Item
            label={label('email')}
            name='email'
            rules={validationRequiredRules}
          >
            <Input
              placeholder={placeholder('email')}
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={label('phoneNumber')}
            name='phone'
            rules={validationPhoneRules}
          >
            <PhoneNumberInput
              placeholder={placeholder('phoneNumber')}
              phoneCode={defaultPhoneCode}
              size='large'
            />
          </Item>
        </StyledKYCModalContentColumnFormSpace>
        {enableConfirmation && (
          <>
            <Item
              className='checkbox-item'
              name='confirm_seen'
              valuePropName='checked'
              required
            >
              <StyledLegalRepresentativeInformationFormCheckbox>
                {t('quickStart:kyc.confirmations.confirmIHaveSeen')} {termsOfConditionsLink}
              </StyledLegalRepresentativeInformationFormCheckbox>
            </Item>
            <Item
              className='checkbox-item'
              name='confirm_accept'
              valuePropName='checked'
              required
            >
              <StyledLegalRepresentativeInformationFormCheckbox>
                {t('quickStart:kyc.confirmations.confirmIAccept')} {termsOfConditionsLink}
              </StyledLegalRepresentativeInformationFormCheckbox>
            </Item>
          </>
        )}
      </Form>
    </StyledUserDetailsForm>
  );
});

UserDetailsForm.propTypes = {
  enableConfirmation: PropTypes.bool
}

UserDetailsForm.defaultProps = {
  enableConfirmation: false
}

export default UserDetailsForm;
