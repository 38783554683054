import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Checkbox, Modal} from 'antd';

const DisableUserModal = ({
  open,
  handleOk,
  handleCancel,
  ...rest
}) => {
  const [trans] = useTranslation('teams');
  const [terminate, setTerminate] = useState(false);

  const t = (key) => trans(`modal.disable.${key}`);

  const onCheckboxChange = (e) => setTerminate(e.target.checked);

  const handleAfterOpenChange = (open) => !open && terminate && setTerminate(false);

  const onOk = (e) => handleOk && handleOk(e, {terminate});

  const footer = [
    <Button
      className='green-btn'
      key='submit'
      onClick={onOk}
      size='large'
    >
      {t('okButtonText')}
    </Button>
  ];

  return (
    <Modal
      forceRender
      footer={footer}
      onCancel={handleCancel}
      afterOpenChange={handleAfterOpenChange}
      open={open}
      title={t('title')}
      {...rest}
    >
      <Checkbox onChange={onCheckboxChange} checked={terminate}>
        {t('terminateSubscriptionsMessage')}
      </Checkbox>
    </Modal>
  );
}

DisableUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
}

export default DisableUserModal;
