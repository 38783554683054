import styled from 'styled-components';
import {Space} from 'antd';
import TotalCounter from '../../SubscriptionPage/tabComponents/Transactions/TotalCounter';

const StyledOverviewCounters = styled(Space)`
  width: 100%;
  
  .ant-space-item {
    width: 25%;
  }
`;

const StyledTotalCounter = styled(TotalCounter)`
  .title {
    line-height: 18px;
    min-height: 40px;
  }
  
  .value {
    display: flex;
    font-size: 24px;
    line-height: 32px;
  }
`;

export {
  StyledOverviewCounters,
  StyledTotalCounter
}
