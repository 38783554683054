import React from 'react';

const TransactionOrderIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.99984 0.666626C6.36803 0.666626 6.6665 0.965103 6.6665 1.33329V1.99996H9.33317V1.33329C9.33317 0.965103 9.63165 0.666626 9.99984 0.666626C10.368 0.666626 10.6665 0.965103 10.6665 1.33329V1.99996H12.6665C13.4029 1.99996 13.9998 2.59691 13.9998 3.33329V14C13.9998 14.7363 13.4029 15.3333 12.6665 15.3333H3.99984C3.26346 15.3333 2.6665 14.7363 2.6665 14V3.33329C2.6665 2.59691 3.26346 1.99996 3.99984 1.99996H5.33317V1.33329C5.33317 0.965103 5.63165 0.666626 5.99984 0.666626ZM5.33317 11C4.96498 11 4.6665 11.2984 4.6665 11.6666C4.6665 12.0348 4.96498 12.3333 5.33317 12.3333H7.99984C8.36803 12.3333 8.6665 12.0348 8.6665 11.6666C8.6665 11.2984 8.36803 11 7.99984 11H5.33317ZM5.33317 8.33329C4.96498 8.33329 4.6665 8.63177 4.6665 8.99996C4.6665 9.36815 4.96498 9.66663 5.33317 9.66663H9.33317C9.70136 9.66663 9.99984 9.36815 9.99984 8.99996C9.99984 8.63177 9.70136 8.33329 9.33317 8.33329H5.33317ZM5.33317 5.66663C4.96498 5.66663 4.6665 5.9651 4.6665 6.33329C4.6665 6.70148 4.96498 6.99996 5.33317 6.99996H10.6665C11.0347 6.99996 11.3332 6.70148 11.3332 6.33329C11.3332 5.9651 11.0347 5.66663 10.6665 5.66663H5.33317Z"
          fill="#262626"/>
  </svg>
);

export default TransactionOrderIcon;
