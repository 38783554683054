import styled from 'styled-components';

const StyledVerifyPageContainer = styled.div`
  min-height: 100vh;
  
  background-image: url(${props => props.bgImg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 60vh;
  
  @media (max-width: 600px) {
    background-size: auto min(50vw, 450px);
  },

  @media (max-width: 767px) {
    background-size: auto min(50vw, 450px);
  },
  @media (max-width: 600px) {
    background-size: auto 100vw;
  }
`;

const StyledVerifyPageContainerContent = styled.div`
  padding-top: 46px;
  padding-left: 114px;
  color: #191927;
  & a {
    display: inline-block;
  }
  & > img {
    height: 60px;
  }
  & > h1 {
    font-size: 60px;
    line-height: 65px;
    margin-top: 50px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  & > p {
    font-weight: 500;
    margin: 0;
    padding: 0;
    font-size: 36px;
    line-height: 34px;
  }
  @media (max-width: 900px) {
    padding-left: 5%;
    & > img {
      height: 54px
    }
    & > h1 {
      font-size: 52px;
      line-height: 58px;
    }
    & > p {
      font-size: 32px
    }
  }
  @media (max-width: 600px) {
    & > img {
      height: 48px;
    }
    & > h1 {
      font-size: 42px;
      line-height: 44px;
    }
    & > p {
      font-size: 26px;
      line-height: 28px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 30px;
    & > img {
      height: 42px
    }
    & > h1 {
      font-size: 36px;
      line-height: 38px;
    }
    & > p {
      font-size: 22px;
      line-height: 26px;
    }
  }
`;

export {
  StyledVerifyPageContainer,
  StyledVerifyPageContainerContent
}