import styled from 'styled-components';
import {Card, Space} from 'antd';

const getBadgeColor = (props) => {
  const {action} = props;
  const {colors} = props.theme;
  const actionColors = {
    'activateAccount': colors.warning,
    'bankingInfo': colors.warning,
    'continue': colors.secondary_3,
    'error': colors.danger
  }
  return actionColors[action] || props.neutral_4;
}

const StyledCompleteKycCard = styled(Card)`
  .ant-card-head {
    font-weight: 500;
  }
  .ant-card-body {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledCompleteKycCardTitleSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

const StyledCompleteKycCardBadge = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${props => getBadgeColor(props)};
  border-radius: 50%;
`;

export {
  StyledCompleteKycCard,
  StyledCompleteKycCardTitleSpace,
  StyledCompleteKycCardBadge
}