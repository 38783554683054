import React from 'react';

const InvoiceIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.6667 12.8083L11.9533 12.2683C11.8379 12.1807 11.6976 12.1333 11.5533 12.1333C11.4091 12.1333 11.2687 12.1807 11.1533 12.2683L9.78 13.3146L8.4 12.2683C8.2846 12.1807 8.14425 12.1333 8 12.1333C7.85575 12.1333 7.7154 12.1807 7.6 12.2683L6.22 13.3146L4.84667 12.2683C4.73127 12.1807 4.59091 12.1333 4.44667 12.1333C4.30242 12.1333 4.16206 12.1807 4.04667 12.2683L3.33333 12.8083V2.68333H12.6667V12.8083ZM10.6667 5.38333H6.66667C6.48986 5.38333 6.32029 5.31221 6.19526 5.18563C6.07024 5.05904 6 4.88735 6 4.70833C6 4.52931 6.07024 4.35762 6.19526 4.23103C6.32029 4.10444 6.48986 4.03333 6.66667 4.03333H10.6667C10.8435 4.03333 11.013 4.10444 11.1381 4.23103C11.2631 4.35762 11.3333 4.52931 11.3333 4.70833C11.3333 4.88735 11.2631 5.05904 11.1381 5.18563C11.013 5.31221 10.8435 5.38333 10.6667 5.38333ZM10.6667 8.08333H5.33333C5.15652 8.08333 4.98695 8.01221 4.86193 7.88562C4.7369 7.75904 4.66667 7.58735 4.66667 7.40833C4.66667 7.22931 4.7369 7.05762 4.86193 6.93103C4.98695 6.80444 5.15652 6.73333 5.33333 6.73333H10.6667C10.8435 6.73333 11.013 6.80444 11.1381 6.93103C11.2631 7.05762 11.3333 7.22931 11.3333 7.40833C11.3333 7.58735 11.2631 7.75904 11.1381 7.88562C11.013 8.01221 10.8435 8.08333 10.6667 8.08333ZM10.6667 10.7833H5.33333C5.15652 10.7833 4.98695 10.7122 4.86193 10.5856C4.7369 10.459 4.66667 10.2873 4.66667 10.1083C4.66667 9.92931 4.7369 9.75762 4.86193 9.63103C4.98695 9.50444 5.15652 9.43333 5.33333 9.43333H10.6667C10.8435 9.43333 11.013 9.50444 11.1381 9.63103C11.2631 9.75762 11.3333 9.92931 11.3333 10.1083C11.3333 10.2873 11.2631 10.459 11.1381 10.5856C11.013 10.7122 10.8435 10.7833 10.6667 10.7833Z"
          fill="#000"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.66667 1.33333H13.3333C13.5101 1.33333 13.6797 1.40444 13.8047 1.53103C13.9298 1.65762 14 1.82931 14 2.00833V14.1583C13.9996 14.283 13.9652 14.4051 13.9005 14.5111C13.8357 14.6172 13.7433 14.703 13.6333 14.7591C13.5226 14.8159 13.3985 14.8404 13.2748 14.8296C13.1511 14.8189 13.0329 14.7734 12.9333 14.6983L11.5533 13.6521L10.18 14.6983C10.0646 14.786 9.92425 14.8333 9.78 14.8333C9.63575 14.8333 9.4954 14.786 9.38 14.6983L8 13.6521L6.62 14.6983C6.5046 14.786 6.36425 14.8333 6.22 14.8333C6.07575 14.8333 5.9354 14.786 5.82 14.6983L4.44667 13.6521L3.06667 14.6983C2.96762 14.7735 2.84984 14.8193 2.72654 14.8306C2.60323 14.8419 2.47926 14.8181 2.36852 14.7621C2.25779 14.706 2.16466 14.6198 2.09957 14.5132C2.03448 14.4066 2 14.2837 2 14.1583V2.00833C2 1.82931 2.07024 1.65762 2.19526 1.53103C2.32029 1.40444 2.48986 1.33333 2.66667 1.33333ZM11.9533 12.2683L12.6667 12.8083V2.68333H3.33333V12.8083L4.04667 12.2683C4.16206 12.1807 4.30242 12.1333 4.44667 12.1333C4.59091 12.1333 4.73127 12.1807 4.84667 12.2683L6.22 13.3146L7.6 12.2683C7.7154 12.1807 7.85575 12.1333 8 12.1333C8.14425 12.1333 8.2846 12.1807 8.4 12.2683L9.78 13.3146L11.1533 12.2683C11.2687 12.1807 11.4091 12.1333 11.5533 12.1333C11.6976 12.1333 11.8379 12.1807 11.9533 12.2683Z"
          fill="#000"/>
  </svg>
);

export default InvoiceIcon;
