import styled from 'styled-components';
import {Space, Table} from 'antd';


const StyledSubscriptionTableActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 100%;
  
  .ant-btn {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 20px;
    width: 20px !important;
    &.cancel {
      color: ${props => props.theme.colors.danger};
    }
    &.edit {
      color: ${props => props.theme.colors.primary_6};
    }
    &.success {
      color: ${props => props.theme.colors.success};
    }
    &:active,
    &:hover,
    &:focus {
      &.cancel {
        color: ${props => props.theme.colors.danger};
      }
      &.edit {
        color: ${props => props.theme.colors.primary_6};
      }
      &.success {
        color: ${props => props.theme.colors.success};
      }
    }
    .ant-btn-icon {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
`;


const StyledSubscriptionTable = styled(Table)`
  .ant-table {
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-radius: ${props => props.theme.border_radius.standard};
    
    .ant-table-tbody {
      tr {
        &:last-of-type {
          td {
            border-bottom: unset;
          }
        }
      }
    }
  }
  
  &.table-editable {
    tbody {
      tr {
        td {
          &:last-of-type {
            
          }
        }
      }
    }
  }
  
  .ant-table-container {
    overflow-x: auto;
  }

  tbody {
    tr {
      &:not(.grouped-row) {
        &:hover {
          cursor: pointer;
        }
        
        td {
          &:last-of-type:not(:first-of-type) {
            padding: ${props => props.editable ? '16px 2px' : '16px 16px'};
          }
          &:nth-last-of-type(2) {
            padding: ${props => props.editable ? '16px 4px 16px 16px' : '16px 16px'};
          }
        }
      }
      
      &:not(:hover) {
        & ${StyledSubscriptionTableActions} {
          &:not(.editable) {
            button {
              display: none;
            }
          }
        }
      }
    }
  }

  thead {
    &.ant-table-thead tr > th {
      color: ${props => props.theme.colors.neutral_7};
      font-weight: 500;
    }
    th {
      &:last-of-type {
        &:before {
          content: none !important;
        }
      }
    }
  }
  
  .amount,
  .date {
    text-wrap: nowrap;
  }
`;

const StyledSubscriptionTableTabMenuItemSpace = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  svg {
    height: 16px;
    width: 16px;
    
    path {
      fill: ${props => props.theme.colors.neutral_10};
    }
  }
  .ant-space-item {
    display: flex;
    font-size: 16px;
  }
  
  &.delete {
    color: ${props => props.theme.colors.danger};
    svg {
      path {
        fill: ${props => props.theme.colors.danger};
      }
    } 
  }
  
  &.gray {
    color: ${props => props.theme.colors.neutral_7};
    svg {
      path {
        fill: ${props => props.theme.colors.neutral_7};
      }
    } 
  }
  
  &.primary {
    color: ${props => props.theme.colors.primary_6};
    svg {
      path {
        fill: ${props => props.theme.colors.primary_6};
      }
    } 
  }
`;


export {
  StyledSubscriptionTable,
  StyledSubscriptionTableActions,
  StyledSubscriptionTableTabMenuItemSpace
}
