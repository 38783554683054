import {useTranslation} from 'react-i18next';
import moment from 'moment/moment';
import React from 'react';
import {Avatar, Button, Dropdown, Space, Tooltip} from 'antd';
import ls from 'localstorage-slim';
import {
  cardLimitPeriodConstants,
  subscriptionCategoriesConstants as subsOptions,
  subscriptionBillTypesConstants,
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
  subscriptionDurationTypesConstants,
  subscriptionReviewTypesConstants
} from '../../../constants';
import {helpers} from '../../../helpers';
import SubscriptionStatusMark from './SubscriptionStatusMark';
import StatusLine from './StatusLine';
import TableSettingsButton from './TableSettingsButton';
import ExpandedSubscriptionRow from './tabComponents/ExpandedSubscriptionRow/ExpandedSubscriptionRow';
import {StyledSubscriptionTableTabMenuItemSpace} from './tabComponents/SubscriptionTable/StyledSubscriptionTable';
import Usage from '../SubscriptionPage/Usage';
import {ChevronDownIcon, EllipsisVerticalIcon} from '../../../icons';
import routes from '../../../routes/routes.json';

const gObjProp = helpers.getObjProp;

const {ACTIVE, AUDITED, CARD_ERROR, DELETED, INACTIVE, PENDING, RESTRICTED, TERMINATED,
  TRIAL, UNTRACKED, UNMANAGED} = subscriptionStatusesConstants;

const {CARD, FREE, NOT_APPLICABLE, WIRE} = subscriptionPaymentTypesConstants;

const storedTabColumnsPrefix = 'stored-tab-columns';

const alwaysAvailableColumnKeys = ['vendor'];

const subscriptionStatuses = {
  [PENDING]: {
    key: 'subscriptionStatuses.waiting',
    color: '#FFCC00'
  },
  [ACTIVE]: {
    key: 'subscriptionStatuses.active',
    color: '#52C41A'
  },
  [INACTIVE]: {
    key: 'subscriptionStatuses.halted',
    color: '#FFCC00'
  },
  [TERMINATED]: {
    key: 'subscriptionStatuses.deleted',
    color: '#FF7875'
  },
  [DELETED]: {
    key: 'subscriptionStatuses.deleted',
    color: '#FF7875'
  },
  [CARD_ERROR]: {
    key: 'subscriptionStatuses.cardError',
    color: '#FF7875'
  },
  [TRIAL]: {
    key: 'subscriptionStatuses.trial',
    color: '#1890FF'
  },
  [UNTRACKED]: {
    key: 'subscriptionStatuses.untracked',
    color: '#9493A6'
  },
  [AUDITED]: {
    key: 'subscriptionStatuses.audited',
    color: '#722ED1'
  },
  [RESTRICTED]: {
    key: 'subscriptionStatuses.restricted',
    color: '#FFCC00'
  },
  [UNMANAGED]: {
    key: 'subscriptionStatuses.unmanaged',
    color: '#D9D9D9'
  },
};

const billTypeKeys = {
  [subscriptionBillTypesConstants.MONTHLY]: 'billTypes.monthly',
  [subscriptionBillTypesConstants.YEARLY]: 'billTypes.yearly',
  [subscriptionBillTypesConstants.WEEKLY]: 'billTypes.weekly',
  [subscriptionBillTypesConstants.QUARTERLY]: 'billTypes.quarterly',
  [subscriptionBillTypesConstants.HALF_YEARLY]: 'billTypes.halfYearly',
  [subscriptionBillTypesConstants.FIXED]: 'billTypes.fixed'
};

const durationTypeKeys = {
  [subscriptionDurationTypesConstants.MONTHS]: 'subscriptionDurationTypes.months',
  [subscriptionDurationTypesConstants.YEARS]: 'subscriptionDurationTypes.years',
};

const cardLimitPeriodTypeKeys = {
  [cardLimitPeriodConstants.MONTH]: 'cardLimitPeriodTypes.monthly',
  [cardLimitPeriodConstants.YEAR]: 'cardLimitPeriodTypes.yearly',
  [cardLimitPeriodConstants.ALL]: 'cardLimitPeriodTypes.fixed'
}

const paymentTypeKeys = {
  [CARD]: 'subscriptionPaymentTypes.1',
  [FREE]: 'subscriptionPaymentTypes.0',
  [WIRE]: 'subscriptionPaymentTypes.3',
  [NOT_APPLICABLE]: 'subscriptionPaymentTypes.2'
};

const subscriptionReviewTypeKeys = {
  [subscriptionReviewTypesConstants.FREE]: 'subscriptionReviewTypesConstants.free',
  [subscriptionReviewTypesConstants.PAID]: 'subscriptionReviewTypesConstants.paid',
};

const subscriptionStatusKeys = {
  [ACTIVE]: 'subscriptionStatuses.active',
  [TRIAL]: 'subscriptionStatuses.trial',
  // [UNTRACKED]: 'subscriptionStatuses.untracked'
};

const categories = {
  [subsOptions.ANALYTICS_TOOLS_SOFTWARE]: {
    key: 'subscriptionCategories.analytics_tools_&_software',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.ARTIFICIAL_INTELLIGENCE_SOFTWARE]: {
    key: 'subscriptionCategories.artificial_intelligence_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.AR_VR]: {
    key: 'subscriptionCategories.ar_vr_software',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.B2B_MARKETPLACES]: {
    key: 'subscriptionCategories.b2b_marketplaces',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.CAD_PLM_SOFTWARE]: {
    key: 'subscriptionCategories.cad_plm_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.COLLABORATION_PRODUCTIVITY_SOFTWARE]: {
    key: 'subscriptionCategories.collaboration_&_productivity_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.COMMERCE_SOFTWARE]: {
    key: 'subscriptionCategories.commerce_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.CONTENT_MANAGEMENT_SYSTEMS]: {
    key: 'subscriptionCategories.content_management_systems',
    color: '#EABD3B',
    bgColor: '#FCF8EB'
  },
  [subsOptions.CUSTOMER_SERVICE_SOFTWARE]: {
    key: 'subscriptionCategories.customer_service_software',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.DATA_PRIVACY_SOFTWARE]: {
    key: 'subscriptionCategories.data_privacy_software',
    color: '#DB4CB2',
    bgColor: '#FBEDF7'
  },
  [subsOptions.DESIGN_SOFTWARE]: {
    key: 'subscriptionCategories.design_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.DEVELOPMENT_SOFTWARE]: {
    key: 'subscriptionCategories.development_software',
    color: '#176BA0',
    bgColor: '#E7F0F5'
  },
  [subsOptions.DIGITAL_ADVERTISING_PLATFORMS]: {
    key: 'subscriptionCategories.digital_advertising_platforms',
    color: '#EA7369',
    bgColor: '#FCF1F0'
  },
  [subsOptions.ERP_SOFTWARE]: {
    key: 'subscriptionCategories.erp_software',
    color: '#EA7369',
    bgColor: '#FCF1F0'
  },
  [subsOptions.GOVERNANCE_RISK]: {
    key: 'subscriptionCategories.governance_risk_&_compliance_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.HOSTING_PROVIDERS]: {
    key: 'subscriptionCategories.hosting_providers',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.HR_SOFTWARE]: {
    key: 'subscriptionCategories.hr_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.IOT_MANAGEMENT_PLATFORMS]: {
    key: 'subscriptionCategories.iot_management_platforms',
    color: '#EABD3B',
    bgColor: '#FCF8EB'
  },
  [subsOptions.IT_INFRASTRUCTURE_SOFTWARE]: {
    key: 'subscriptionCategories.it_infrastructure_software',
    color: '#DB4CB2',
    bgColor: '#FBEDF7'
  },
  [subsOptions.IT_MANAGEMENT_SOFTWARE]: {
    key: 'subscriptionCategories.it_management_software',
    color: '#176BA0',
    bgColor: '#E7F0F5'
  },
  [subsOptions.MARKETING_SOFTWARE]: {
    key: 'subscriptionCategories.marketing_software',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.OFFICE_SOFTWARE]: {
    key: 'subscriptionCategories.office_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.SALES_TOOLS]: {
    key: 'subscriptionCategories.sales_tools',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.SECURITY_SOFTWARE]: {
    key: 'subscriptionCategories.security_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.SUPPY_CHAIN_SOFTWARE]: {
    key: 'subscriptionCategories.supply_chain_&_logistics_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.VERTICAL_INDUSTRY_SOFTWARE]: {
    key: 'subscriptionCategories.vertical_industry_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
};

export const OptionsList = (optionsArray, activeValue, emptyLabel) => {
  const [t] = useTranslation('main');
  let options = Object.entries(optionsArray);
  if (emptyLabel) {
    options = [
      [null, emptyLabel],
      ...options
    ];
  }
  return options.map(option => {
    let value = option[0];
    let intValue = parseInt(value);
    if (value === `${intValue}`) value = intValue;
    const label = option[1];
    return {
      isActive: activeValue === value,
      value,
      label: t(label)
    }
  });
}

export const BillOptions = (activeValue) => OptionsList(billTypeKeys, activeValue);

export const SubscriptionReviewTypeOptions = (activeValue) => OptionsList(subscriptionReviewTypeKeys, activeValue);

export const PaymentOptions = (activeValue) => {
  const list = OptionsList(paymentTypeKeys, activeValue);
  return list.filter(i => i.value !== NOT_APPLICABLE).concat(list.filter(i => i.value === NOT_APPLICABLE));
};

export const CardLimitPeriodTypeOptions = (activeValue) => OptionsList(cardLimitPeriodTypeKeys, activeValue);

export const SubscriptionsCategoryOptions = () => {
  const [t] = useTranslation('main');
  let categoriesList = Object.entries(categories);
  categoriesList = categoriesList.map(category => {
    const value = category[0];
    const props = category[1];
    const {bgColor, color} = props;
    return {
      bgColor,
      color,
      value,
      label: t(props.key)
    }
  });
  categoriesList.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
  return categoriesList;
}

export const StatusOptions = (activeValue) => {
  const options = OptionsList(subscriptionStatusKeys, activeValue);
  return options.map(option => ({
    ...option,
    image: <StatusLine color={subscriptionsHelpers.getSubscriptionStatusColor(option.value)} />
  }));
};

export const subscriptionsHelpers = {
  getCardLimitType: (key) => gObjProp(cardLimitPeriodTypeKeys, key),
  getBillType: (key) => gObjProp(billTypeKeys, key),
  getDurationType: (key) => gObjProp(durationTypeKeys, key),
  getPaymentType: (key) => gObjProp(paymentTypeKeys, key),
  getStatusType: (key) => {
    const status = gObjProp(subscriptionStatuses, key);
    return status ? status.key : undefined;
  },
  getNextPaymentDate: (subscription, outputFormat = 'DD MMM YYYY', emptyText = 'N.A.') => {
    const {expected_first_payment: exceptedFirstPaymentDate, next_payment: nextPaymentDate} = subscription;
    let date = helpers.isNaV(nextPaymentDate) && exceptedFirstPaymentDate ? exceptedFirstPaymentDate : nextPaymentDate;
    if (date) {
      let momentDateObj = moment(helpers.getTimestampDateObject(date));
      let today = moment().endOf('day');
      if (!momentDateObj.isAfter(today)) {
        momentDateObj
          .set({date: momentDateObj.date(), month: today.month(), year: today.year()})
          .add(1, 'M');
        return momentDateObj.format(outputFormat);
      }
      return helpers.getTimestampDate(date, outputFormat);
    } else {
      return emptyText;
    }
  },
  getSubscriptionDate: (subs, key, placeholder = '') => {
    let date = gObjProp(subs, key);
    return date ? helpers.getTimestampDate(date, 'DD MMM YYYY') : placeholder;
  },
  getSubscriptionStatus: ({statusCode}) => helpers.getStatus(statusCode, subscriptionStatuses),
  getSubscriptionStatusColor: (status) => {
    const colors = {
      [ACTIVE]: '#03AB93',
      [DELETED]: '#EA7369',
      [INACTIVE]: '#FFCC00',
      [TRIAL]: '#515FFF',
      [UNTRACKED]: '#9493A6'
    }
    return colors[status] || '#9493A6'
  },
  getFieldsValidationErrors: (formState, fields, t) => {
    let errors = {};
    const checkPropError = (key) => {
      let isValid = true;
      if (formState.hasOwnProperty(key)) {
        isValid = formState[key];
      }
      return isValid ? null : t('main:validation.fieldIsRequired');
    };

    const fieldsList = fields.map(field => {
      const {name} = field;
      return {key: field.name, message: field.isRequired ? checkPropError(name) : ''}
    });
    fieldsList.forEach(field => {
      const {message, key} = field;
      if (message) errors[key] = message;
    });
    return errors;
  },
  getFieldErrors: (errors, fields) => {
    const fieldErrors = {};
    const getErrorMessage = (propName) => errors && errors.hasOwnProperty(propName) ? errors[propName] : '';
    const hasError = (propName) => errors && errors.hasOwnProperty(propName);

    fields.forEach(key => {
      const error = hasError(key);
      fieldErrors[key] = {
        className: error ? 'error' : '',
        message: getErrorMessage(key),
        error: hasError(key)
      }
    });
    return fieldErrors;
  },
  getFormItemProps: (fieldErrors, propName, className, additionalProps = {}) => {
    const {className: cName, error, message} = fieldErrors[propName];
    return {
      className: `${className} ${cName}`,
      isRequired: gObjProp(additionalProps, 'isRequired', true),
      error,
      message
    }
  },
  getSubscriptionCategory: (category) => {
    return categories.hasOwnProperty(category) ? categories[category] : {
      bgColor: '#E5F6F4',
      color: '#03AB93',
      key: 'undefined'
    };
  },
  checkIsEnabledLoadMore: (availableDate, months) => {
    let isEnabled = false;
    if (availableDate) {
      const loadedMonthDate = moment().set({date: 1}).subtract(months, 'months');
      availableDate = moment(availableDate);
      availableDate.set({date: 1});
      loadedMonthDate.isBefore(availableDate);
      isEnabled = availableDate.isSameOrBefore(loadedMonthDate);
    }
    return isEnabled;
  },
  getTransactionRequestQuery: (query, subtractMonthsCount) => {
    const currentDate = moment();
    currentDate.subtract(subtractMonthsCount, 'months');
    return {
      month: currentDate.month() + 1,
      year: currentDate.year(),
      sort_order: 'desc',
      ...query
    }
  },
  getSubscriptionStatusMark: (data) => (
    <SubscriptionStatusMark
      statusCode={data.status}
    />
  ),
  getSubscriptionOwner: (data) => {
    const getOwnerProp = (key) => gObjProp(data.owner, key);
    const fullName = getOwnerProp('full_name');
    const logo = getOwnerProp('logo');
    return (
      <Tooltip title={fullName}>
        <Avatar src={logo}>
          {helpers.getInitials(fullName)}
        </Avatar>
      </Tooltip>
    )
  },
  getUsageVariant: (value) => {
    let variant = 'empty';
    if (value > 1) {
      if (value <= 25) {
        variant = 'low';
      } else if (value <= 50 && value > 25) {
        variant = 'medium';
      } else {
        variant = 'high';
      }
    }
    return variant;
  },
  getLoginsCount: (value) => {
    let variant = subscriptionsHelpers.getUsageVariant(value);
    return (
      <div className='flex-center-center'>
        <Tooltip placement='bottom' title={value}>
          <Usage variant={variant} />
        </Tooltip>
      </div>
    );
  },
  getServicesOptionsList: (services, searchedText = '') => {
    let options = [...services];
    searchedText = subscriptionsHelpers.getSearchedDomainName(searchedText);
    if (
      helpers.isValidUrl(searchedText) &&
      (
        !Boolean(services.length) ||
        services.find(s => s.domain === searchedText || s.name === searchedText) === undefined
      )
    ) {
      options = [
        {domain: searchedText, name: searchedText},
        ...options
      ];
    }
    return options;
  },
  getAvailableColumns: (columns, selectedKeys = []) => {
    const columnKeys = [...selectedKeys, ...alwaysAvailableColumnKeys];
    return columns.filter(column => columnKeys.includes(column.dataIndex));
  },
  getSettingsRightSideContent: ({columns = [], filterProps, selectedKeys, onChange}) => {
    columns = columns.filter(column => !alwaysAvailableColumnKeys.includes(column.dataIndex));
    return (
      <TableSettingsButton
        defaultSelectedKeys={selectedKeys}
        options={columns.map(c => ({value: c.dataIndex, label: c.title}))}
        onChange={onChange}
      >
        {filterProps.rightSideContent}
      </TableSettingsButton>
    )
  },
  getStoredTabColumns: (name) => ls.get(`${storedTabColumnsPrefix}-${name}`),
  storeTabColumns: (name, value) => ls.set(`${storedTabColumnsPrefix}-${name}`, value),
  getDefaultTableColumns: (tableKey, defaultKeys) => subscriptionsHelpers.getStoredTabColumns(tableKey) || defaultKeys,
  getServicesAvatarGroup: (services) => {
    const maxCount = 6;
    if (maxCount < 1) return ;

    return (
      <Avatar.Group maxCount={maxCount} size={40}>
        {services.map((s, key) => {
          const logo = s.logo_url;
          return (
            <Avatar
              key={key}
              src={logo || undefined}
              style={{backgroundColor: logo ? 'unset' : '#BFBFBF'}}
            >
              {helpers.getInitials(s.name || s.url)}
            </Avatar>
          )
        })}
      </Avatar.Group>
    )
  },
  getTooltipServices: (services) => {
    const maxCount = 6;
    const avatarStyle = {backgroundColor: '#BFBFBF'};
    if (maxCount < 1) return ;

    return (
      <>
        {services.map((s, key) => {
          const serviceName = s.name || s.url;
          return (
            <Space size={4} align='center' key={key}>
              <Avatar
                key={key}
                src={s.logo_url || undefined}
                size={16}
                style={avatarStyle}
              >
                {helpers.getInitials(serviceName)}
              </Avatar>
              <span>{serviceName}</span>
            </Space>
          )
        })}
      </>
    )
  },
  getDetailsFormExtra: ({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}) => {
    return isEnableEdit ?
      isEditMode ? (
        <Space size='middle'>
          <Button
            onClick={() => setIsEditMode(false)}
            size='large'
          >
            {t('cancel')}
          </Button>
          <Button
            className='green-btn'
            onClick={handleOnSave}
            size='large'
          >
            {t('save')}
          </Button>
        </Space>
      ) : (
        <Button
          className='edit-btn'
          onClick={() => isEnableEdit && setIsEditMode(true)}
          type='text'
        >
          {t('edit')}
        </Button>
      ) : false;
  },
  getExpandedRowRender: (record) => (
    <ExpandedSubscriptionRow
      subscription={record}
    />
  ),
  isDeletedSubscription: (subscription) => [DELETED, TERMINATED].includes(subscription.status),
  isAuthorizedSubscription: (subscription) => ![AUDITED, DELETED, RESTRICTED, TERMINATED, UNTRACKED, UNMANAGED].includes(subscription.status),
  isAuditedStatus: (status) => [AUDITED, RESTRICTED, UNMANAGED].includes(status),
  getExpandableProps: () => ({
    expandIcon: ({ expandable, expanded, onExpand, record }) => expandable && (
      <ChevronDownIcon
        className={`expanded-table-icon ${expanded ? 'expanded' : ''}`}
        onClick={e => onExpand(record, e)}
      />
    ),
    expandedRowRender: subscriptionsHelpers.getExpandedRowRender,
    expandRowByClick: true,
    rowExpandable: (record) => !subscriptionsHelpers.isAuthorizedSubscription(record)
  }),
  handleLoadSubscriptionEmails: ({e, subscription, setSubscriptionEmails, subscriptionEmails, getSubscriptionEmails}) => {
    helpers.stopPropagation(e);
    const subscriptionId = subscription.id;
    if (!subscriptionEmails.hasOwnProperty(subscriptionId)) {
      setSubscriptionEmails({...subscriptionEmails, loading: true});
      getSubscriptionEmails(
        subscriptionId,
        (data) => {
          setSubscriptionEmails({
            ...subscriptionEmails,
            loading: false, data: data.emails || []
          });
        },
        () => {
          setSubscriptionEmails({
            ...subscriptionEmails,
            loading: false, data: []
          });
        }
      );
    }
  },
  getDropdownMenuItem: ({key, icon, t, ...rest}) => {
    return ({
      key,
      label: (
        <StyledSubscriptionTableTabMenuItemSpace
          aling='center'
          size='small'
          {...rest}
        >
          {icon}{t(`subscriptions:actions.${key.toLowerCase()}`)}
        </StyledSubscriptionTableTabMenuItemSpace>
      )
    })
  },
  getDropdownMenu: (menuProps) => (
    <Dropdown
      menu={menuProps}
      placement='bottomRight'
    >
      <EllipsisVerticalIcon
        className='ellipsis-icon'
        onClick={helpers.stopPropagation}
      />
    </Dropdown>
  ),
  isEnablePanelEdit: (statusCode) => ![CARD_ERROR, DELETED, PENDING, TERMINATED].includes(statusCode),
  getSubscriptionDetailsPageUrl: (subscription) => `${routes.subscriptionsList}/${subscription.id}`,
  isViewSubscription: ({viewsStatuses, view, subscription} = {}) => {
    const viewDetails = viewsStatuses.find(v => v.view === view);
    const statuses = viewDetails.statuses || [];
    return statuses.includes(subscription.status);
  },
  getServiceName: (subscription) => {
    const service = gObjProp(subscription, 'service');
    return gObjProp(service, 'name') || gObjProp(service, 'url');
  },
  getSearchedDomainName: (value) => value ? value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : '',
}
