import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Notifications from '../../components/pages/ProfileSettingsPage/tabs/Notifications';
import PersonalInfo from '../../components/pages/ProfileSettingsPage/tabs/PersonalInfo';
import Companies from '../../components/pages/ProfileSettingsPage/tabs/Companies';
import BankingInfo from '../../components/pages/ProfileSettingsPage/tabs/BankingInfo';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import {StyledSettingPageContainer, StyledTabComponentContainer, StyledTabs} from '../SettingsPage/StyledSettingsPage';
import {helpers} from '../../helpers';

const ProfileSettingsPage = ({
  isEnabledBanking
}) => {
  const location = useLocation();
  const [t] = useTranslation(['profile', 'main']);
  const [tabs, setTabs] = useState([]);
  const [defaultActiveTab, setDefaultActiveTab] = useState(null);

  const tabTrans = (key) => t(`tabs.${key}.title`);

  useEffect(() => {
    const hash = helpers.getLocationHash(location);
    if (hash && tabs.length > 0) {
      let searchedIndex = tabs.findIndex(t => t.key === hash && t.disabled !== true);
      if (searchedIndex >= 0) setDefaultActiveTab(searchedIndex);
    }
  }, [location.hash, tabs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTabs([
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <Space
              direction='vertical'
              size='large'
            >
              <PersonalInfo />
              <Companies />
            </Space>
          </StyledTabComponentContainer>
        ),
        key: 'personal-info',
        label: tabTrans('personalInfo')
      },
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <Notifications />
          </StyledTabComponentContainer>
        ),
        key: 'notifications',
        label: tabTrans('notifications')
      },
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <BankingInfo />
          </StyledTabComponentContainer>
        ),
        disabled: !isEnabledBanking,
        key: 'banking-info',
        label: tabTrans('bankingInfo')
      }
    ]);
  }, [isEnabledBanking]); // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumbs = [
    {title: t('title')}
  ];

  return (
    <>
      <PageDocumentDetails title={t('main:pageTitles.profile')} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <StyledSettingPageContainer>
        <StyledTabs
          defaultActiveKey={defaultActiveTab}
          type='card'
          items={tabs}
        />
      </StyledSettingPageContainer>
    </>
  );
}

const mapStateToProps = state => {
  const {isEnabledBanking} = state.card;

  return {
    isEnabledBanking
  }
}

export default connect(mapStateToProps, null)(ProfileSettingsPage);
