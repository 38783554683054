import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Space, Spin, Tooltip} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  StyledBillingInformation,
  StyledBillingInformationTitle,
  StyledBillingInformationTitleSpace,
  StyledBillingInformationTitlePlanValue,
  StyledBillingInformationTable,
  StyledBillingInformationLoadMoreContainer,
  StyledBillingInformationTableDownloadContainer
} from './StyledBillingInformation';
import {helpers} from '../../../../../helpers';
import {Invoice2Icon} from '../../../../../icons';
import {billingActions} from '../../../../../state/actions';

const dateFormat = 'MMM YYYY';
const tableRowKey = 'transfer_id';

const BillingInformation = ({
  dispatch,
  getTransferAttachmentFile,

  data,
  handleLoadMore,
  loading,
  pagination,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'settings']);
  const t = (key) => trans(`settings:tabs.billing.${key}`);
  const [selectedTransferId, setSelectedTransferId] = useState(undefined);

  const renewsData = useMemo(() => moment().add(1, 'M').format(dateFormat), []);

  const columns = [
    {
      key: 'created_date',
      dataIndex: 'created_date',
      title: trans('date'),
      render: (value) => (
        <span className='text-uppercase'>
          {helpers.getTimestampDate(value, dateFormat)}
        </span>
      )
    },
    {
      align: 'right',
      key: 'cashback',
      dataIndex: 'cashback',
      title: t('cashback'),
      render: (value) => value ? helpers.getMoneyView(value) : '-',
    },
    {
      align: 'right',
      key: 'amount',
      dataIndex: 'amount',
      title: t('cashOut'),
      render: (value) => (
        <span className='cashout'>
          {helpers.getMoneyView(-value)}
        </span>
      )
    },
    {
      dataIndex: 'match',
      title: '',
      render: (_, record) => {
        const id = record[tableRowKey];
        const fileUrl = record.file_url;
        return (
          <StyledBillingInformationTableDownloadContainer>
            {fileUrl && (
              <Space size={2}>
                <Spin spinning={selectedTransferId === id} />
                <Tooltip title={trans('clickHereToDownloadFile')}>
                  <Invoice2Icon onClick={(e) => onDownload(e, id)} />
                </Tooltip>
              </Space>
            )}
          </StyledBillingInformationTableDownloadContainer>
        )
      },
      width: 32
    },
  ];

  const title = (
    <StyledBillingInformationTitle>
      <StyledBillingInformationTitleSpace direction='vertical' size={4}>
        <span className='billing-title'>{t('yourPlan')}</span>
        <span className='billing-plan'>{t('proMonthly')}</span>
        <span className='billing-renews-date'>{t('renewsOn')} {renewsData}</span>
      </StyledBillingInformationTitleSpace>
      <StyledBillingInformationTitlePlanValue>
        2%
      </StyledBillingInformationTitlePlanValue>
    </StyledBillingInformationTitle>
  );

  const onDownload = (e, id) => {
    e.stopPropagation();
    if (id === undefined) return;

    setSelectedTransferId(id);
    getTransferAttachmentFile({
      id,
      successCallback: (data) => {
        helpers.saveFile(data);
        setSelectedTransferId(undefined);
      },
      errorCallback: () => setSelectedTransferId(undefined)
    })
  }

  return (
    <StyledBillingInformation
      title={title}
      {...rest}
    >
      <StyledBillingInformationTable
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        rowKey='created_date'
      />
      {pagination?.cursor && (
        <StyledBillingInformationLoadMoreContainer>
          <Button
            size='large'
            loading={loading}
            onClick={handleLoadMore}
          >
            {trans('loadMore')}
          </Button>
        </StyledBillingInformationLoadMoreContainer>
      )}
    </StyledBillingInformation>
  );
}

BillingInformation.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      created_date: PropTypes.number
    })
  ),
  handleLoadMore: PropTypes.func,
  loading: PropTypes.bool,
  pagination: PropTypes.objectOf(
    PropTypes.shape({
      cursor: PropTypes.string,
      next_page: PropTypes.string
    })
  )
}

BillingInformation.defaultProps = {
  loading: false
}

const mapDispatchToProps = {
  getTransferAttachmentFile: billingActions.getTransferAttachmentFile
}

export default connect(null, mapDispatchToProps)(BillingInformation);
