import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionPaymentType = styled(Space)`
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  display: flex;
  svg {
    path {
      &:last-child {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
  
  .ant-space-item {
    align-items: center;
    display: flex;
    height: 32px;
  }
`;

export default StyledSubscriptionPaymentType;
