import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledOverviewCounters, StyledTotalCounter} from './StyledOverviewCounters';
import TextLoader from '../../../ContentLoaders/TextLoader';
import {helpers} from '../../../../helpers';

const OverviewCounters = ({
  data,
  days,
  month,
  ...rest
}) => {
  const [t] = useTranslation(['subscriptions', 'main']);
  const trans = (key) => t(`overview.counters.${key}`);

  const getPrice = (value) => helpers.getMoneyView(value);

  const checkIsLoaded = (key) => helpers.getObjProp(data[key], 'isLoaded', true);

  const getValue = (key) => {
    const isLoaded = checkIsLoaded(key);
    return isLoaded
      ? helpers.getObjProp(data[key], 'value', 0)
      : <TextLoader width={80} y={0} height={32} />;
  }

  const getPriceValue = (key) => {
    const isLoaded = checkIsLoaded(key);
    const value = getValue(key)
    return isLoaded ? getPrice(value) : value;
  }

  const getPercentValue = (key) => {
    const isLoaded = checkIsLoaded(key);
    const value = getValue(key)
    return isLoaded ? `${value}%` : value;
  }

  return (
    <StyledOverviewCounters
      {...rest}
      size='large'
    >
      <StyledTotalCounter
        title={t('main:subscriptions')}
        value={getValue('total')}
      />
      <StyledTotalCounter
        title={<>{trans('spending')}<br/>{month} {days}</>}
        value={getPriceValue('spend')}
      />
      <StyledTotalCounter
        title={<>{trans('totalForecast')}<br/>{month}</>}
        value={getPriceValue('expected')}
      />
      <StyledTotalCounter
        title={<>{trans('expectedVsForecast')}<br/>{trans('monthToDate')}</>}
        value={getPercentValue('var')}
      />
    </StyledOverviewCounters>
  );
}

const CounterPropsType = PropTypes.shape({
  isLoaded: PropTypes.bool.isRequired,
  value: PropTypes.any
}).isRequired;

OverviewCounters.propTypes = {
  data: PropTypes.shape({
    total: CounterPropsType,
    expected: CounterPropsType,
    spend: CounterPropsType,
    var: CounterPropsType
  }).isRequired,
  days: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired
};

export default OverviewCounters;
