import styled from 'styled-components';
import {Button, Divider, Space} from 'antd';

const StyledTableSettingsButtonSpace = styled(Space)`
  .ant-space-item-split {
    font-size: 26px;
  }
`;

const StyledTableSettingsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    & path {
      transition: .3s ease;
      fill: ${props => props.theme.colors.neutral_6};
    }
  }
  
  &:hover,&:active {
    svg {
      width: 16px;
      & path {
        transition: .3s ease;
        fill: ${props => props.theme.colors.primary_5};
      }
    }
  }
`;

const StyledTableSettingsButtonDivider = styled(Divider)`
  font-size: 26px;
`;

const StyledTableSettingsButtonDropdownSpace = styled(Space)`
  background-color: #fff;
  border-radius: ${props => props.theme.border_radius.standard};
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  min-width: 170px;
  padding: 4px;
  
  .ant-space, .ant-space-item, label {
    width: 100%;
  }
  
  label {
    border-radius: ${props => props.theme.border_radius.standard};
    padding: 5px 12px;
    transition: .3s ease;
    
    &:hover {
      background-color: ${props => props.theme.colors.primary_2};
    }
  }
`;

export {
  StyledTableSettingsButton,
  StyledTableSettingsButtonDivider,
  StyledTableSettingsButtonSpace,
  StyledTableSettingsButtonDropdownSpace
}
