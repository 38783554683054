import React from 'react';

const H1LogoIcon = () => (
  <svg width="54" height="24" viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_961_65771)">
      <mask id="mask0_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="11" y="11" width="13"
            height="13">
        <path
          d="M17.3435 11.8564C14.1829 11.8564 11.6259 14.4324 11.6259 17.6164C11.6259 20.8004 14.1829 23.3764 17.3435 23.3764C20.5041 23.3764 23.0611 20.8004 23.0611 17.6164V11.8564H17.3435Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask0_961_65771)">
        <mask id="mask1_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_961_65771)">
          <path d="M23.5059 11.4082H11.1732V23.8322H23.5059V11.4082Z" fill="#0A145A"/>
        </g>
      </g>
      <mask id="mask2_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="12"
            height="12">
        <path
          d="M5.90027 0.335938C2.73968 0.335938 0.182617 2.91194 0.182617 6.09594C0.182617 9.27994 2.73968 11.8559 5.90027 11.8559H11.6179V6.09594C11.6179 2.91194 9.06085 0.335938 5.90027 0.335938Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask2_961_65771)">
        <mask id="mask3_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask3_961_65771)">
          <path d="M12.0626 -0.112305H-0.27002V12.3117H12.0626V-0.112305Z" fill="#0A145A"/>
        </g>
      </g>
      <mask id="mask4_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="11" y="0" width="13"
            height="12">
        <path
          d="M17.3435 0.335938H11.6259V6.09594C11.6259 9.27994 14.1829 11.8559 17.3435 11.8559C20.5041 11.8559 23.0611 9.27994 23.0611 6.09594C23.0611 2.91194 20.5041 0.335938 17.3435 0.335938Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask4_961_65771)">
        <mask id="mask5_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask5_961_65771)">
          <path d="M23.5059 -0.112305H11.1732V12.3117H23.5059V-0.112305Z" fill="#0A145A"/>
        </g>
      </g>
      <mask id="mask6_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="11" width="12"
            height="13">
        <path
          d="M5.90027 11.8564C2.73968 11.8564 0.182617 14.4324 0.182617 17.6164C0.182617 20.8004 2.73968 23.3764 5.90027 23.3764H11.6179V17.6164C11.6179 14.4324 9.06085 11.8564 5.90027 11.8564Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask6_961_65771)">
        <mask id="mask7_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask7_961_65771)">
          <path d="M12.0626 11.4082H-0.27002V23.8322H12.0626V11.4082Z" fill="#0A145A"/>
        </g>
      </g>
      <mask id="mask8_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="45" y="0" width="9"
            height="23">
        <path
          d="M53.5632 22.9281H48.9891V6.59213H45.0424V3.26413C47.6074 3.13613 49.2909 2.20012 49.7515 0.328125H53.5712V22.9201L53.5632 22.9281Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask8_961_65771)">
        <mask id="mask9_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8823V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask9_961_65771)">
          <path d="M54.008 -0.112305H44.5897V23.3837H54.008V-0.112305Z" fill="#0A145A"/>
        </g>
      </g>
      <mask id="mask10_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="24" y="0" width="20"
            height="23">
        <path
          d="M43.2477 22.9279H38.5227V13.4399H29.5809V22.9279H24.8559V0.335938H29.5809V8.98394H38.5227V0.335938H43.2477V22.9279Z"
          fill="white"/>
      </mask>
      <g mask="url(#mask10_961_65771)">
        <mask id="mask11_961_65771" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
              height="4040">
          <path d="M603.085 -20H-42.8823V4019.64H603.085V-20Z" fill="white"/>
        </mask>
        <g mask="url(#mask11_961_65771)">
          <path d="M43.6924 -0.112305H24.4033V23.3837H43.6924V-0.112305Z" fill="#0A145A"/>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_961_65771">
        <rect width="54" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default H1LogoIcon;
