import React from 'react';
import {useTranslation} from 'react-i18next';
import {Spin} from 'antd';
import PropTypes from 'prop-types';
import {StyledInvoiceForward, StyledCopyField} from './StyledInvoiceForward';
import {alertActions} from '../../../../state/actions';

const InvoiceForward = ({
  email,
  loading,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);

  const onCopy = () => alertActions.success(`${t('copied')}!`);

  return (
    <Spin spinning={loading}>
      <StyledInvoiceForward
        {...rest}
      >
        {!loading && (
          <>
            {email ? (
              <>
                <p>{t('invoices:invoiceForwardingDescription')}:</p>
                <StyledCopyField
                  onCopy={onCopy}
                  value={email}
                />
              </>
            ) : (
              <p>{t('invoiceEmailEmptyDescription')}</p>
            )}
          </>
        )}
      </StyledInvoiceForward>
    </Spin>
  );
}

InvoiceForward.propTypes = {
  email: PropTypes.string,
  loading: PropTypes.bool
}

InvoiceForward.defaultProps = {
  loading: false
}

export default InvoiceForward;
