import styled from 'styled-components';
import {Space, Table} from 'antd';
import Card from '../../../Card';

const StyledSubscriptionTables = styled(Space)`
  width: 100%;
  
  & > .ant-space-item {
    width: 50%;
  }
`;

const StyledSubscriptionTablesCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  
  .ant-card-extra {
    svg {
      height: 24px;
      width: 24px;
      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

const StyledSubscriptionTablesTable = styled(Table)`
  overflow: auto;
  .ant-table-thead > tr > th {
    background: ${props => props.theme.colors.neutral_2};
    border-bottom: 1px solid ${props => props.theme.colors.neutral_3};
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 500;
    font-size: 14px;
    padding: 12px;
  }
  
  .ant-table-tbody > tr > td {
    padding: 8px 12px;
  }
  
  @media screen and (min-width: 1440px) {
    .date {
      min-width: 130px;
      text-wrap: nowrap;
    }

    .expected-payment {
      min-width: 150px;
    }
  }
  
  .amount,
  .date {
    text-wrap: nowrap;
  }
`;


export {
  StyledSubscriptionTables,
  StyledSubscriptionTablesCard,
  StyledSubscriptionTablesTable
}