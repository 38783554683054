import styled from 'styled-components';
import {Avatar, Space} from 'antd';

const StyledVendorDetails = styled(Space)`
  .vendor-name {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 14px;
    font-weight: 400;
  }
  .vendor-category {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 12px;
    line-height: 12px;
  }
`;

const StyledVendorDetailsAvatar = styled(Avatar)`
  &.ant-avatar {
    border: 1px solid ${props => props.theme.colors.neutral_3};
  }
`;

const StyledVendorDetailsLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: ${props => props.theme.colors.neutral_6};
      transition: .2s ease;
    }
  }
  
  &:hover,&:active,&:focus {
    svg {
      path {
        fill: ${props => props.theme.colors.primary_5};
      }
    }
  }
`;

export {
  StyledVendorDetails,
  StyledVendorDetailsAvatar,
  StyledVendorDetailsLink
}
