import styled from 'styled-components';

const StyledTransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  StyledTransactionDetails
};
