import styled from 'styled-components';
import {Select} from 'antd';
import Search from '../../../Search';

const StyledTableFilters = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledTableFiltersSearch = styled(Search)`
  max-width: 264px;
  width: 100%;
`;

const StyledTableFiltersSelect = styled(Select)`
  width: 180px;
`;

export {
  StyledTableFilters,
  StyledTableFiltersSearch,
  StyledTableFiltersSelect
};
