import styled from 'styled-components';
import {Radio, Space} from 'antd';
import AntdModal from '../../../AntdModal';

const StyledAddInvoiceModal = styled(AntdModal)`
  .attachment-preview {
    margin-top: 16px;
    text-align: center;
  }
  
  .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
    padding: 0 24px 20px 24px !important;
  }
`;

const StyledAddInvoiceModalSpace = styled.div`
  width: 100%;
  
  & > div {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    &:first-of-type {
      margin-bottom: 0;
    }
  }
  
  .ant-radio-group {
    width: 100%;
  }
`;

const StyledAddInvoiceModalLineDescription = styled.div`
  color: ${props => props.theme.colors.neutral_6};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  &:before,&:after {
    background-color: ${props => props.theme.colors.neutral_4};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 25%;
  }
  &:before {
    right: 0.5em;
  }
  &:after {
    left: 0.5em;
  }
`;

const StyledAddInvoiceModalRadio = styled(Radio)`
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  color: ${props => props.theme.colors.neutral_10};
  transition: .2s ease-in;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-inline-end: 0;
  padding: 16px;
  width: 100%;
  
  &.ant-radio-wrapper-checked {
    border: 1px solid ${props => props.theme.colors.primary_4};
  }
  
  & > .ant-space {
    width: 100%;
  }
  
  & > span {
    &:not(.ant-radio) {
      padding-inline-start: 0;
    }
  }
  
  &:after {
    content: none;
  }
  
  &:hover {
    background: ${(props) => props.theme.colors.primary_5}19;
  }
  
  .placeholder {
    height: 70px;
    width: 70px;
  }
  
  .price {
    font-weight: 600;
  }
`;

const StyledAddInvoiceModalRadioGroupSpace = styled(Space)`
  width: 100%;
`;

const StyledAddInvoiceModalStickyContainer = styled.div`
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 2;
`;


export {
  StyledAddInvoiceModal,
  StyledAddInvoiceModalSpace,
  StyledAddInvoiceModalLineDescription,
  StyledAddInvoiceModalRadio,
  StyledAddInvoiceModalRadioGroupSpace,
  StyledAddInvoiceModalStickyContainer
};
