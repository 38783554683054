import styled from 'styled-components';
import {Menu} from 'antd';

const StyledSelectDropdownMenu = styled(Menu)`
  &.ant-dropdown-menu {
    border-inline-end: none;
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledSelectDropdownMenuItem = styled(Menu.Item)`
  label {
    font-size: 16px;
  }
  &.ant-dropdown-menu-item-selected {
    background-color: unset !important;
  }
`;

const StyledSelectDropdownContent = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 4px;
  position: relative;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 3px 6px -4px rgba(0, 0, 0, 0.12),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-dropdown-menu {
    box-shadow: unset;
    max-height: 250px;
    overflow: auto;
  }
  
  .dropdown-searched-content {
    margin-top: 48px;
  }

  .dropdown-searched-content .ant-typography {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    margin-bottom: 0;
    padding: 8px 12px;
  }
`;

const StyledSelectDropdownSearchContainer = styled.div`
  position: absolute;
  left: 4px;
  width: calc(100% - (4px * 2));
  & > div {
    width: 100%;
    button.ant-btn {
      background-color: ${props => props.theme.colors.secondary_6};
      color: ${props => props.theme.colors.neutral_1};
      font-size: 14px;

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: ${props => props.theme.colors.secondary_5};
        border-color: ${props => props.theme.colors.secondary_5};
        color: ${props => props.theme.colors.neutral_1};
      }

      &:not(:disabled):not(.ant-btn-disabled):active {
        background-color: ${props => props.theme.colors.secondary_7};
        border-color: ${props => props.theme.colors.secondary_7};
        color: ${props => props.theme.colors.neutral_1};
      }
      &:disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export {
  StyledSelectDropdownMenu,
  StyledSelectDropdownMenuItem,
  StyledSelectDropdownContent,
  StyledSelectDropdownSearchContainer
};
