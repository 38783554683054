import styled from 'styled-components';

const StyledSubscriptionTooltip = styled.div`
  background-color: rgba(58, 57, 73, 0.96);
  border-radius: ${props => props.theme.border_radius.standard};
  color: #fff;
  font-size: 10px;
  min-height: 20px;
  min-width: 160px;
  line-height: 0.875rem;
  padding: 8px;
  position: relative;
  transform: translate(${props => props.isPositiveTransform ? 25 : -35}px, 0%);
`;

export {
  StyledSubscriptionTooltip
}