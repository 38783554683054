import {request} from '../services/request';
import {backendEndpoints} from '../../api';
import {apiHelpers} from '../../api/apiHelpers';

const subscriptionRequestTemplate = (url, success, error, query) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const getTotalSubscriptions = (query, success, error) => subscriptionRequestTemplate(backendEndpoints.GET_SUBSCRIPTIONS_OVERVIEW, success, error, query);

const getGroupedSubscriptions = (query, success, error) => subscriptionRequestTemplate(backendEndpoints.GET_SUBSCRIPTIONS_OVERVIEW_GROUPED, success, error, query);

const getDailySubscriptions = (date, query, success, error) => subscriptionRequestTemplate(apiHelpers.getUrl(backendEndpoints.GET_SUBSCRIPTIONS_OVERVIEW_BY_DATE, {date}), success, error, query);

export const overviewActions = {
  getTotalSubscriptions,
  getGroupedSubscriptions,
  getDailySubscriptions
};
