import {Table} from 'antd';
import styled from 'styled-components';

const StyledTableContainer = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px;
  margin-top: 24px;
`;

const StyledUsersTable = styled(Table)`
  border: 1px solid ${props => props.theme.colors.neutral_3};
  border-bottom: none;
`;

export {StyledTableContainer, StyledUsersTable};
