import styled from 'styled-components';
import AntdModal from '../../../../../AntdModal';
import Card from '../../../../../Card';

const StyledCardDetailsModal = styled(AntdModal)`
  .ant-modal-footer {
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  }

  .ant-space {
    width: 100%;
  }

  .card-image {
    width: 342px;
    height: 218px;
  }

  .card-details {
    & > div:nth-of-type(2) {
      width: 100%;

      .ant-card-body {
        & > * {
          &:not(:last-of-type) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
`;

const StyledCardDetailsTimer = styled.span`
  color: ${props => props.theme.colors.neutral_7};
  font-size: 16px;
  font-weight: 300;

  .time {
    color: ${props => props.theme.colors.primary_8};
    font-weight: 700;
  }
`;

const StyledCardDetailsCard = styled(Card)`
  .ant-card-head {
    background-color: ${props => props.theme.colors.neutral_2};
  }
  .ant-card-body {
    padding: 0;
    & > div {
      min-height: 52px;
    }
  }
  min-height: 218px;
`;

export {
  StyledCardDetailsModal,
  StyledCardDetailsCard,
  StyledCardDetailsTimer
}
