import styled from 'styled-components';

const StyledCodeInputField = styled.div`
  .link {
    text-decoration: underline;
    &.disabled {
      cursor: unset;
      opacity: 0.4;
      pointer-events: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledResendContainer = styled.div`
  display: flex;
  color: #343536;
  font-weight: 500;
  
  .link {
    margin-right: 1rem;
  }
`;

const StyledCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  
  & + ${StyledResendContainer} {
    margin-top: -16px;
  }
`;

const StyledInputsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  & > div {
    flex: unset;
    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }
  input {
    border: 1px solid ${props => props.theme.colors.neutral_5};
    border-radius: 8px;
    color: ${props => props.theme.colors.neutral_10};
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    height: 56px;
    padding: 0;
    text-align: center;
    transition: .2s ease;
    width: 56px;
    
    &:hover, &:focus {
      box-shadow: unset;
      border-color: #808080;
    }
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  
  &.not-valid {
    input {
      border: 2px solid ${props => props.theme.colors.danger};
      &:hover, &:focus {
        border: 2px solid ${props => props.theme.colors.danger};
        border-width: 2px !important;
      }
    }
  }
`;

const StyledHelpText = styled.div`
  color: ${props => props.theme.colors.danger};
  min-height: 18px;
  padding-top: 24px;
  font-size: 16px;
`;

export {
  StyledCodeInputField,
  StyledCodeContainer,
  StyledInputsList,
  StyledInputContainer,
  StyledHelpText,
  StyledResendContainer
}