import styled from 'styled-components';

const StyledSubscriptionTooltipSpendingDetails = styled.div`
  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const StyledSubscriptionTooltipSpendingDetailsTitle = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  padding-bottom: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &.dashed {
    border-bottom: 1px dashed ${props => props.theme.colors.tertiary_3};
  }
  &.solid {
    border-bottom: 1px solid ${props => props.theme.colors.primary_4};
  }
`;

const StyledSubscriptionTooltipSpendingDetailsServicesList = styled.ul`
  list-style-type: none;
  margin: 5px 0 0 0;
  padding: 0;
  li {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 12px;
    
    &:not(:last-of-type) {
      margin-bottom: 2px;
    }
    
    .price {
      margin-left: 8px;
    }
    
    .direction {
      text-align: right;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;

export {
  StyledSubscriptionTooltipSpendingDetails,
  StyledSubscriptionTooltipSpendingDetailsTitle,
  StyledSubscriptionTooltipSpendingDetailsServicesList
}