import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {UnmountClosed} from 'react-collapse';
import SpinnerImage from '../../components/SpinnerImage';
import Card from '../../components/Card';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import SubscriptionChart from '../../components/pages/OverviewPage/SubscriptionChart';
import OverviewCounters from '../../components/pages/OverviewPage/OverviewCounters/OverviewCounters';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import CompleteKycCard from '../../components/pages/OverviewPage/CompleteKycCard';
import SubscriptionTables from '../../components/pages/OverviewPage/SubscriptionTables';
import {StyledOverviewPage, StyledOverviewPageContent, StyledOverviewPageSpin} from './StyledOverviewPage';
import useIsEmployeeChanged from '../../hooks/useIsEmployeeChanged';
import {overviewActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {kycHelpers} from '../../components/quickStart/components/KYCModal/kycHelpers';

const gObjProp = helpers.getObjProp;

const counterDefaultProps = {value: null, isLoaded: false};

const defaultSubscriptions = {
  loaded: false,
  created: [],
  upcoming: []
};

const OverviewPage = ({
  getGroupedSubscriptions,
  getTotalSubscriptions,
  isKnownEmployee,
  isAdmin,
  bankingCompanyState,
  companyCountry,
  requiredCardVerification
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [showLoader, setShowLoader] = useState(true);
  const [totals, setTotals] = useState({
    counters: {
      total: counterDefaultProps,
      expected: counterDefaultProps,
      spend: counterDefaultProps,
      var: counterDefaultProps
    },
    chartData: [],
    expectedServices: {}
  });
  const [subscriptions, setSubscriptions] = useState(defaultSubscriptions);
  const [selectedTags, setSelectedTags] = useState([]);
  const [requiredCompleteKyc, setRequiredCompleteKyc] = useState(false);

  const isEmployeeChanged = useIsEmployeeChanged();

  const finishLoading = () => setShowLoader(false);

  const calculateSubscriptionsChart = (data) => {
    const {day: currentDayNum} = helpers.getCurrentDate();
    let expected = 0, current = 0;
    return data.map(i => {
      const {index: name} = i;
      const daySelected = parseInt(name);
      const date = moment().set('date', name);
      let data = {
        date: date.format('YYYY-MM-DD'),
        label: date.format('Do MMMM'),
        name
      };

      expected += i.expected;
      current += i.current;

      data = {...data, expected};
      if (daySelected <= currentDayNum) data = {...data, current}
      if (daySelected === currentDayNum) data = {...data, isActive: true}
      return data;
    });
  }

  const getExpectedServices = (data) => {
    let expectedServices = {};
    Object.keys(data).forEach(key => {
      const gOP = (name) => gObjProp(data[key], name);
      const index = gOP('index');
      expectedServices[index] = gOP('expected_tooltip') || [];
    });
    return expectedServices;
  }

  const getOverviewData = (hasAccessToBalance, tags = []) => {
    const query = {tags};
    getTotalSubscriptions(
      query,
      (data) => {
        const gOP = (name) => ({isLoaded: true, value: gObjProp(data, name, 0)});
        const counters = {
          total: gOP('active'),
          expected: gOP('expected'),
          spend: gOP('spend'),
          var: gOP('trend')
        };
        const {data: subscriptionData} = data;
        const chartData = calculateSubscriptionsChart(subscriptionData);
        const expectedServices = getExpectedServices(subscriptionData);
        setTotals({chartData, counters, expectedServices});
        finishLoading();
      },
      finishLoading
    );
    getGroupedSubscriptions(
      query,
      (data) => {
        setSubscriptions({
          ...subscriptions,
          loaded: true,
          created: data.new,
          upcoming: data.upcoming
        })
      },
      () => {
        setSubscriptions({
          ...subscriptions,
          loaded: false
        });
      }
    )
  }

  useEffect(() => {
    if (isKnownEmployee) {
      getOverviewData(isAdmin);
    }
  }, [isKnownEmployee, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmployeeChanged) {
      setShowLoader(true);
      setSubscriptions(defaultSubscriptions);
      getOverviewData(isAdmin);
      setSelectedTags([]);
    }
  }, [isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isRequired = false;
    const isSupportedKycCountry = kycHelpers.isSupportedCountry(companyCountry);
    if (bankingCompanyState && isSupportedKycCountry) {
      isRequired = bankingCompanyState.stage !== 'card_stage';
      if (isRequired !== requiredCompleteKyc) setRequiredCompleteKyc(isRequired);
    }
    if (isRequired !== requiredCompleteKyc) setRequiredCompleteKyc(isRequired);
  }, [bankingCompanyState, companyCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMonthDays = () => {
    const currentDate = moment();
    const month = currentDate.format('MMMM');
    const todayDayNum = parseInt(currentDate.format('D'));
    return {
      month,
      days: todayDayNum === 1 ? 1 : `1-${todayDayNum}`
    }
  }

  const {days, month} = getMonthDays();

  return (
    <StyledOverviewPage
      direction='vertical'
      size='middle'
    >
      <PageDocumentDetails title={t('pageTitles.overview')} />
      <BalancePageHeader
        breadcrumbs={[{title: t('overview')}]}
      />
      <StyledOverviewPageSpin
        indicator={<SpinnerImage />}
        spinning={showLoader}
      >
        <StyledOverviewPageContent
          direction='vertical'
          size='large'
        >
          {isAdmin && (
            <UnmountClosed isOpened={requiredCompleteKyc}>
              <CompleteKycCard
                className='complete-kyc-panel'
                variant='kyc'
              />
            </UnmountClosed>
          )}
          <UnmountClosed isOpened={requiredCardVerification}>
            <CompleteKycCard
              className='complete-kyc-panel'
              variant={isAdmin ? 'admin-banking' : 'banking'}
            />
          </UnmountClosed>
          <OverviewCounters
            data={totals.counters}
            days={days}
            month={month}
          />
          <Card
            title={t('main:spendings')}
          >
            <SubscriptionChart
              data={totals.chartData}
              expectedServices={totals.expectedServices}
              tags={selectedTags}
            />
          </Card>
          <SubscriptionTables
            loading={!subscriptions.loaded}
            created={subscriptions.created}
            upcoming={subscriptions.upcoming}
          />
        </StyledOverviewPageContent>
      </StyledOverviewPageSpin>
    </StyledOverviewPage>
  );
}

const mapStateToProps = state => {
  const {isKnownEmployee, isAdmin} = state.user;
  const {companyState: bankingCompanyState, verification} = state.card;
  const {country: companyCountry} = state.company;
  const requiredCardVerification = helpers.getObjProp(verification, 'isRequired') || false;

  return {
    bankingCompanyState,
    companyCountry,
    isKnownEmployee,
    isAdmin,
    requiredCardVerification
  }
}

const mapDispatchToProps = {
  getGroupedSubscriptions: overviewActions.getGroupedSubscriptions,
  getTotalSubscriptions: overviewActions.getTotalSubscriptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
