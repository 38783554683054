import styled from 'styled-components';
import {Form} from 'antd';

const StyledSelectedServiceForm = styled(Form)`
  display: flex;
  padding: 0 !important;

  & > div {
    padding: 16px;
    position: relative;

    .ant-select {
      width: 100%;
    }

    &:nth-of-type(1) {
      width: 25%;
    }

    &:nth-of-type(2) {
      width: 30%;
    }

    &:nth-of-type(3),&:nth-of-type(4) {
      width: calc((100% - 50% - 48px) / 2);
    }

    &:nth-of-type(4) {
      width: calc((100% - 50% - 48px) / 2);
    }

    &:nth-of-type(5) {
      display: flex;
      align-items: start;
      justify-content: center;
      padding: 16px 0;
      width: 48px;

      button {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 4px;
      }

      svg {
        path {
          fill: ${props => props.theme.colors.danger};
        }
      }
    }

    &:not(:last-of-type) {
      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 22px;
        width: 1px;
        // background-color: ${props => props.theme.colors.neutral_3};
        transform: translateY(-50%);
      }
    }
  }
`;

const StyledSelectedServiceFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export {
  StyledSelectedServiceForm,
  StyledSelectedServiceFormItem
}
