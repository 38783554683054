import {overviewConstants} from '../constants';

const defaultCompanyCategory = {
  category: null,
  current_month_price: null,
  previous_month_price: null
};

const defaultTotalCosts = {
  currency: null,
  paid_total_price: null,
  not_paid_price: null,
  last_month_price: null
};

const defaultCompanyEmployee = {
  employee: null,
  current_month_price: null,
  previous_month_price: null
};

const defaultCompanyTag = {
  tag: null,
  current_month_price: null,
  previous_month_price: null
};

const defaultCompanyVendor = {
  vendor: null,
  current_month_price: null,
  previous_month_price: null
};

const overviewState = {
  companyCategory: defaultCompanyCategory,
  companyEmployee: defaultCompanyEmployee,
  companyTag: defaultCompanyTag,
  companyVendor: defaultCompanyVendor,
  employeeCategories: [],
  employeeTags: [],
  employeeVendors: [],
  totalCosts: defaultTotalCosts,
  costsByDay: []
};

const overviewReducer = (state = overviewState, action) => {
  const {type, data} = action;
  switch (type) {
    case overviewConstants.SUCCESS_GET_TOP_COMPANY_CATEGORY:
      return {
        ...state,
        companyCategory: data
      }
    case overviewConstants.SUCCESS_GET_TOP_COMPANY_EMPLOYEE:
      return {
        ...state,
        companyEmployee: data
      }
    case overviewConstants.SUCCESS_GET_TOP_COMPANY_TAG:
      return {
        ...state,
        companyTag: data
      }
    case overviewConstants.SUCCESS_GET_TOP_COMPANY_VENDOR:
      return {
        ...state,
        companyVendor: data
      }
    case overviewConstants.SUCCESS_GET_TOTAL_COSTS:
      return {
        ...state,
        totalCosts: data
      }
    case overviewConstants.SUCCESS_GET_TOP_EMPLOYEE_CATEGORY:
      return {
        ...state,
        employeeCategories: data.categories
      }
    case overviewConstants.SUCCESS_GET_TOP_EMPLOYEE_TAGS:
      return {
        ...state,
        employeeTags: data.tags
      }
    case overviewConstants.SUCCESS_GET_TOP_EMPLOYEE_VENDOR:
      return {
        ...state,
        employeeVendors: data.vendors
      }
    case overviewConstants.SUCCESS_GET_COSTS_BY_DAY:
      return {
        ...state,
        costsByDay: data.records
      }
    case overviewConstants.ERROR_GET_TOP_COMPANY_CATEGORY:
      return {
        ...state,
        companyCategory: defaultCompanyCategory
      }
    case overviewConstants.ERROR_GET_TOP_COMPANY_EMPLOYEE:
      return {
        ...state,
        companyEmployee: defaultCompanyEmployee
      }
    case overviewConstants.ERROR_GET_TOP_COMPANY_TAG:
      return {
        ...state,
        companyTag: defaultCompanyTag
      }
    case overviewConstants.ERROR_GET_TOP_COMPANY_VENDOR:
      return {
        ...state,
        companyVendor: defaultCompanyVendor
      }
    case overviewConstants.ERROR_GET_TOTAL_COSTS:
      return {
        ...state,
        totalCosts: defaultTotalCosts
      }
    case overviewConstants.ERROR_GET_TOP_EMPLOYEE_CATEGORY:
      return {
        ...state,
        employeeCategories: []
      }
    case overviewConstants.ERROR_GET_TOP_EMPLOYEE_TAGS:
      return {
        ...state,
        employeeTags: []
      }
    case overviewConstants.ERROR_GET_TOP_EMPLOYEE_VENDOR:
      return {
        ...state,
        employeeVendors: []
      }
    case overviewConstants.ERROR_GET_COSTS_BY_DAY:
      return {
        ...state,
        costsByDay: []
      }
    default:
      return state
  }
};

export default overviewReducer;
