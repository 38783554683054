import styled from 'styled-components';
import {Space, Table} from 'antd';
import Card from '../../../../Card';

const StyledBillingInformation = styled(Card)`
  .ant-card-body {
    color: ${props => props.theme.colors.neutral_8};
    a {
      color: ${props => props.theme.colors.neutral_8};
      text-decoration: underline;
    }
  }
  .ant-card-head {
    padding: 24px;
    flex-direction: unset;
  }
`;

const StyledBillingInformationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBillingInformationTitleSpace = styled(Space)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  .billing-title {
    color: ${props => props.theme.colors.neutral_6};
  }
  .billing-plan {
    color: ${props => props.theme.colors.neutral_10};
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }
  .billing-renews-date {
    color: ${props => props.theme.colors.neutral_8};
  }
`;

const StyledBillingInformationTitlePlanValue = styled.div`
  background: ${props => props.theme.colors.primary_4};
  border-radius: 0 48px 48px 48px;
  height: 48px;
  width: 48px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.neutral_1};
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 40px;
`;

const StyledBillingInformationTable = styled(Table)`
  border: 1px solid ${props => props.theme.colors.neutral_3};
  border-bottom: none;
  
  .ant-table-cell::before {
    content: none !important;
  }
  
  .cashout {
    color: ${props => props.theme.colors.danger};
  }

`;

const StyledBillingInformationLoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const StyledBillingInformationTableDownloadContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 38px;
  
  .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 37.5%);
  }
  
  svg {
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      fill: ${props => props.theme.colors.neutral_6};
    }
    
    &:hover {
      cursor: pointer;
      path {
        fill: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

export {
  StyledBillingInformation,
  StyledBillingInformationTitle,
  StyledBillingInformationTitleSpace,
  StyledBillingInformationTitlePlanValue,
  StyledBillingInformationTable,
  StyledBillingInformationLoadMoreContainer,
  StyledBillingInformationTableDownloadContainer
};
