import React from 'react';
import {StyledInvoicesTable} from './StyledinvoicesTable';

const InvoicesTable = ({
  ...rest
}) => {

  return (
    <StyledInvoicesTable
      rowKey='id'
      {...rest}
    />
  );
}

export default InvoicesTable;
