import React from 'react';

const DragIndicatorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 20C8.45 20 7.979 19.804 7.587 19.412C7.195 19.02 6.99934 18.5493 7 18C7 17.45 7.196 16.979 7.588 16.587C7.98 16.195 8.45067 15.9993 9 16C9.55 16 10.021 16.196 10.413 16.588C10.805 16.98 11.0007 17.4507 11 18C11 18.55 10.804 19.021 10.412 19.413C10.02 19.805 9.54934 20.0007 9 20ZM15 20C14.45 20 13.979 19.804 13.587 19.412C13.195 19.02 12.9993 18.5493 13 18C13 17.45 13.196 16.979 13.588 16.587C13.98 16.195 14.4507 15.9993 15 16C15.55 16 16.021 16.196 16.413 16.588C16.805 16.98 17.0007 17.4507 17 18C17 18.55 16.804 19.021 16.412 19.413C16.02 19.805 15.5493 20.0007 15 20ZM9 14C8.45 14 7.979 13.804 7.587 13.412C7.195 13.02 6.99934 12.5493 7 12C7 11.45 7.196 10.979 7.588 10.587C7.98 10.195 8.45067 9.99934 9 10C9.55 10 10.021 10.196 10.413 10.588C10.805 10.98 11.0007 11.4507 11 12C11 12.55 10.804 13.021 10.412 13.413C10.02 13.805 9.54934 14.0007 9 14ZM15 14C14.45 14 13.979 13.804 13.587 13.412C13.195 13.02 12.9993 12.5493 13 12C13 11.45 13.196 10.979 13.588 10.587C13.98 10.195 14.4507 9.99934 15 10C15.55 10 16.021 10.196 16.413 10.588C16.805 10.98 17.0007 11.4507 17 12C17 12.55 16.804 13.021 16.412 13.413C16.02 13.805 15.5493 14.0007 15 14ZM9 8C8.45 8 7.979 7.804 7.587 7.412C7.195 7.02 6.99934 6.54934 7 6C7 5.45 7.196 4.979 7.588 4.587C7.98 4.195 8.45067 3.99934 9 4C9.55 4 10.021 4.196 10.413 4.588C10.805 4.98 11.0007 5.45067 11 6C11 6.55 10.804 7.021 10.412 7.413C10.02 7.805 9.54934 8.00067 9 8ZM15 8C14.45 8 13.979 7.804 13.587 7.412C13.195 7.02 12.9993 6.54934 13 6C13 5.45 13.196 4.979 13.588 4.587C13.98 4.195 14.4507 3.99934 15 4C15.55 4 16.021 4.196 16.413 4.588C16.805 4.98 17.0007 5.45067 17 6C17 6.55 16.804 7.021 16.412 7.413C16.02 7.805 15.5493 8.00067 15 8Z"
      fill="#000" />
  </svg>

);

export default DragIndicatorIcon;
