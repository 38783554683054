import styled from 'styled-components';
import {Tabs} from 'antd';
import TransactionDetailsPanel from '../../TransactionsPage/TransactionDetailsPanel';

const StyledInvoiceTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  padding: 32px 0 56px 24px;
  position: relative;
  
  & > div {
    position: relative;
  }
`;

const StyledInvoiceTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-tab {
      font-weight: 500;
      padding: 9px 32px;

      &.ant-tabs-tab-active {
        background-color: ${props => props.theme.colors.neutral_1};
      }

      &:not(.ant-tabs-tab-active) {
        background: unset;
        border-color: transparent;
      }
    }
  }
  .ant-tabs-content-holder {
    background-color: ${props => props.theme.colors.neutral_1};
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-top: unset;
    padding: 24px;
    min-height: calc(100vh - 64px - (16px * 2) - (24px * 2) - 40px);
  }
`;

const StyledInvoiceDetailsPanel = styled(TransactionDetailsPanel)`
  & > div {
    margin-top: ${props => props.fixed ? '0' : '41px'};
    transition: margin-top .3s ease;
    & > div {
      width: calc(100% - 24px);
    }
  }
`;

const StyledInvoiceTabsContent = styled.div`
  .ant-tabs-content-holder {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    min-height: unset;
  }
`;

export {
  StyledInvoiceTabs,
  StyledInvoiceTabsContainer,
  StyledInvoiceDetailsPanel,
  StyledInvoiceTabsContent
}
