import styled from 'styled-components';
import {Card, Space} from 'antd';

const StyledCompanies = styled(Card)`
  .ant-card-head {
    flex-direction: unset;
  }

  .ant-card-body {
    padding: 0;
  }
`;

const StyledCompanyItem = styled(Space)`
  padding: 16px;
  width: 100%;

  .ant-space-item {
    &:first-of-type {
      color: ${props => props.theme.colors.neutral_10};
      font-weight: 500;
      font-size: 16px;
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  }
`;

export {
  StyledCompanies,
  StyledCompanyItem
}
