import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {
  StyledTableFilters,
  StyledTableFiltersSearch,
  StyledTableFiltersSelect
} from './StyledTableFilters';
import ExportModal from '../ExportModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow/AuthenticationWindow';
import {useIsMount} from '../../../../hooks';
import {expenseActions, transactionActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';
import {scaHelpers} from '../../../../scaHelpers';
import {SCAActionsConstants} from '../../../../constants';

const SCA_OPERATION_NAME = SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION;

const clearCardValue = (value) => value ? value.replaceAll('*', '') : '';

const TableFilters = ({
  enableExport,
  onFilter,
  onSearch,
  searchParams,

  cards,
  isAdmin,
  isKnownEmployee,

  downloadTransactions,
  downloadZipInvoices,
}) => {
  const [t] = useTranslation('main');
  const [exportModalProps, setExportModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [exportDetails, setExportDetails] = useState({range: undefined, format: null});
  const [isOpenCardsDropdown, setIsOpenCardsDropdown] = useState(false);

  const [filter, setFilter] = useState({
    cards: [],
    types: [],
    users: []
  });
  const isMounted = !useIsMount();

  useEffect(() => {
    if (isKnownEmployee) {
      if (searchParams && searchParams.hasOwnProperty('card')) {
        const {card: selectedCard} = searchParams;
        if (!filter.types.includes('card') && !filter.cards.includes(selectedCard)) {
          setFilter({
            ...filter,
            cards: [...filter.cards, selectedCard],
            types: [...filter.types, 'card']
          });
        }
      } else {
        handleFilter(filter);
      }
    }
  }, [isKnownEmployee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => isMounted && handleFilter(filter), [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOptions = useMemo(() => cards.map(card => ({label: helpers.hideCardNumber(card.masked_pan), value: card.id})), [cards]);

  const handleFilter = (filterData) => {
    const {cards, users, types} = filterData;
    let isCardTransaction = undefined;
    if (types.length === 1) isCardTransaction = types[0] === 'card';

    onFilter && onFilter({
      card_id_in: cards,
      user_id_in: users,
      is_card_transaction: isCardTransaction
    });
  }

  const handleOnSearch = (value) => onSearch && onSearch(value);

  const handleOnClearCard = () => handleOnSelectCard('');

  const handleOnSearchCard = (value) => {
    value = clearCardValue(value);
    setIsOpenCardsDropdown(!(!value || !value.length));
  }

  const handleOnSelectCard = (value) => {
    handleOnSearchCard();
    setFilter({
      ...filter,
      cards: value ? [value] : []
    });
  }

  const filterCardOption = (input, option) => clearCardValue(option.label).indexOf(clearCardValue(input)) >= 0;

  const handleExportTransactions = (data) => {
    const {format, date} = data;

    const errorCallback = () => setExportModalProps({...exportModalProps, open: true, loading: false});
    setExportModalProps({...exportModalProps, loading: true});
    if (format === 'zip') {
      const query = {
        year_month: date.format('YYYY-MM'),
      };
      downloadZipInvoices(
        query,
        (data) => {
          helpers.saveFile({
            file: data.file,
            filename: 'invoices.zip',
            'content-type': 'application/zip'
          });
          handleCancelExportModal();
        },
        errorCallback
      )
    } else {
      const timeFormat = 'YYYY-MM-DD';
      const startMonthDate = date.startOf('month').format(timeFormat);
      const endMonthDate = date.endOf('month').format(timeFormat);
      const query = {
        date_gte: startMonthDate,
        date_lte: endMonthDate,
        month: date.format('MM'),
        year: date.format('YYYY'),
        file_format: format
      };
      downloadTransactions({
        headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
        query,
        successCallback: (data) => {
          window.open(data.url, 'download');
          handleCancelExportModal();
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => {
              setAuthWindowProps({...authWindowProps, ...scaProps});
              setExportDetails(data);
            },
            errorCallback
          });
        }
      });
    }
  }

  const handleOnExportClick = () => setExportModalProps({...exportModalProps, open: true});

  const handleCancelExportModal = () => setExportModalProps({...exportModalProps, open: false, loading: false});

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    setExportModalProps({...exportModalProps, loading: false});
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    handleExportTransactions(exportDetails);
  }

  return (
    <StyledTableFilters>
      <Space align='center' size='middle'>
        <StyledTableFiltersSearch
          allowClear
          onSearch={handleOnSearch}
          placeholder={t('search')}
          size='large'
        />
        <StyledTableFiltersSelect
          allowClear
          filterOption={filterCardOption}
          onSelect={handleOnSelectCard}
          options={cardOptions}
          optionLabelProp='label'
          onClear={handleOnClearCard}
          onSearch={handleOnSearchCard}
          open={isOpenCardsDropdown}
          placeholder={`${t('all')} ${'Cards'}`}
          showSearch
          size='large'
        />
      </Space>
      {(isAdmin && enableExport) && (
        <>
          <Button
            onClick={handleOnExportClick}
            size='large'
            type='primary'
          >
            {t('export')}
          </Button>

          <ExportModal
            {...exportModalProps}
            handleCancel={handleCancelExportModal}
            handleOk={handleExportTransactions}
          />

          <AuthenticationWindow
            {...authWindowProps}
            handleCancel={handleCloseAuthModal}
            onSuccess={handleOnSuccessAuth}
            operationName={SCA_OPERATION_NAME}
          />
        </>
      )}

    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  enableExport: PropTypes.bool,
  onSearch: PropTypes.func,
  onFilter: PropTypes.func,
  searchParams: PropTypes.object,
}

TableFilters.defaultProps = {
  enableExport: false
}

const mapStateToProps = state => {
  const {isKnownEmployee, isAdmin} = state.user;
  const {cards} = state.card;
  return {
    cards,
    isKnownEmployee,
    isAdmin
  }
}

const mapDispatchToProps = {
  downloadTransactions: transactionActions.downloadTransactions,
  downloadZipInvoices: expenseActions.downloadZipInvoices
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
