import styled from 'styled-components';

const StyledSubscriptionStatusMark = styled.div`
  display: flex;
  align-items: center;
  & > *:first-of-type {
    margin-right: 8px;
  }
`;

export default StyledSubscriptionStatusMark;
