import styled from 'styled-components';
import {Spin} from 'antd';

const StyledKYCModalScreenContent = styled.div`
  color: ${props => props.theme.colors.neutral_10};
  overflow: auto;
  
  .left-side-content {
    max-width: 400px;
  }
  
  .column-form-content {
    max-width: 832px;
  }
  
  .ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
    .kyc-upload {
      background: linear-gradient(to right, ${props => props.theme.colors.danger} 50%, transparent 0%) top repeat-x,
                  linear-gradient(${props => props.theme.colors.danger} 50%, transparent 0%) right repeat-y,
                  linear-gradient(to right, ${props => props.theme.colors.danger} 50%, transparent 0%) bottom repeat-x,
                  linear-gradient(${props => props.theme.colors.danger} 50%, transparent 0%) left repeat-y;
      background-color: ${props => props.theme.colors.neutral_3};
      background-size: 24px 1px, 1px 24px;
    }
  }
  
  form.file-upload-form {
    display: flex;
    flex-direction: column;
    
    .ant-form-item,
    .ant-form-item-row,
    .ant-form-item-control,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content,
    .ant-form-item-control-input-content .kyc-upload {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
    }
    
    .ant-form-item {
      margin: 0;
    }
  }
`;


const StyledKYCModalScreenFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.neutral_4};
  height: 104px;
  padding: 24px 40px;
  
  .ant-btn {
    height: 56px;
  }
`;

const StyledKYCModalScreen = styled.div`
  background-color: #F9F9F9;
  width: 100%;
  
  ${StyledKYCModalScreenContent} {
    padding: 120px 104px 24px 120px;
  }
  
  ${StyledKYCModalScreenFooter} {
    padding: 24px 104px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    
    .ant-btn {
      width: 180px;
    }
  }
`;

const StyledKYCModalSpin = styled(Spin)`
  background-color: #F9F9F9;
  max-height: unset !important;
`;


export {
  StyledKYCModalScreen,
  StyledKYCModalScreenContent,
  StyledKYCModalScreenFooter,
  StyledKYCModalSpin
};
