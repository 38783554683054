export const overviewConstants = {
  SUCCESS_GET_TOP_COMPANY_CATEGORY: 'SUCCESS_GET_TOP_COMPANY_CATEGORY',
  ERROR_GET_TOP_COMPANY_CATEGORY: 'ERROR_GET_TOP_COMPANY_CATEGORY',

  SUCCESS_GET_TOP_COMPANY_EMPLOYEE: 'SUCCESS_GET_TOP_COMPANY_EMPLOYEE',
  ERROR_GET_TOP_COMPANY_EMPLOYEE: 'ERROR_GET_TOP_COMPANY_EMPLOYEE',

  SUCCESS_GET_TOP_COMPANY_TAG: 'SUCCESS_GET_TOP_COMPANY_TAG',
  ERROR_GET_TOP_COMPANY_TAG: 'ERROR_GET_TOP_COMPANY_TAG',

  SUCCESS_GET_TOP_COMPANY_VENDOR: 'SUCCESS_GET_TOP_COMPANY_VENDOR',
  ERROR_GET_TOP_COMPANY_VENDOR: 'ERROR_GET_TOP_COMPANY_VENDOR',

  SUCCESS_GET_TOTAL_COSTS: 'SUCCESS_GET_TOTAL_COSTS',
  ERROR_GET_TOTAL_COSTS: 'ERROR_GET_TOTAL_COSTS',

  SUCCESS_GET_COSTS_BY_DAY: 'SUCCESS_GET_COSTS_BY_DAY',
  ERROR_GET_COSTS_BY_DAY: 'ERROR_GET_COSTS_BY_DAY',

  SUCCESS_GET_TOP_EMPLOYEE_CATEGORY: 'SUCCESS_GET_TOP_EMPLOYEE_CATEGORY',
  ERROR_GET_TOP_EMPLOYEE_CATEGORY: 'ERROR_GET_TOP_EMPLOYEE_CATEGORY',

  SUCCESS_GET_TOP_EMPLOYEE_TAGS: 'SUCCESS_GET_TOP_EMPLOYEE_TAGS',
  ERROR_GET_TOP_EMPLOYEE_TAGS: 'ERROR_GET_TOP_EMPLOYEE_TAGS',

  SUCCESS_GET_TOP_EMPLOYEE_VENDOR: 'SUCCESS_GET_TOP_EMPLOYEE_VENDOR',
  ERROR_GET_TOP_EMPLOYEE_VENDOR: 'ERROR_GET_TOP_EMPLOYEE_VENDOR',
};
