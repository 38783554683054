import styled from 'styled-components';
import {Table} from 'antd';

const StyledTransactionTable = styled(Table)`
  border: 1px solid ${props => props.theme.colors.neutral_3};
  border-radius: ${props => props.theme.border_radius.standard};

  .ant-table-container {
    overflow: auto;
  }

  tbody {
    tr {
      &:not(.grouped-row) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  thead {
    th {
      &:last-of-type {
        &:before {
          content: none !important;
        }
      }
    }
  }

  .total-amount {
    color: ${props => props.theme.colors.neutral_8};
    font-size: 16px;
    text-wrap: nowrap;
  }

  .grouped-row {
    background: linear-gradient(0deg, ${props => props.theme.colors.neutral_2}, ${props => props.theme.colors.neutral_2}), linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
  }

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 32px;
      width: 32px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }

    .card-number {
      margin-left: 12px;
    }
  }

  .transaction-icon {
    svg {
      height: 32px;
      width: 32px;

      path {
        &:not([fill-rule="evenodd"]) {
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
  
  .danger-text {
    color: ${props => props.theme.colors.danger} !important;
  }
  
  .returned-price {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: line-through;
  }
`;

const StyledTableContainer = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px;
`;

const TotalSpend = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  color: ${props => props.theme.colors.neutral_7};

  .price {
    color: ${props => props.theme.colors.neutral_10};
  }
`;

export {
  StyledTransactionTable,
  StyledTableContainer,
  TotalSpend
}
