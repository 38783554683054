import React from 'react';
import PropTypes from 'prop-types';
import {StyledSubscriptionInfo} from './StyledSubscriptionInfo';
import {helpers} from '../../../../helpers';
import SubscriptionStatusMark from '../../SubscriptionsPage/SubscriptionStatusMark';

const SubscriptionInfo = ({
  data,
  enabledStatus,
  ...rest
}) => {
  const gSubscriptionProp = (key, defaultValue = '') => helpers.getObjProp(data, key, defaultValue);

  const gServiceProps = (key, defaultValue = '') => data && data.service ? helpers.getObjProp(data.service, key, defaultValue) : defaultValue;

  const statusCode = gSubscriptionProp('status');

  const category = gServiceProps('category');
  const logo = gServiceProps('logo');
  const name = gServiceProps('name');
  const url = gServiceProps('url');
  const label = name || url;
  const extra = enabledStatus && (
    <SubscriptionStatusMark statusCode={statusCode}/>
  );

  return (
    <StyledSubscriptionInfo
      enabledstatus={String(enabledStatus || false)}
      extra={extra}
      description={category}
      name={label}
      logo={logo}
      url={url ? `https://${url}` : undefined}
      {...rest}
    />
  );
}

SubscriptionInfo.propTypes = {
  data: PropTypes.shape({
    service: PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string
    }),
    status: PropTypes.number
  }),
  enabledStatus: PropTypes.bool
}

SubscriptionInfo.defaultProps = {
  enabledStatus: true
}

export default SubscriptionInfo;

