import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Form, Input, Select, Space, Spin} from 'antd';
import {EditFilled} from '@ant-design/icons';
import {UnmountClosed} from 'react-collapse';
import VerifyModal from '../../../../quickStart/VerifyModal/VerifyModal';
import CompanyInfoDetails from '../../tabComponents/CompanyInfo';
import {StyledCompanyInfo, StyledTitle} from './StyledCompanyInfo';
import DetailsTable from '../../../TransactionsPage/DetailsTable';
import {EmailRegexp, helpers, ZipRegexp} from '../../../../../helpers';
import {companyActions, userActions} from '../../../../../state/actions';
import useContentLoaded from '../../../../../hooks/useContentLoaded';
import EditableAvatar from '../../../../EditableAvatar';

const {Item} = Form;

const gOP = helpers.getObjProp;

const countryOptions = helpers.getSupportedCountries().map(c => ({label: c.name, value: c.name}));

const CompanyInfo = ({
  dispatch,
  currencies,
  settings,
  sendEmailVerification,
  companyId,
  getCompanies,
  getSettings,
  updateCompanyInformation,
  isEnabledEmptyState,
  ...rest
}) => {
  const [trans] = useTranslation('settings');
  const isLoaded = useContentLoaded(settings);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [formValues, setFormValues] = useState({
    address: '',
    city: '',
    country: '',
    currency: '',
    email: '',
    name: '',
    vat: '',
    zip: ''
  });
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const t = (key) => trans(`tabs.companyInfo.${key}`);

  useEffect(() => {
    if (!helpers.isEmptyObject(settings)) {
      const {address, city, country, email, company_logo_url: logo} = settings;
      const formValues = {
        address,
        city,
        country,
        currency: settings.company_currency,
        email,
        logo,
        company_name: settings.company_name,
        vat_id: settings.vat_id,
        zip_code: settings.zip_code
      }
      setFormValues(formValues);
      form.setFieldsValue(formValues);
      setLogo(logo);
    }
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => clearError(), [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const mainT = (key, options) => trans(`main:${key}`, options);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const finishLoading = () => setLoading(false);

  const handleSubmit = (fields) => {
    let data = {
      address: fields.address,
      company_name: fields.company_name,
      city: fields.city,
      country: fields.country,
      email: fields.email,
      vat_id: fields.vat_id,
      zip_code: fields.zip_code
    }

    // update or remove company logo
    if (formValues.logo !== logo) {
      data = {
        ...data,
        company_logo_url: logo ? helpers.cutFileData(logo) : null
      }
    }

    if (errorMessage) return;

    setLoading(true);
    updateCompanyInformation(
      data,
      () => {
        getCompanies(
          undefined,
          () => {
            getSettings(
              () => {
                finishLoading();
                setIsEditMode(false);
              },
              finishLoading
            );
          }
        );
      },
      (error) => {
        let fields = helpers.getFormServerErrorFields(error);
        let errorMessage;
        if (error && error.hasOwnProperty('message')) errorMessage = error.message;
        form.setFields(fields);
        setErrorMessage(errorMessage);
        finishLoading();
      }
    );
  }

  const clearError = () => errorMessage && setErrorMessage(undefined);

  const handleVerify = () => {
    setVerificationLoading(true);
    sendEmailVerification(
      email,
      () => {
        setShowVerifyModal(true);
        setVerificationLoading(false);
      },
      () => setVerificationLoading(false)
    )
  }

  const handleChangeLogo = ({file}) => {
    setLogo(file);
    clearError();
  }

  const onEditClick = () => setIsEditMode(true);

  const {company_name: name, currency, vat_id: vat, email} = formValues;

  const isVerifiedEmail = gOP(settings, 'email_verified', false) || false;

  const showContent = (content, loader) => helpers.showContentWithLoader(isLoaded, content, loader);

  const tableData = [
    {
      key: 'company-name',
      label: `${mainT('company')} ${mainT('name')}`,
      value: showContent(name)
    },
    {
      key: 'address',
      label: t('address'),
      value: showContent(helpers.getCompanyAddress(formValues))
    },
    {
      key: 'vat-number',
      label: t('vatNumber'),
      value: showContent(vat)
    },
    {
      key: 'email',
      label: mainT('email'),
      value: (
        <Space size='middle'>
          {showContent(email)}
          {isLoaded && !isVerifiedEmail && (
            <Button
              className='green-btn'
              loading={verificationLoading}
              onClick={handleVerify}
              size='middle'
            >
              {mainT('verify')} {mainT('email')}
            </Button>
          )}
        </Space>
      )
    },
    {
      key: 'currency',
      label: t('currency'),
      value: showContent(currency)
    },
    {
      key: 'company-id',
      label: `${mainT('company')} ID`,
      value: showContent(companyId)
    }
  ];

  const title = isEditMode ? (
    <>
      <StyledTitle>
        <EditableAvatar
          disabled={loading}
          defaultValue={formValues.logo}
          initials={helpers.getInitials(name)}
          onChange={handleChangeLogo}
          onError={setErrorMessage}
          showError={false}
        />
        <Space size='middle'>
          <Button
            disabled={loading}
            onClick={handleOnCancel}
            size='large'
            type='default'
          >
            {mainT('cancel')}
          </Button>
          <Button
            className='green-btn'
            disabled={loading}
            onClick={handleOnSave}
            size='large'
          >
            {mainT('save')}
          </Button>
        </Space>
      </StyledTitle>
      <UnmountClosed isOpened={Boolean(errorMessage)}>
        <span className='title-error-message'>
          {errorMessage}
        </span>
      </UnmountClosed>
    </>
  ) : (
    <StyledTitle>
      <CompanyInfoDetails className='company-info-details' />
      <Button
        icon={<EditFilled />}
        onClick={onEditClick}
        size='large'
        type='default'
      >
        {mainT('edit')}
      </Button>
    </StyledTitle>
  );

  const lengthFieldMessage = (value, type = 'min') => mainT(`validation.${type}LengthValidation`, {value});

  const requiredRules = [{required: true, message: mainT('validation.fieldIsRequired')}];

  const currencyOptions = currencies.map(c => ({label: c, value: c}));

  return (
    <StyledCompanyInfo
      {...rest}
      title={title}
    >

      <DetailsTable
        className={isEditMode && 'd-none'}
        data={tableData}
      />

      <Spin spinning={loading}>
        <Form
          className={!isEditMode && 'd-none'}
          initialValues={formValues}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Item
            label={`${mainT('company')} ${mainT('name')}`}
            name='company_name'
            rules={[
              ...requiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')}
            ]}
          >
            <Input
              minLength={2}
              maxLength={50}
              size='large'
            />
          </Item>
          <Item
            label={mainT('address')}
            name='address'
            rules={requiredRules}
          >
            <Input size='large' />
          </Item>
          <Item
            label={mainT('city')}
            name='city'
            rules={requiredRules}
          >
            <Input size='large' />
          </Item>
          <Item
            label={mainT('postCode')}
            name='zip_code'
            rules={[
              ...requiredRules,
              {min: 4, message: lengthFieldMessage(4, 'min')},
              {max: 10, message: lengthFieldMessage(10, 'max')},
              {pattern: ZipRegexp, message: mainT('validation.zipCodeInvalid')}
            ]}
          >
            <Input
              maxLength={10}
              size='large'
            />
          </Item>
          <Item
            label={mainT('country')}
            name='country'
            rules={requiredRules}
          >
            <Select
              options={countryOptions}
              size='large'
              showSearch
            />
          </Item>
          <Item
            label={t('vatNumber')}
            name='vat_id'
            rules={[
              ...requiredRules,
              {min: 4, message: lengthFieldMessage(4, 'min')},
              {max: 15, message: lengthFieldMessage(15, 'max')}
            ]}
          >
            <Input
              maxLength={15}
              size='large'
            />
          </Item>
          <Item
            label={mainT('email')}
            name='email'
            rules={[
              ...requiredRules,
              {pattern: EmailRegexp, message: mainT('validation.enterValidEmail')}
            ]}
          >
            <Input
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={mainT('currency')}
            name='currency'
          >
            <Select
              disabled
              options={currencyOptions}
              size='large'
            />
          </Item>
        </Form>
      </Spin>

      <VerifyModal
        open={showVerifyModal}
        email={email}
        onCancel={() => setShowVerifyModal(false)}
      />

    </StyledCompanyInfo>
  );
}

const mapStateToProps = state => {
  const {id, settings} = state.company;
  const {currencies} = state.main;

  return {
    currencies,
    companyId: id,
    settings
  }
}

const mapDispatchToProps = {
  getCompanies: userActions.getCompanies,
  getSettings: companyActions.getCompanySettings,
  sendEmailVerification: companyActions.sendEmailVerification,
  updateCompanyInformation: companyActions.updateCompanyInformation
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
