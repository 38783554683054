import React, {useEffect, useMemo, useState} from 'react';
import {DatePicker, Form, Input, Select, Space, Spin} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  StyledUserInformationForm
} from './StyledUserInformationForm';
import {StyledKYCModalContentStepTitle, StyledKYCModalContentColumnFormSpace, StyledKYCModalTitleNameSpace} from '../../../../KYCModal/StyledKYCModal';
import PhoneNumberInput from '../../../../../PhoneNumberInput';
import AntdCountriesSelect from '../../../../../Select/AntdCountriesSelect';
import {kycHelpers} from '../../kycHelpers';
import useLocation from '../../../../../../hooks/useLocation';
import {helpers} from '../../../../../../helpers';
import {titleOptions} from '../../../../../pages/CardsPage/cardsOptions';

const {Item} = Form;

const initialFormValues = {
  birthday: undefined,
  birth_country: undefined,
  firstname: '',
  email: '',
  lastname: '',
  nationality: undefined,
  phone: '',
  place_of_birth: '',
  tax_residence: undefined,
  tax_number: '',
  title: titleOptions[0].value,
}

const formSpaceProps = {
  align: 'start',
  size: 32
}

const countrySelectProps = kycHelpers.getCountrySelectProps();
const datePickerProps = kycHelpers.getDatePickerProps('birthday');

const UserInformationForm = React.forwardRef(({
  defaultFormValues,
  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();

  const {phone_code: defaultPhoneCode, code: defaultCountryCode} = useLocation();

  const {
    description,
    label,
    lengthFieldMessage,
    placeholder,
    validationPhoneRules,
    validationRequiredRules,
    validationTaxRules,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'yourInformation'}), [t]);


  useEffect(() => {
    if (defaultCountryCode && helpers.isEmptyObject(defaultFormValues)) {
      form.setFieldsValue({
        birth_country: defaultCountryCode,
        country: defaultCountryCode,
        nationality: defaultCountryCode
      });
    }
  }, [defaultCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    defaultFormValues && form.setFieldsValue(defaultFormValues);
  }, [defaultFormValues, form]);

  const onFinish = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (errors) => {
          form.setFields(errors);
          setLoading(false)
        }
      )
    }
  }

  return (
    <StyledUserInformationForm
      direction='vertical'
      size={32}
      {...rest}
    >
      <Space
        direction='vertical'
        size='middle'
      >
        <StyledKYCModalContentStepTitle>
          {title}
        </StyledKYCModalContentStepTitle>
        <p>{description}</p>
      </Space>
      <Spin spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <StyledKYCModalTitleNameSpace {...formSpaceProps}>
              <Item
                label={label('title')}
                name='title'
                required
              >
                <Select
                  options={titleOptions}
                  size='large'
                />
              </Item>
              <Item
                label={label('firstName')}
                name='firstname'
                rules={[
                  ...validationRequiredRules,
                  {min: 2, message: lengthFieldMessage(2, 'min')}
                ]}
              >
                <Input
                  minLength={2}
                  maxLength={50}
                  placeholder={placeholder('firstName')}
                  size='large'
                />
              </Item>
            </StyledKYCModalTitleNameSpace>
            <Item
              label={label('lastName')}
              name='lastname'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('lastName')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>

          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('dateOfBirth')}
              name='birthday'
              rules={validationRequiredRules}
            >
              <DatePicker
                {...datePickerProps}
                placeholder={placeholder('dateOfBirth')}
              />
            </Item>
            <Item
              label={label('placeOfBirth')}
              name='place_of_birth'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('placeOfBirth')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>

          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('countryOfBirth')}
              name='birth_country'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('countryOfBirth')}
                {...countrySelectProps}
              />
            </Item>
            <Item
              label={label('nationality')}
              name='nationality'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('nationality')}
                {...countrySelectProps}
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('email')}
              name='email'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('email')}
                size='large'
                type='email'
              />
            </Item>
            <Item
              label={label('phoneNumber')}
              name='phone'
              rules={validationPhoneRules}
            >
              <PhoneNumberInput
                phoneCode={defaultPhoneCode}
                placeholder={placeholder('phoneNumber')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('taxResidence')}
              name='tax_residence'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('taxResidence')}
                {...countrySelectProps}
              />
            </Item>
            <Item
              label={label('taxCode')}
              name='tax_number'
              rules={validationTaxRules}
            >
              <Input
                placeholder={placeholder('taxCode')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
        </Form>
      </Spin>
    </StyledUserInformationForm>
  );
});

export default UserInformationForm;
