import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {ReactComponent as EmptySearchResultSvgImage} from '../../static/images/pages/empty-search-result.svg';
import Empty from '../Empty';

const NoSearchResults = ({
  value,
  ...rest
}) => {
  const [trans] = useTranslation('main');
  const t = (key) => trans(`noSearchResults.${key}`);

  return (
    <Empty
      description={(
        <>
          {t('description1')} <b>{value}</b>.
          <br/>
          {t('description2')}
        </>
      )}
      image={<EmptySearchResultSvgImage />}
      title={t('title')}
      {...rest}
    />
  );
}

NoSearchResults.propTypes = {
  value: PropTypes.string
}

export default NoSearchResults;
