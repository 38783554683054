import styled from 'styled-components';

const PANEL_BODY_PADDING = '24px';

const StyledTransactionDetailsPanel = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const StyledTransactionDetailsPanelContainer = styled.div` 
  position: ${props => props.fixed ? 'fixed' : 'relative'};
  top: ${props => props.fixed ? PANEL_BODY_PADDING : '0px'};
  transition: top .3s ease, overflow 0.3s ease;
  width: inherit;
  
  & > div {
    opacity: ${props => props.hiddenOverflow ? 0 : 1};
    transition: opacity .3s ease;
  }
`;

const StyledTransactionDetailsContent = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_3};
  border-radius: ${props => props.theme.border_radius.standard};
  margin-left: 24px;
`;

const StyledPanelBody = styled.div`
  padding: ${PANEL_BODY_PADDING};
  overflow: auto;
  max-height: calc(100vh - (66px + ${props => props.fixed ? PANEL_BODY_PADDING : `${props.offset}px`}));
`;

const StyledPanelHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  padding: 20px 24px;

  button.ant-btn {
    border: none;
    padding: 0;
    height: 24px;
    width: 24px;

    svg {
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        fill: ${props => props.theme.colors.neutral_6};
        stroke: ${props => props.theme.colors.neutral_6};
      }
    }

    &:hover {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.88);
          stroke: rgba(0, 0, 0, 0.88);
        }
      }
    }
  }
`;

export {
  StyledTransactionDetailsPanel,
  StyledTransactionDetailsPanelContainer,
  StyledTransactionDetailsContent,
  StyledPanelHeader,
  StyledPanelBody
};
