import styled from 'styled-components';
import {Space} from 'antd';

const StyledSubscriptionChart = styled(Space)`
  width: 100%;
  
  .recharts-tooltip-wrapper {
    z-index: 3;
  }
`;

const StyledSubscriptionChartLegend = styled.div`
  text-align: center;
`;

const StyledSubscriptionChartLegendItem = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 16px;
  
  & > div {
    display: flex;
    align-items: center;
  }
`;

const StyledSubscriptionChartLegendSurface = styled.span`
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  &.actual {
    background-color: ${props => props.theme.colors.primary_4};
  }
  &.forecasted {
    border: 1px solid ${props => props.theme.colors.tertiary_4};
    position: relative;
    &:before,&:after {
      content: "";
      position: absolute;
      background-color: white;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    &:before {
      height: calc(100% + 10px);
      width: 37.5%;
    }
    &:after {
      height: 37.5%;
      width: calc(100% + 10px);
    }
  }
`;

export {
  StyledSubscriptionChart,
  StyledSubscriptionChartLegend,
  StyledSubscriptionChartLegendItem,
  StyledSubscriptionChartLegendSurface
}