import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Space, Spin} from 'antd';
import {connect} from 'react-redux';
import CopyField from '../../../../../CopyField';
import {StyledCardDetailsModal, StyledCardDetailsTimer, StyledCardDetailsCard} from './StyledCardDetailsModal';
import {cardsHelpers} from '../../../../CardsPage/cardsHelpers';
import {alertActions} from '../../../../../../state/actions';
import {useIsMount} from '../../../../../../hooks';
import {helpers} from '../../../../../../helpers';

let interval = null;

const CardDetailsModal = ({
  dispatch,

  companyDetails,
  image,
  initialSeconds,
  handleCancel,
  handleOk,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [cardDetails, setCardDetails] = useState({
    cvv: null,
    expired: null,
    isLoaded: false,
    number: null,
    owner: null
  });
  const [seconds, setSeconds] = useState(initialSeconds);
  const isMounted = !useIsMount();

  const [cardImage, setCardImage] = useState(image);

  useEffect(() => {
    if (image && open && image !== cardImage) {
      if (cardDetails.isLoaded) setCardDetails({...cardDetails, isLoaded: false});

      cardsHelpers
        .getCardDetailsFromImage(image)
        .then(details => setCardDetails({...details, isLoaded: true}))
        .catch(() => setCardDetails({...cardDetails, isLoaded: true}));
      setCardImage(image);
    } else if (cardImage && !open) {
      setCardImage(undefined);
    }
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted && !open) {
      // clear the form fields and errors after closing the modal
      setTimeout(() => {
        setSeconds(initialSeconds);
      }, [300]);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const startTimer = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    if (seconds > 0 && open) {
      startTimer();
      return () => {
        if (interval) clearInterval(interval);
      }
    } else if (seconds <= 0) {
      handleCancel && handleCancel();
    }
  }, [seconds, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopy = () => alertActions.success(`${t('dataCopiedToClipboard')}!`);

  const footer = [
    <StyledCardDetailsTimer key='timer'>
      {t('time')} {t('left')}: <span className='time'>{helpers.getSecondsTime(seconds)}</span>
    </StyledCardDetailsTimer>,
    <Button
      className='green-btn'
      key='submit'
      onClick={() => handleCancel && handleCancel()}
      size='large'
    >
      {t('done')}
    </Button>
  ];

  const copyFieldProps = {
    emptyMessage: t('cards:whoopsWeCouldNotReadThisFromTheCard'),
    enabledEmpty: cardDetails.isLoaded,
    onCopy: handleCopy
  }

  return (
    <StyledCardDetailsModal
      footer={footer}
      open={open}
      onCancel={handleCancel}
      title={`${t('card')} ${t('details')}`}
      width={806}
      {...rest}
    >
      <Space direction='vertical' size='middle'>
        <Space size='middle' className='card-details'>
          <img src={cardImage} alt='card' className='card-image' />
          <Spin spinning={!cardDetails.isLoaded}>
            <StyledCardDetailsCard>
              <CopyField
                {...copyFieldProps}
                label='Name or Card'
                value={cardDetails.owner}
              />
              <CopyField
                {...copyFieldProps}
                label='Card number'
                value={cardDetails.number}
              />
              <CopyField
                {...copyFieldProps}
                label='Card expiry date'
                value={cardDetails.expired}
              />
              <CopyField
                {...copyFieldProps}
                label='CVV'
                value={cardDetails.cvv}
              />
            </StyledCardDetailsCard>
          </Spin>
        </Space>

        <StyledCardDetailsCard title='Billing Information'>
          <CopyField
            label='Company name'
            onCopy={handleCopy}
            value={companyDetails.name}
          />
          <CopyField
            label='Address'
            onCopy={handleCopy}
            value={companyDetails.address}
          />
          <CopyField
            label='VAT'
            onCopy={handleCopy}
            value={companyDetails.vat}
          />
        </StyledCardDetailsCard>
      </Space>

    </StyledCardDetailsModal>
  );
}

CardDetailsModal.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    vat: PropTypes.string
  }),
  image: PropTypes.string,
  initialSeconds: PropTypes.number,
  handleCancel: PropTypes.func
}

CardDetailsModal.defaultProps = {
  initialSeconds: 59
}

const mapStateToProps = state => {
  return {
    companyDetails: cardsHelpers.getBankingCompanyDetails(state)
  }
}

export default connect(mapStateToProps, null)(CardDetailsModal);
