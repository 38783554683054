import {Space, Tag} from 'antd';
import styled from 'styled-components';
import Card from '../../../../Card';

const StyledInvoiceDetails = styled(Space)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  
  & > .ant-space-item {
    width: 100%;
  }
`;

const StyledCard = styled(Card)`
  margin-top: 0;
  &.body-center {
    .ant-card-body {
      text-align: center;
    }
  }
  .ant-card-body {
    position: relative;
    text-align: unset;

    button:not(.ant-btn-icon-only) {
      color: ${props => props.theme.colors.secondary_6};

      &:hover {
        color: ${props => props.theme.colors.secondary_6};
      }
    }
  }
`;

const StyledInvoiceDetailsHeaderSpace = styled(Space)`
  display: flex;
  justify-content: space-between;
  
  .date {
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 300;
  }
  
  .vendor-details {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 16px;
  }
  
  .price {
    font-size: 20px;
    font-weight: 600;
  }
`;

const StyledTag = styled(Tag)`
  &.manually {
    background: linear-gradient(0deg, #F6FFED, #F6FFED), linear-gradient(0deg, #B7EB8F, #B7EB8F);
    border: 1px solid #B7EB8F;
    color: ${props => props.theme.colors.success};
  }
  &.automatically {
    background: linear-gradient(0deg, #F0F4FF, #F0F4FF), linear-gradient(0deg, #A3B1FF, #A3B1FF);
    border: 1px solid ${props => props.theme.colors.primary_4};
    color: ${props => props.theme.colors.primary_6};
  }
`;

const StyledAttachmentPreview = styled.div`
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 16px;
  
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > div {
      max-height: 250px;
      overflow: auto;
    }
  }
  
  &:hover {
    cursor: pointer;
  }
`;


export {
  StyledInvoiceDetails,
  StyledInvoiceDetailsHeaderSpace,
  StyledCard,
  StyledTag,
  StyledAttachmentPreview
};
