import styled from 'styled-components';
import {InputNumber, Switch} from 'antd';
import Card from '../../../../../Card';

const StyledPaymentDetails = styled.div`
  .ant-alert {
    align-items: baseline;
  }
`;


const StyledCard = styled(Card)`

  .card-details {
    margin-bottom: 24px;
  }

  .ant-card-actions {
    justify-content: end;
    padding: 16px 24px;

    li {
      margin: 0;
      width: unset !important;
      &:not(:last-child) {
        border-inline-end: none;
        margin-right: 16px;
      }
    }
  }
`;

const StyledInput = styled(InputNumber)`
  width: 100%;
  
  input.ant-input-number-input {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .ant-input-number-group-addon {
    background-color: transparent;
    svg {
      width: 16px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: ${props => props.theme.colors.secondary_6};
    &:hover:not(.ant-switch-disabled) {
      background: ${props => props.theme.colors.secondary_5};
    }
  }
`;


export {
  StyledCard,
  StyledInput,
  StyledPaymentDetails,
  StyledSwitch
}
