import styled from 'styled-components';
import CopyField from '../../../CopyField';

const StyledInvoiceForward = styled.div`
  color: ${props => props.theme.colors.neutral_8};
  background-color: ${props => props.theme.colors.neutral_4};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  padding: 24px 48px;
  p {
    margin: 0 16px 0 0;
  }
`;

const StyledCopyField = styled(CopyField)`
  background-color: ${props => props.theme.colors.neutral_2};
  border-radius: ${props => props.theme.border_radius.standard};
  max-width: 505px;
  width: 50%;
`;

export {
  StyledInvoiceForward,
  StyledCopyField
};
