import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Space, Tooltip} from 'antd';
import {Collapse, UnmountClosed} from 'react-collapse';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  StyledHeaderDetails,
  StyledHeaderDetailsInfo,
  StyledHeaderDetailsMoreInfo,
  StyledHeaderDetailsMoreInfoSpace,
  StyledHeaderDetailsTag,
  StyledHeaderDetailsOverlappingAppsTooltip
} from './StyledHeaderDetails';
import SubscriptionInfo from '../SubscriptionInfo';
import {subscriptionActionConstants, subscriptionStatusesConstants} from '../../../../constants';
import {ChevronDownIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {serviceStatsActions} from '../../../../state/actions';
import {subscriptionsHelpers} from '../../SubscriptionsPage/subscriptionsHelpers';

const {ACTIVE, AUDITED, DELETED, INACTIVE, RESTRICTED, TERMINATED,
  TRIAL, UNMANAGED} = subscriptionStatusesConstants;

const {ACTIVATE_DELETED, ACTIVATE_PAUSED, AUTHORIZE, DELETE, PAUSE} = subscriptionActionConstants;

const detailsMoreInfoSpaceProps = {
  direction: 'vertical',
  size: 4
}

const gObjProp = helpers.getObjProp;

const infoCircleIcon = <InfoCircleOutlined size={13} />;

const HeaderDetails = ({
  data,
  onAction,
  getRecommendedServices,
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isExpandedDetails, setIsExpandedDetails] = useState(false);
  const [recommendedServices, setRecommendedServices] = useState({loading: false, data: []});

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const {
    isAuthorized,
    isCanceled,
    isDisabled,
    enableDelete,
    enablePause,
  } = useMemo(() => {
    const statusCode = data?.status || undefined;
    const checkStatus = (value) => typeof value === 'object' ? value.includes(statusCode) : statusCode === value;
    const isCanceled = checkStatus([DELETED, TERMINATED]);
    const isAuthorized = checkStatus([AUDITED, RESTRICTED, UNMANAGED]);
    return {
      isAuthorized,
      isCanceled: checkStatus([DELETED, TERMINATED]),
      isDisabled: checkStatus(INACTIVE),
      isDeleted: checkStatus(DELETED),
      enableDelete: !isCanceled && isAuthorized,
      enablePause: checkStatus([ACTIVE, TRIAL]),
    }
  }, [data]);

  const {
    description,
    shortDescription,
    tags
  } = useMemo(() => {
    const service = gObjProp(data, 'service');
    const gServiceProp = (key) => gObjProp(service, key);
    const description = gServiceProp('description') || '-';
    let shortDescription = description.split(' ').slice(0, 9).join(' ');
    if (description !== shortDescription) shortDescription = `${shortDescription}...`;
    return {
      description,
      shortDescription,
      tags: gServiceProp('tags') || []
    }
  }, [data]);

  const {isExistOverlappingApps, overlappingApps} = useMemo(() => {
    const services = recommendedServices.data;
    return {
      isExistOverlappingApps: recommendedServices.loading ? false : Boolean(services.length),
      overlappingApps: services
    }
  }, [recommendedServices]);

  useEffect(() => {
    const serviceName = subscriptionsHelpers.getServiceName(data);

    if (!serviceName) return;

    setRecommendedServices({...recommendedServices, loading: false});

    const successHandle = (data) => {
      setRecommendedServices({
        ...recommendedServices,
        loading: false,
        data
      });
    }
    const errorHandle = () => successHandle([]);

    getRecommendedServices(
      serviceName,
      successHandle,
      errorHandle
    );
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAction = (action) => onAction && onAction({action});

  const overTitle = (
    <StyledHeaderDetailsOverlappingAppsTooltip>
      <Space size='small' direction='vertical'>
        <p>
          {trans('tooltips.overlappingAppsAlertTitle')}
        </p>
        <div className='tooltip-list'>
          <div className='tooltip-list-title'>{trans('overlappingApps')}</div>
          <div className='tooltip-list-services'>
            {subscriptionsHelpers.getTooltipServices(overlappingApps)}
          </div>
        </div>
      </Space>
    </StyledHeaderDetailsOverlappingAppsTooltip>
  )

  return (
    <StyledHeaderDetails>
      <StyledHeaderDetailsInfo>
        <SubscriptionInfo data={data} />
        <Space size='middle'>
          {/* Display overlapping apps alert*/}
          {isExistOverlappingApps && (
            <Tooltip
              overlayInnerStyle={{width: 280}}
              placement='bottomLeft'
              title={overTitle}
            >
              <Button
                danger
                ghost
                className='overlapping-alert'
                size='large'
                type='primary'
              >
                <Space size='small' align='center'>
                  <span>{trans('overlappingApps')}</span>
                  {infoCircleIcon}
                </Space>
              </Button>
            </Tooltip>
          )}

          {/* Enable "Pause" if status is ACTIVE or TRIAL */}
          {enablePause && (
            <Button
              onClick={() => handleAction(PAUSE)}
              size='large'
              type='primary'
            >
              {t('pause')}
            </Button>
          )}
          {/* Enable "Activate" if status is DISABLED */}
          {(isDisabled || isCanceled) && (
            <Button
              onClick={() => handleAction(isDisabled ? ACTIVATE_PAUSED : ACTIVATE_DELETED)}
              size='large'
              type='primary'
            >
              {t('activate')}
            </Button>
          )}
          {/* Enable "Authorize" if status is AUDITED/RESTRICTED/UNMANAGED */}
          {isAuthorized && (
            <Button
              onClick={() => handleAction(AUTHORIZE)}
              size='large'
              type='primary'
            >
              {trans('authorize')}
            </Button>
          )}
          {/* Enable "Delete" if subscription is not canceled and AUDITED/RESTRICTED/UNMANAGED */}
          {enableDelete && (
            <Button
              danger
              onClick={() => handleAction(DELETE)}
              size='large'
              type='primary'
            >
              {t('delete')}
            </Button>
          )}
        </Space>
      </StyledHeaderDetailsInfo>
      <StyledHeaderDetailsMoreInfo>
        <Space size='middle' align='start'>
          <StyledHeaderDetailsMoreInfoSpace {...detailsMoreInfoSpaceProps}>
            <label>{t('description')}</label>
            {shortDescription !== description ? (
              <>
                <Collapse isOpened={isExpandedDetails}>
                  <p>{description}</p>
                </Collapse>
                <Collapse isOpened={!isExpandedDetails}>
                  <p>{shortDescription}</p>
                </Collapse>
              </>
            ) : (
              <p>{description}</p>
            )}
          </StyledHeaderDetailsMoreInfoSpace>
          <Button type='link' onClick={() => setIsExpandedDetails(!isExpandedDetails)}>
            <Space size='small' align='center'>
              <span>{t('showMoreDetails')}</span>
              <ChevronDownIcon
                className={`expanded-icon ${isExpandedDetails ? 'expanded' : ''}`}
              />
            </Space>
          </Button>
        </Space>

        <UnmountClosed className='expanded-details' isOpened={isExpandedDetails}>
          <StyledHeaderDetailsMoreInfoSpace {...detailsMoreInfoSpaceProps}>
            <label>{t('features')}</label>
            <Space size='small' wrap={true}>
              {tags.length ? tags.map((tag, key) => (
                <StyledHeaderDetailsTag key={key}>
                  {tag}
                </StyledHeaderDetailsTag>
              )) : '-'}
            </Space>
          </StyledHeaderDetailsMoreInfoSpace>
          {isExistOverlappingApps && (
            <StyledHeaderDetailsMoreInfoSpace
              align='center'
              size='small'
            >
              <label>
                {trans('overlappingApps')}
                <Tooltip title={trans('tooltips.overlappingApps')}>
                  {infoCircleIcon}
                </Tooltip>
              </label>
              {subscriptionsHelpers.getServicesAvatarGroup(overlappingApps)}
            </StyledHeaderDetailsMoreInfoSpace>
          )}
        </UnmountClosed>
      </StyledHeaderDetailsMoreInfo>
    </StyledHeaderDetails>
  );
}

HeaderDetails.propTypes = {
  data: PropTypes.object,
  onAction: PropTypes.func,
}

const mapStateToProps = state => {
  const {country, currentCurrency} = state.company;
  return {
    companyCountry: country,
    currentCurrency
  }
}

const mapDispatchToProps = {
  getRecommendedServices: serviceStatsActions.getRecommendedServices
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDetails);
