import styled from 'styled-components';
import {Space} from 'antd';

const StyledActivateAccount = styled(Space)`
  position: relative;
  width: 100%;
  max-width: 550px;
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .ant-alert {
    align-items: baseline;
  }
  
  .warning-alert {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
  }
`;

const StyledActivateAccountCopyFieldsContainer = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
`;

export {
  StyledActivateAccount,
  StyledActivateAccountCopyFieldsContainer
};
