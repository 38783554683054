const APP_LAUNCH_DATE = '01.2021';
const TERM_OF_USE_URL = 'https://www.h1card.com/terms-of-service';
const PRIVACY_POLICY_URL = 'https://www.h1card.com/privacy-policy';
const COOKIE_POLICY_URL = 'https://www.h1card.com/cookie-policy';

const TRZ_TERMS = {
  'FR': 'https://www.treezor.com/fr/cgu-conditions-generale-dutilisation/',
  'IT': 'https://www.treezor.com/it/termini-e-condizioni-generali-di-utilizzo/',
  'default': 'https://www.treezor.com/general-terms-conditions-of-use/'
}

const KEYLESS_APP_URL = {
  APPLE_STORE: 'https://apps.apple.com/in/app/keyless-authenticator/id1494269968',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=io.keyless.push'
}

export {
  APP_LAUNCH_DATE,
  COOKIE_POLICY_URL,
  KEYLESS_APP_URL,
  PRIVACY_POLICY_URL,
  TERM_OF_USE_URL,
  TRZ_TERMS,
};
