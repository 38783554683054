import styled from 'styled-components';
import {Radio, Space} from 'antd'

const StyledRadioGroupIcon = styled(Radio.Group)`
  width: 100%;
`;

const StyledRadioSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    width: 100%;
  }

  label {
    align-items: center;
    border: 1px solid ${props => props.theme.colors.neutral_4};
    border-radius: ${props => props.theme.border_radius.standard};
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    margin-inline-end: 0;
    transition: .2s ease;

    .ant-space-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .icon {
      svg path {
        fill: ${props => props.theme.colors.neutral_6};
        transition: .2s ease;
      }
    }

    &.ant-radio-wrapper-checked {
      border-color: ${props => props.theme.colors.primary_6};
      color: ${props => props.theme.colors.primary_6};
      font-weight: 500;

      .icon {
        svg path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }

    &:after {
      content: none;
    }

    > span {
      &:not(.ant-radio) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      &.ant-radio {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
`;


export {
  StyledRadioGroupIcon,
  StyledRadioSpace
};
