import React from 'react';

const TeamsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33317 4.33337C3.33317 2.67652 4.67631 1.33337 6.33317 1.33337C7.99003 1.33337 9.33317 2.67652 9.33317 4.33337C9.33317 5.99023 7.99003 7.33337 6.33317 7.33337C4.67631 7.33337 3.33317 5.99023 3.33317 4.33337Z"
      fill="#262626"/>
    <path
      d="M11.2132 1.7623C10.8978 1.57227 10.4881 1.67387 10.2981 1.98924C10.108 2.3046 10.2096 2.7143 10.525 2.90432C11.0107 3.19702 11.3332 3.72784 11.3332 4.33331C11.3332 4.93878 11.0107 5.4696 10.525 5.7623C10.2096 5.95232 10.108 6.36202 10.2981 6.67739C10.4881 6.99275 10.8978 7.09435 11.2132 6.90433C12.0826 6.38042 12.6665 5.42537 12.6665 4.33331C12.6665 3.24125 12.0826 2.2862 11.2132 1.7623Z"
      fill="#262626"/>
    <path
      d="M7.09504 8.66671H5.5713C4.84879 8.6667 4.26601 8.6667 3.7941 8.70525C3.3082 8.74495 2.88139 8.82883 2.48653 9.03001C1.85932 9.34959 1.34939 9.85955 1.02982 10.4868C0.828625 10.8816 0.74475 11.3084 0.70505 11.7943C0.666493 12.2662 0.666498 12.849 0.666504 13.5715V14C0.666504 14.3682 0.964982 14.6667 1.33317 14.6667H11.3332C11.7014 14.6667 11.9998 14.3682 11.9998 14V13.5715C11.9998 12.849 11.9998 12.2662 11.9613 11.7943C11.9216 11.3084 11.8377 10.8816 11.6365 10.4867C11.317 9.85954 10.807 9.34958 10.1798 9.03C9.78493 8.82882 9.35814 8.74495 8.87224 8.70525C8.40033 8.6667 7.81756 8.6667 7.09504 8.66671Z"
      fill="#262626"/>
    <path
      d="M13.5131 9.03004C13.185 8.86288 12.7836 8.99332 12.6164 9.32138C12.4493 9.64944 12.5797 10.0509 12.9078 10.218C13.2841 10.4098 13.5901 10.7158 13.7818 11.0921C13.8713 11.2677 13.9327 11.4995 13.9657 11.9029C13.9993 12.3141 13.9998 12.8423 13.9998 13.6001V14.0001C13.9998 14.3683 14.2983 14.6667 14.6665 14.6667C15.0347 14.6667 15.3331 14.3683 15.3331 14.0001V13.5716C15.3331 12.849 15.3331 12.2663 15.2946 11.7943C15.2549 11.3084 15.171 10.8816 14.9698 10.4868C14.6502 9.85957 14.1403 9.34962 13.5131 9.03004Z"
      fill="#262626"/>
  </svg>
);

export default TeamsIcon;
