import styled from 'styled-components';
import {Empty} from 'antd';

const StyledNotifications = styled.div`
  border-radius: ${props => props.theme.border_radius.standard};
  width: 392px;

  > header {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
    padding: 16px 20px 16px 24px;
    button {
      color: ${props => props.theme.colors.neutral_7};
      font-weight: 500;
      padding: 4px;
    }
  }
`;

const StyledNotification = styled.div`
  display: flex;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
  }

  .ant-avatar {
    min-width: 32px;
  }

  .ant-badge-dot {
    height: 8px;
    min-height: 8px;
    min-width: 8px;
    width: 8px;
    transform: translate(75%, -75%);
  }

  .description {
    line-height: 20px;
    margin-left: 12px;
  }

  .date {
    color: #A5ACB8;
    display: inline-block;
    font-weight: 500;
    margin-top: 8px;
  }
`;

const StyledNotificationContent = styled.div`
  border: 2px solid transparent;
  padding: 10px 10px 6px 6px;
  transition: .3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.primary_2};
  }
  &:active,&:focus {
    border: 2px solid ${props => props.theme.colors.primary_5};
  }
`;

const StyledNotificationsList = styled.div`
  max-height: 400px;
  overflow-y: auto;

`;

const StyledNotificationEmpty = styled(Empty)`
  padding: 24px 0;
`;

export {
  StyledNotification,
  StyledNotificationContent,
  StyledNotifications,
  StyledNotificationEmpty,
  StyledNotificationsList
};
