import styled from 'styled-components';

const StyledTotalCounter = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 16px 24px;
  display: flex;
  align-items: self-start;
  justify-content: space-between;

`;

const TotalDescription = styled.div`
  .title {
    color: ${props => props.theme.colors.neutral_6};
  }

  .value {
    font-size: 20px;
    font-weight: 600;
  }

  .description {
    color: ${props => props.theme.colors.neutral_8};
    margin-top: 8px;
  }

  .trend {
    &.success {
      color: ${props => props.theme.colors.success};
    }
    &.error {
      color: ${props => props.theme.colors.danger};
    }
  }
`;

const Extra = styled.div`
  position: relative;
`;

export {StyledTotalCounter, Extra, TotalDescription};
