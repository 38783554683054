import React from 'react';
import PropTypes from 'prop-types';
import SpinnerImage from '../../components/SpinnerImage';
import {StyledComponentLoading} from './StyledComponentLoading';


const ComponentLoading = ({image, ...rest}) => (
  <StyledComponentLoading {...rest}>
    <SpinnerImage size='small' image={image} />
  </StyledComponentLoading>
);


ComponentLoading.propTypes = {
  /** The image for loader */
  image: PropTypes.string,
};

export default ComponentLoading;
