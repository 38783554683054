import {serviceStatsRequest} from '../services/request';
import {serviceStatsEndpoints} from '../../api';
import {apiHelpers} from '../../api/apiHelpers';

const {getUrl} = apiHelpers;

const getSSCompany = (success, error) =>
  requestTemplate('get', serviceStatsEndpoints.GET_COMPANY, null, success, error);

const getServices = (success, error) =>
  requestTemplate('get', serviceStatsEndpoints.GET_SERVICES, null, success, error);

const getServiceUsers = (serviceName, query, success, error) =>
  requestTemplate('get', getUrl(serviceStatsEndpoints.GET_SERVICE_USERS, {serviceName}), query, success, error);

const getServiceUsageReport = (serviceName, success, error) =>
  requestTemplate('get', getUrl(serviceStatsEndpoints.GET_SERVICE_USAGE_REPORTS, {serviceName}), null, success, error);

const getRecommendedServices = (domain, success, error) => {
  return requestTemplate(
    'get',
    getUrl(serviceStatsEndpoints.GET_RECOMMENDED_SERVICES, {domain}),
    null,
    (data) => success && success(data.filter(d => d.is_overlapping)),
    error
  );
}

const getSuggestServices = (query, success, error) =>
  requestTemplate('get', serviceStatsEndpoints.GET_SUGGEST_SERVICES, query, success, error);

const generateAuthUrl = (service, success, error) =>
  requestTemplate('post', getUrl(serviceStatsEndpoints.POST_GENERATE_AUTH_URL, {service}), null, success, error);

const verifyOauth = (service, data, success, error) =>
  requestTemplate('post', getUrl(serviceStatsEndpoints.POST_VERIFY_AUTH, {service}), data, success, error);

const revokeOauth = (id, service, success, error) =>
  requestTemplate('post', getUrl(serviceStatsEndpoints.POST_REVOKE_AUTH, {id, service}), null, success, error);

const requestTemplate = (method, url, data, success, error) => {
  return (dispatch) => {
    serviceStatsRequest[method]({
      dispatch,
      url,
      [method === 'get' ? 'query' : 'data']: data,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps: {disableAlert: true}
    });
  }
}


export const serviceStatsActions = {
  getSSCompany,
  getServices,
  getServiceUsers,
  getServiceUsageReport,
  getRecommendedServices,
  getSuggestServices,
  generateAuthUrl,
  revokeOauth,
  verifyOauth
};
