import React from 'react';
import PropTypes from 'prop-types';
import bgImg from '../../static/images/pages/custom-page-bg.png';
import logoImg from '../../static/images/H1_LogoBlue.png';
import {
  StyledVerifyPageContainer,
  StyledVerifyPageContainerContent,
} from './StyledVerifyPageContainer';

const VerifyPageContainer = ({children, ...rest}) => {
  return (
    <StyledVerifyPageContainer bgImg={bgImg} {...rest}>
      <StyledVerifyPageContainerContent>
        <a href='/'>
          <img src={logoImg} alt='H1 logo' />
        </a>
        {children}
      </StyledVerifyPageContainerContent>
    </StyledVerifyPageContainer>
  )
};

VerifyPageContainer.propTypes = {
  className: PropTypes.string
}

export default VerifyPageContainer;
