import styled from 'styled-components';
import Card from '../../../../Card';

const StyledInvoiceDetailsForm = styled.div`
  display: flex;
  flex-direction: column;
  
  .ant-picker {
    width: 100%;
  }
`;

const StyledInvoiceDetailsFormCard = styled(Card)`
  margin-top: 16px;

  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 32px;
      width: 32px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }

    .card-number {
      margin-left: 12px;
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    > svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }

    .ant-avatar {
      margin-right: 8px;
    }

    .ant-select-selection-item-content {
      font-size: 14px;
    }
  }
`;

export {
  StyledInvoiceDetailsFormCard,
  StyledInvoiceDetailsForm
};
