import React from 'react';
import {StyledTeamsTable} from './StyledTeamsTable';

const TeamsTable = ({
  ...rest
}) => {

  return (
    <StyledTeamsTable
      rowKey='email'
      {...rest}
    />
  );
}

export default TeamsTable;
