import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import {StyledPageContainer, StyledFormContainer, StyledImageContainer, StyledFormContent, StyledFormContainerChild, StyledFadeElement} from './StyledPageContainer';
import {ReactComponent as LogoSvgImage} from '../../../../static/images/auth/h1-logo.svg';

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <StyledFadeElement state={state}>{children}</StyledFadeElement>}
  </Transition>
);

const PageContainer = ({formContent, imageContent, ...rest}) => {

  return (
    <StyledPageContainer {...rest}>
      <StyledFormContainer className='page-form-content-container'>
        <StyledFormContainerChild>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to='/' className='main-page-link'>
            <LogoSvgImage />
          </Link>
          <StyledFormContent className='form-content-container'>
            {formContent}
          </StyledFormContent>
        </StyledFormContainerChild>
      </StyledFormContainer>
      <StyledImageContainer className='image-content-container'>
        {imageContent}
      </StyledImageContainer>
    </StyledPageContainer>
  );
}

PageContainer.propTypes = {
  formContent: PropTypes.any,
  imageContent: PropTypes.any,
};

export {
  PageContainer,
  FadeTransition
};
