import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Avatar, Space} from 'antd';
import {helpers} from '../../../../../../helpers';
import {StyledTableContainer, StyledUsersTable} from './StyledUsersTable';
import Usage from '../../../Usage';

const UsersTable = ({data, onOrderChange, ...rest}) => {
  const [t] = useTranslation(['main', 'subscriptions']);

  const trans = (key) => t(`subscriptions:${key}`);

  const handleTableChange = (pagination, filters, sorter) => {
    const {columnKey, order} = sorter;
    onOrderChange && onOrderChange(columnKey, order ? order === 'ascend' ? 'asc' : 'desc' : undefined);
  }

  const columns = [
    {
      key: 'user_id',
      dataIndex: 'user_id',
      title: t('user'),
      render: (_, record) => {
        const logo = record.logo;
        const fullName = record.full_name || record.email;
        return (
          <Space>
            <Avatar src={logo}>
              {helpers.getInitials(fullName)}
            </Avatar>
            {fullName}
          </Space>
        )
      }
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('role'),
    },
    {
      key: 'usage',
      dataIndex: 'usage',
      title: trans('usage'),
      render: (usage) => <Usage variant={usage ? usage.toLowerCase() : undefined} />
    },
    {
      key: 'last_login',
      dataIndex: 'last_login',
      title: trans('lastLogIn'),
      render: (date) => helpers.getDateWithGMTFormat(date, 'D MMM YYYY'),
      sorter: true
    }
  ];

  return (
    <StyledTableContainer {...rest}>
      <StyledUsersTable
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={handleTableChange}
        rowKey='user_id'
      />
    </StyledTableContainer>
  );
}

UsersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      full_name: PropTypes.string,
      last_login: PropTypes.string,
      logo: PropTypes.string,
      usage: PropTypes.string.isRequired
    })
  ),
  onOrderChange: PropTypes.func
}

UsersTable.defaultProps = {
  data: []
}

export default UsersTable;

