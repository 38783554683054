import styled from 'styled-components';
import {Space, Tag} from "antd";

const StyledHeaderDetails = styled.div`
  padding: 12px 0;
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  border-top: none;
  border-top-left-radius: 0;
  background: linear-gradient(0deg, ${props => props.theme.colors.neutral_1}, ${props => props.theme.colors.neutral_1}),
              linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
  margin-bottom: 24px;
  
  & > div {
    padding: 12px 24px;
  }
`;

const StyledHeaderDetailsInfo = styled.div`
  align-items: self-start;
  background: ${props => props.theme.colors.neutral_1};
  display: flex;
  justify-content: space-between;
  
  .ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost.overlapping-alert {
    background: #fff2f0;
    &:hover:not(:disabled):not(.ant-disabled),
    &:active:not(:disabled):not(.ant-disabled),
    &:focus:not(:disabled):not(.ant-disabled) {
      background: #fff2f0;
      border-color: ${props => props.theme.colors.danger};
      color: ${props => props.theme.colors.danger};
    }
  }
`;

const StyledHeaderDetailsMoreInfo = styled.div`
  border-top: 1px solid ${props => props.theme.colors.neutral_4};
  
  .ant-btn {
    color: ${props => props.theme.colors.primary_6};
    margin-top: 20px;
    
    &:hover:not(:disabled):not(.ant-disabled),
    &:active:not(:disabled):not(.ant-disabled),
    &:focus:not(:disabled):not(.ant-disabled) {
      color: ${props => props.theme.colors.primary_5};
      
      .expanded-icon {
        path {
          fill: ${props => props.theme.colors.primary_5};
          stroke: ${props => props.theme.colors.primary_5};
        }
      }
    }
  }
  
  .expanded-icon {
    display: flex;
    height: 24px;
    width: 24px;
    transition: .3s;
    
    path {
      fill: ${props => props.theme.colors.primary_6};
      stroke: ${props => props.theme.colors.primary_6};
    }
    
    &.expanded {
      transform: rotate(180deg);
    }
  }
  
  & > .ant-space {
    width: 100%;
    &:nth-of-type(1) {
      & > div {
        &:nth-of-type(1) {
          min-width: calc(50% - 16px - 90px);
        }
      }
    }
  }
  
  .ReactCollapse--content {
    display: flex;
    flex-direction: column;
    & > div {
      margin-top: 8px;
    }
  }
`;

const StyledHeaderDetailsMoreInfoSpace = styled(Space)`
  label {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 16px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
    }
  }
  p {
    color: ${props => props.theme.colors.neutral_10};
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
`;

const StyledHeaderDetailsTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 8px;
  margin: 0 0 4px 0;
`;

const StyledHeaderDetailsOverlappingAppsTooltip = styled.div`
  p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
  }
  .tooltip-list {
    border: 1px solid ${props => props.theme.colors.neutral_9};
    border-radius: ${props => props.theme.border_radius.standard};
    font-size: 12px;
  }
  .tooltip-list-title {
    background-color: ${props => props.theme.colors.neutral_9};
    border-top-left-radius: ${props => props.theme.border_radius.standard};
    border-top-right-radius: ${props => props.theme.border_radius.standard};
    font-weight: 500;
    padding: 8px;
  }
  .tooltip-list-services {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px 4px;
    max-height: 500px;
    overflow: auto;
    & > * {
      &:not(:last-of-type) {
        margin-bottom: 2px;
      }
    }
  }
`


export {
  StyledHeaderDetails,
  StyledHeaderDetailsInfo,
  StyledHeaderDetailsMoreInfo,
  StyledHeaderDetailsMoreInfoSpace,
  StyledHeaderDetailsTag,
  StyledHeaderDetailsOverlappingAppsTooltip
}
