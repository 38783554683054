import styled from 'styled-components';
import {Table} from 'antd';

const StyledInvoicesTable = styled(Table)`
  .ant-table {
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-radius: ${props => props.theme.border_radius.standard};
    
    .ant-table-tbody {
      tr {
        &:last-of-type {
          td {
            border-bottom: unset;
          }
        }
      }
    }
  }

  .ant-table-container {
    overflow-x: auto;
  }

  tbody {
    tr {
      &:not(.grouped-row) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  thead {
    &.ant-table-thead tr {
      & > th {
        color: ${props => props.theme.colors.neutral_7};
        font-weight: 500;
      }
      & > th, & > td {
        &:before {
          content: none !important;
        }
      }
    }
    th {
      &:last-of-type {
        &:before {
          content: none !important;
        }
      }
    }
  }
`;


export {
  StyledInvoicesTable
}
