import styled from 'styled-components';
import {Tag} from 'antd';
import Card from '../../../../../Card';

const StyledSubscriptionDetails = styled(Card)`

  .edit-btn {
    color: ${props => props.theme.colors.secondary_7};
    padding: 4px 12px;
  }

  .ant-space-item {
    display: flex;
  }
`;

const StyledTag = styled(Tag)`
  font-size: 14px;
  padding: 5px 8px;
`;

export {
  StyledSubscriptionDetails,
  StyledTag
}
