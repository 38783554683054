import React from 'react';

const TransactionAddIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1526_10615)">
      <path
        d="M13.3333 10.7665H21.3333C21.687 10.7665 22.0261 10.6243 22.2761 10.3711C22.5262 10.1179 22.6667 9.77455 22.6667 9.4165C22.6667 9.05846 22.5262 8.71508 22.2761 8.46191C22.0261 8.20874 21.687 8.0665 21.3333 8.0665H13.3333C12.9797 8.0665 12.6406 8.20874 12.3905 8.46191C12.1405 8.71508 12 9.05846 12 9.4165C12 9.77455 12.1405 10.1179 12.3905 10.3711C12.6406 10.6243 12.9797 10.7665 13.3333 10.7665ZM10.6667 16.1665H13.3333C13.687 16.1665 14.0261 16.0243 14.2761 15.7711C14.5262 15.5179 14.6667 15.1745 14.6667 14.8165C14.6667 14.4585 14.5262 14.1151 14.2761 13.8619C14.0261 13.6087 13.687 13.4665 13.3333 13.4665H10.6667C10.313 13.4665 9.97391 13.6087 9.72386 13.8619C9.47381 14.1151 9.33333 14.4585 9.33333 14.8165C9.33333 15.1745 9.47381 15.5179 9.72386 15.7711C9.97391 16.0243 10.313 16.1665 10.6667 16.1665ZM10.6667 21.5665C11.0203 21.5665 11.3594 21.4243 11.6095 21.1711C11.8595 20.9179 12 20.5745 12 20.2165C12 19.8585 11.8595 19.5151 11.6095 19.2619C11.3594 19.0087 11.0203 18.8665 10.6667 18.8665C10.313 18.8665 9.97391 19.0087 9.72386 19.2619C9.47381 19.5151 9.33333 19.8585 9.33333 20.2165C9.33333 20.5745 9.47381 20.9179 9.72386 21.1711C9.97391 21.4243 10.313 21.5665 10.6667 21.5665Z"
        fill="#BFBFBF"/>
      <path
        d="M26.6667 2.6665H5.33333C4.97971 2.6665 4.64057 2.80874 4.39052 3.06191C4.14048 3.31508 4 3.65846 4 4.0165V28.3165C4 28.5672 4.06895 28.813 4.19913 29.0262C4.32931 29.2395 4.51557 29.4119 4.73705 29.524C4.95852 29.6361 5.20646 29.6836 5.45307 29.661C5.69969 29.6385 5.93524 29.5469 6.13333 29.3965L8.89333 27.304L10.4419 28.3355C11.2962 28.9046 12.44 28.2922 12.44 27.2657C12.44 26.8645 12.2527 26.4863 11.9335 26.2432L9.69333 24.5365C9.46254 24.3612 9.18183 24.2665 8.89333 24.2665C8.60484 24.2665 8.32413 24.3612 8.09333 24.5365L6.66667 25.6165V5.3665H25.3333V10.6903C25.3333 11.4267 25.9303 12.0236 26.6667 12.0236C27.403 12.0236 28 11.4267 28 10.6903V4.0165C28 3.65846 27.8595 3.31508 27.6095 3.06191C27.3594 2.80874 27.0203 2.6665 26.6667 2.6665Z"
        fill="#BFBFBF"/>
      <rect x="13.3335" y="13.3335" width="18.6667" height="18.6667" rx="9.33333" fill="#00C887"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.6891 17.0669C23.0757 17.0674 23.3886 17.3812 23.3881 17.7678L23.3826 21.9669H27.5669C27.9535 21.9669 28.2669 22.2803 28.2669 22.6669C28.2669 23.0535 27.9535 23.3669 27.5669 23.3669H23.3808L23.3753 27.5678C23.3748 27.9544 23.061 28.2674 22.6744 28.2669C22.2878 28.2664 21.9748 27.9526 21.9753 27.566L21.9808 23.3669H17.7669C17.3803 23.3669 17.0669 23.0535 17.0669 22.6669C17.0669 22.2803 17.3803 21.9669 17.7669 21.9669H21.9826L21.9881 17.766C21.9886 17.3794 22.3025 17.0664 22.6891 17.0669Z"
            fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1526_10615">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default TransactionAddIcon;
