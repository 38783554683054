import {BrowserRouter as Router} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Toaster} from 'react-hot-toast';
import {Transition} from 'react-transition-group';
import {LastLocationProvider} from 'react-router-dom-last-location';
import {FolderAddFilled} from '@ant-design/icons';
import './App.css';
import {history} from '../state/history';
import Navigation from '../components/Navigation';
import {cardActions, companyActions, mainActions, userActions} from '../state/actions';
import SpinnerImage from '../components/SpinnerImage';
import ScreenExtensionBlockerWindow from '../components/ScreenExtensionBlockerWindow';
import WelcomeModal from '../components/quickStart/WelcomeModal';
import EmailVerificationModal from '../components/quickStart/EmailVerificationModal';
import KYCModal from '../components/quickStart/KYCModal';
import LightKYCModal from '../components/quickStart/LightKYCModal';
import CompanyInviteModal from '../components/quickStart/CompanyInviteModal';
import CompanyDisableModal from '../components/quickStart/CompanyDisableModal';
import useAvailableDataLoading from '../hooks/useAvailableDataLoading';
import useIsEmployeeLoaded from '../hooks/useIsEmployeeLoaded';
import useWindowExtensionBlock from '../hooks/useWindowExtensionBlock';
import {cardsHelpers} from '../components/pages/CardsPage/cardsHelpers';
import BalanceLoading from '../components/pages/TransactionsPage/BalanceLoading';
import {helpers} from '../helpers';
import {availableModulesConstants, KYCModalModeTypes, localStorageKeysConstants} from '../constants';
import firebaseAnalytics from '../snippets/firebase/analytics';
import Routes from '../routes/Routes';
import routesList from '../routes/routes.json';
import {HomeIcon, InvoiceIcon, TeamsIcon, TransactionOrderIcon} from '../icons';
import {StyledApp, StyledContentContainer, StyledNavContainer, StyledSpin} from './StyledApp';

let interval = null;
const navExpandWidth = 236;
const navCollapseWidth = 72;
const {SHOW_WELCOME, TOKEN} = localStorageKeysConstants;
const {TRANSACTIONS, SUBSCRIPTIONS} = availableModulesConstants;
const containerExpandWidth = `calc(100% - ${navExpandWidth}px)`;
const containerCollapseWidth = `calc(100% - ${navCollapseWidth}px)`;

const verifySuccessPath = routesList.verifyIdentity,
  userInvitePath = routesList.userInvite,
  oauthCallbackPath = routesList.oauthCallback;

const isAvailableSubscriptionsModule = helpers.checkIfAvailableFeature(SUBSCRIPTIONS);
const isAvailableTransactionsModule = helpers.checkIfAvailableFeature(TRANSACTIONS);

const duration = 300;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`
}

const navigationTransitionStyles = {
  entering: {width: navExpandWidth},
  entered: {width: navExpandWidth},
  exiting: {width: navCollapseWidth},
  exited: {width: navCollapseWidth}
}

const containerTransitionStyles = {
  entering: {width: containerCollapseWidth},
  entered: {width: containerCollapseWidth},
  exiting: {width: containerExpandWidth},
  exited: {width: containerExpandWidth}
}


const App = ({
  loading,
  getEmployeeProfile,
  getUserProfile,
  getCompanies,
  getNotificationList,
  isAuthenticated,
  initialLoading,
  emailVerificationToken,
  companyInviteToken,
  markedCheckedAuth,
  isAdmin,
  companyId,
  getCompanyCurrentCurrency,
  showWelcome,
  user,
  disableEmployeeFromCompany,
  disabledCompany,
  getUserCardList,
  employee,
  isRequiredCardVerification,
  getCompanyCardState,
  getUserAccessToCards,
  changeCompany,
  isOpenMenu,
  isEnabledBanking,
  openKycWindow
}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [startPathname, setStartPathname] = useState(null);
  const [isDisabledDataLoading, setIsDisabledDataLoading] = useState(false);
  const [availableModules, setAvailableModules] = useState([]);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showCompanyInvite, setShowCompanyInvite] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [companyDisableModalProps, setCompanyDisableModalProps] = useState({open: false, company: null});
  const [navigationItems, setNavigationItems] = useState([]);
  const isEmployeeLoaded = useIsEmployeeLoaded();
  const navigationNodeRef = useRef(null);
  const containerNodeRef = useRef(null);

  const { isBlocked: isBlockedScreen } = useWindowExtensionBlock(767);
  const [t] = useTranslation('main');
  const availableDataLoading = useAvailableDataLoading();

  const isDisabledModule = (name) => !availableModules.includes(name);

  useEffect(() => {
    const availableTransactions = isAvailableSubscriptionsModule && isAvailableTransactionsModule;
    const disabledTransactions = isEnabledBanking ? isDisabledModule(TRANSACTIONS) : true;
    const navItems = [
      {
        icon: <HomeIcon />,
        isAdmin: false,
        path: routesList.overview,
        title: 'overview'
      },
      {
        available: availableTransactions,
        disabled: disabledTransactions,
        icon: <TransactionOrderIcon />,
        isAdmin: true,
        path: routesList.transactionsList,
        title: 'transactions'
      },
      {
        available: isAvailableSubscriptionsModule,
        disabled: isDisabledModule(SUBSCRIPTIONS),
        icon: <FolderAddFilled />,
        isAdmin: false,
        path: routesList.subscriptionsList,
        title: 'subscriptions',
      },
      {
        available: availableTransactions,
        disabled: disabledTransactions,
        icon: <InvoiceIcon />,
        isAdmin: true,
        path: routesList.invoicesList,
        title: 'invoices'
      },
      {
        icon: <TeamsIcon />,
        isAdmin: true,
        title: 'team',
        path: routesList.team
      },
      // {title: 'deals', path: routesList.dealsList, icon: DollarWalletIcon}
    ];
    let items = isAdmin ? navItems : navItems.filter(i => i.isAdmin === false);
    // show only available features
    items = items
      .filter(i => i.available !== false)
      .map(i => ({
        disabled: i.disabled,
        icon: i.icon,
        key: i.title,
        label: t(`navigation.${i.title}`),
        path: i.path
      }));
    setNavigationItems(items);
  }, [isAdmin, t, isRequiredCardVerification, availableModules]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localStorage.getItem(TOKEN)) {
      const {pathname} = history.location;
      const hideLoader = !pathname.startsWith('/email/verification');
      const publicPaths = [oauthCallbackPath, verifySuccessPath, userInvitePath];
      if (publicPaths.map(p => pathname.startsWith(p)).includes(true)) {
        setIsDisabledDataLoading(true);
      } else {
        availableDataLoading && getEmployeeProfile();
        getUserProfile(hideLoader);
      }
    } else {
      markedCheckedAuth();
    }
  }, [getUserProfile, getEmployeeProfile, markedCheckedAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const {pathname} = history.location;
    if (isAuthenticated) {
      setIsAuth(true);
      if (localStorage.getItem(SHOW_WELCOME) === 'true') {
        setShowWelcomeModal(true);
      }
    } else {
      setIsAuth(false);
      clearNotificationInterval();
      if (isAuth && ![routesList.signUp, routesList.login, routesList.forgotPin].includes(pathname)) {
        history.push(routesList.overview);
        setStartPathname(pathname);
      }
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated) {
      firebaseAnalytics.setUserProperties({
        email: helpers.getObjProp(user, 'email'),
        name: helpers.getObjProp(user, 'full_name')
      });
      firebaseAnalytics.logEvent('user_authenticated');
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (showWelcome) setTimeout(() => setShowWelcomeModal(true), 500);
  }, [showWelcome]);

  useEffect(() => {
    if (isAuth) {
      getCompanies(
        (company) => disableEmployeeFromCompany(true, company),
        (data) => !Boolean(data.length) && setShowWelcomeModal(true)
      );
      if (startPathname) history.push(startPathname);
    }
  }, [getCompanies, isAuth, startPathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disabledCompany) {
      const {isDisabled, company} = disabledCompany;
      setCompanyDisableModalProps({...companyDisableModalProps, open: isDisabled, company});
    }
  }, [disabledCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAvailableSubscriptionsModule && !helpers.isEmptyObject(employee)) {
      const {id} = employee;
      const bankingModules = [SUBSCRIPTIONS];

      const checkUserAccess = () => getUserAccessToCards(id);
      getCompanyCardState(
        (companyState) => {
          const isEnabledBanking = cardsHelpers.checkIsAvailableBanking(companyState);
          if (isEnabledBanking) bankingModules.push(TRANSACTIONS);

          if (isAdmin) {
            // check if the admin has access to card (display light kyc window if the admin doesn't have the card user)
            setAvailableModules(bankingModules);
            isEnabledBanking && checkUserAccess();
          } else {
            // immediately hide card related menu items if they were visible in another active company
            if (availableModules.length) setAvailableModules([]);

            // check if the user has assigned him cards
            // display light kyc window if the user doesn't have the card user
            getCardList(
              id,
              (data) => {
                const isExistsCards = data.length > 0;
                if (isExistsCards) {
                  setAvailableModules(bankingModules);
                  checkUserAccess();
                }
              }
            );
          }
        },
        () => availableModules.length && setAvailableModules([])
      );
    }
  }, [employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCardList = (id, successFunc, errorFunc) => {
    getUserCardList(
      id,
      null,
      (data) => successFunc && successFunc(data),
      (data) => errorFunc && errorFunc(data)
    );
  }

  useEffect(() => {
    if (emailVerificationToken) setShowVerificationModal(true);
  }, [emailVerificationToken]);

  useEffect(() => {
    if (companyId) {
      getNotificationList();
      getCompanyCurrentCurrency();
      clearNotificationInterval();
      interval = setInterval(() => getNotificationList(), 60000);
    }
  }, [getNotificationList, getCompanyCurrentCurrency, companyId]);

  useEffect(() => {
    if (companyInviteToken) setShowCompanyInvite(true);
  }, [companyInviteToken]);

  const clearNotificationInterval = () => {
    if (interval) clearInterval(interval);
  }

  const handleOkCompanyCreationModal = (createdCompanyData) => {
    const {id: companyId, isNeedToChange} = createdCompanyData;
    if (isNeedToChange) changeCompany(companyId);
  }

  const handleCloseDisableModal = () => {
    setCompanyDisableModalProps({...companyDisableModalProps, open: false});
    disableEmployeeFromCompany();
  }

  const getStarted = () => {
    setShowWelcomeModal(false);
    openKycWindow({mode: KYCModalModeTypes.COMPANY});
  };

  const isEnablePath = () => {
    const paths = [routesList.invite, routesList.verification, verifySuccessPath, userInvitePath, oauthCallbackPath];
    let isEnable = false;

    paths.forEach(path => {
      if (history.location.pathname.startsWith(path)) {
        isEnable = true;
      }
    });
    return isEnable;
  }

  if (isBlockedScreen && !isEnablePath()) {
    let description = (
      <>
        {t('messages.screenExtensionBlockerDescription1')}<br/>
        {t('messages.screenExtensionBlockerDescription2')}<br/>
        {t('messages.screenExtensionBlockerDescription3')}
      </>
    );
    return (
      <ScreenExtensionBlockerWindow
        description={description}
        title={t('messages.screenExtensionBlockerTitle')}
      />
    );
  }

  const removeShowVerification = () => {
    setShowWelcomeModal(false);
    localStorage.removeItem(SHOW_WELCOME);
  }

  const mainLoading = !(isDisabledDataLoading ? true : !initialLoading && (isAuthenticated ? isEmployeeLoaded : true));

  const routes = <Routes isAuth={isAuth} availableModules={availableModules} />;

  return (
    <StyledApp className='App'>
      <StyledSpin
        className='white'
        indicator={<SpinnerImage />}
        spinning={mainLoading}
        wrapperClassName='full-width-spin'
      >
        <StyledSpin
          indicator={<SpinnerImage />}
          spinning={loading}
          wrapperClassName='full-width-spin'
        >
          <Router history={history}>
            <LastLocationProvider>

              {/* Routes for not auth user */}

              {!isAuth && routes}

              {/* View, Navigation & Routes for auth user */}

              {isAuth && (
                <div className='d-flex flex-row'>
                  <Transition nodeRef={navigationNodeRef} in={isOpenMenu} timeout={duration}>
                    {state => (
                      <StyledNavContainer
                        ref={navigationNodeRef}
                        style={{
                          ...defaultStyle,
                          ...navigationTransitionStyles[state]
                        }}
                      >
                        <Navigation
                          items={navigationItems}
                        />
                      </StyledNavContainer>
                    )}
                  </Transition>
                  <Transition nodeRef={containerNodeRef} in={!isOpenMenu} timeout={duration}>
                    {state => (
                      <StyledContentContainer
                        id='app-content-container'
                        ref={containerNodeRef}
                        style={{
                          ...defaultStyle,
                          ...containerTransitionStyles[state]
                        }}
                      >
                        {routes}
                      </StyledContentContainer>
                    )}
                  </Transition>

                  <Toaster position='bottom-right' />

                </div>
              )}

            </LastLocationProvider>
          </Router>
        </StyledSpin>
      </StyledSpin>

      {isAuth && (
        <>
          <WelcomeModal
            open={!showVerificationModal && isAuth && showWelcomeModal && !showCompanyInvite}
            handleOk={getStarted}
          />

          <KYCModal onOk={handleOkCompanyCreationModal} />

          <LightKYCModal />

          <EmailVerificationModal
            open={showVerificationModal}
            handleCancel={() => {
              setShowVerificationModal(false);
              removeShowVerification();
            }}
          />

          <CompanyInviteModal
            open={showCompanyInvite}
            handleClose={() => {
              setShowCompanyInvite(false);
              removeShowVerification();
            }}
          />

          <CompanyDisableModal
            {...companyDisableModalProps}
            handleClose={handleCloseDisableModal}
            handleOk={() => {
              handleCloseDisableModal();
              openKycWindow({closeIcon: false});
            }}
          />

          <BalanceLoading />

        </>
      )}
    </StyledApp>
  );
}

const mapStateToProps = state => {
  const {card, main, user, auth, company} = state;
  const {isAdmin, isAuth, disabledCompany, employee} = user;
  const {initialLoading, isOpenMenu, loading} = main;
  const {companyInviteToken, emailVerificationToken, showWelcome} = auth;
  const {isEnabledBanking} = card;
  const isRequiredCardVerification = cardsHelpers.checkIsRequiredVerification(card.verification);
  return {
    loading,
    initialLoading,
    isAuthenticated: isAuth,
    emailVerificationToken,
    companyInviteToken,
    isAdmin,
    companyId: company.id,
    showWelcome,
    user: user.user,
    disabledCompany,
    employee,
    isOpenMenu,
    // banking props
    isRequiredCardVerification,
    isEnabledBanking,
  }
}

const mapDispatchToProps = {
  getEmployeeProfile: userActions.getEmployeeProfile,
  getUserProfile: userActions.getUserProfile,
  getCompanies: userActions.getCompanies,
  markedCheckedAuth: userActions.markedCheckedAuth,
  getNotificationList: userActions.getNotificationList,
  getCompanyCurrentCurrency: companyActions.getCompanyCurrentCurrency,
  disableEmployeeFromCompany: userActions.disableEmployeeFromCompany,
  getUserCardList: cardActions.getUserCardList,
  getCompanyCardState: cardActions.getCompanyState,
  getUserAccessToCards: cardActions.getUserAccessToCards,
  changeCompany: userActions.changeCompany,
  openKycWindow: mainActions.openKycWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
