import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  StyledCompleteKycCard,
  StyledCompleteKycCardTitleSpace,
  StyledCompleteKycCardBadge
} from './StyledCompleteKycCard';
import {mainActions} from '../../../../state/actions';

const variants = {
  ADMIN_BANING: 'admin-banking',
  BANKING: 'banking',
  KYC: 'kyc'
}

const {ADMIN_BANING, BANKING, KYC} = variants;

const CompleteKycCard = ({
  bankingCompanyState,
  variant,
  openKycWindow,
  openLightKycWindow,
  ...rest
}) => {
  const [t] = useTranslation('quickStart');
  const [action, setAction] = useState('continue');

  const trans = (key) => t(`kycCompleteCard.${key}`);

  useEffect(() => {
    let newAction;
    if (variant === ADMIN_BANING) {
      newAction = 'adminBankingInfo';
    } else if (variant === BANKING) {
      newAction = 'bankingInfo';
    } else if (bankingCompanyState) {
      const {state, stage} = bankingCompanyState;
      if (stage === 'review_stage' && state === 'review_has_errors') {
        newAction = 'error';
      }
    }
    if (newAction && action !== newAction) setAction(newAction);
  }, [bankingCompanyState, variant]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCardProps = (action) => {
    return {
      buttonText: trans(`${action}.buttonText`),
      description: trans(`${action}.description`),
      title: trans(`${action}.title`)
    };
  }

  const {
    buttonText,
    description,
    title
  } = useMemo(() => getCardProps(action), [action]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClick = () => {
    const handles = {
      [ADMIN_BANING]: openLightKycWindow,
      [BANKING]: openLightKycWindow,
      [KYC]: () => openKycWindow({mode: 'kyc'})
    };
    if (handles.hasOwnProperty(variant)) handles[variant]();
  }

  return (
    <StyledCompleteKycCard
      title={(
        <StyledCompleteKycCardTitleSpace>
          {title}
          <StyledCompleteKycCardBadge action={action} />
        </StyledCompleteKycCardTitleSpace>
      )}
      {...rest}
    >
      <Space direction='vertical' size={48}>
        {description}
        <Button
          className={[ADMIN_BANING, BANKING].includes(variant) && 'green-btn'}
          onClick={handleOnClick}
          size='large'
          type='primary'
        >
          {buttonText}
        </Button>
      </Space>
    </StyledCompleteKycCard>
  );
}

CompleteKycCard.propTypes = {
  variant: PropTypes.oneOf([ADMIN_BANING, BANKING, KYC])
}

CompleteKycCard.defaultProps = {
  variant: KYC
}

const mapStateToProps = state => {
  const {companyState: bankingCompanyState} = state.card;

  return {
    bankingCompanyState
  }
}

const mapDispatchToProps = {
  openKycWindow: mainActions.openKycWindow,
  openLightKycWindow: mainActions.openLightKycWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteKycCard);
