import styled from 'styled-components';
import {Space} from 'antd';
import {StyledSpin} from '../../app/StyledApp';

const StyledOverviewPage = styled(Space)`
  width: 100%;
`;

const StyledOverviewPageContent = styled(Space)`
  padding: 0 24px 54px 24px;
  width: 100%;
  
  .complete-kyc-panel {
    width: calc(50% - 8px);
  }
`;

const StyledOverviewPageSpin = styled(StyledSpin)`
  min-height: calc(100vh - 66px - 24px);
`;

export {
  StyledOverviewPage,
  StyledOverviewPageContent,
  StyledOverviewPageSpin
}