import styled from 'styled-components';
import {Space, Tabs} from 'antd';

const CHILDREN_WIDTH = 'calc(61.25% + 12px)';

const StyledSettingPageContainer = styled.div`
  padding: 16px 24px 24px 24px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      content: none;
    }

    .ant-tabs-tab {
      font-weight: 500;
      padding: 9px 32px;

      &:not(.ant-tabs-tab-active) {
        background: unset;
        border-color: transparent;
      }
    }
  }
  .ant-tabs-tabpane {
    .ant-card {
      margin-top: 0;
    }
  }
`;

const StyledTabComponentContainer = styled(Space)`
  width: 100%;
  & > div {
    width: 100%;
    & > div {
      width: 100%;
    }
    &:nth-of-type(1) {
      min-width: ${CHILDREN_WIDTH};
      width: ${CHILDREN_WIDTH};
    }
  }
`;

export {
  StyledSettingPageContainer,
  StyledTabs,
  StyledTabComponentContainer
};
