import styled from 'styled-components';
import {Tag} from 'antd';
import Card from '../../../../Card';

const StyledTagsManagement = styled(Card)`
  .ant-card-body {
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
  }
`;

const StyledTag = styled(Tag)`
  font-size: 14px;
  margin-top: 8px;
  padding: 6px 8px;

  &.disabled {
    cursor: unset;
    opacity: 0.4;
    pointer-events: none;
  }
`;

export {StyledTagsManagement, StyledTag};
