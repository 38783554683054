import styled from 'styled-components';

const StyledTransactionDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTransactionDetailsHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  
  a {
    color: ${props => props.theme.colors.neutral_10};
    
    &:hover {
      color: ${props => props.theme.colors.neutral_7};
    }
  }

  .date,
  .category {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 14px;
    font-weight: 300;
  }

  .price {
    font-size: 20px;
    font-weight: 600;
    
    &.returned {
      color: ${props => props.theme.colors.neutral_7};
      text-decoration: line-through;
    }
  }
`;

export {
  StyledTransactionDetailsHeader,
  StyledTransactionDetailsHeaderItem
};
