import styled from 'styled-components';
import {Space} from 'antd';

const ICON_HEIGHT = '32px';

const StyledMenuSteps = styled.div`
  position: relative;
`;


const StyledMenuStepsItemIcon = styled.div`
  height: ${ICON_HEIGHT};
  width: ${ICON_HEIGHT};
  margin-bottom: 1px;
`;

const StyledMenuStepsItemLine = styled.div`
  background: ${props => props.theme.colors.neutral_5};
  height: calc(100% - ${ICON_HEIGHT});
  min-height: 16px;
  left: calc((${ICON_HEIGHT} / 2) - 1px);
  width: 2px;
  position: absolute;
`;

const StyledMenuStepsItemDetailsSpace = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  font-size: 16px;
  font-weight: 400;
  min-height: calc(${ICON_HEIGHT} + 18px);
  padding-bottom: 8px;
  width: 100%;
  & > div {
    width: 100%;
  }
  .title {
    line-height: 32px;
    font-weight: 500;
  }
  .description {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
`;

const StyledMenuStepsItem = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 3px;
  }
  
  &:last-of-type {
    ${StyledMenuStepsItemLine} {
      display: none;
    }
    ${StyledMenuStepsItemDetailsSpace} {
      min-height: unset;
      padding-bottom: 0;
    }
  }
  
  &:not(.active):not(.pending):not(.error):not(.finished) {
    svg {
      circle {
        &:nth-of-type(1) {
          stroke: ${props => props.theme.colors.neutral_5};
        }
        &:nth-of-type(2) {
          fill: ${props => props.theme.colors.neutral_5};
        }
      }
    }
  }
  
  &.active {
    ${StyledMenuStepsItemIcon} {
      svg {
        circle {
          &:nth-of-type(1) {
            stroke: ${props => props.theme.colors.primary_6};
          }
          &:nth-of-type(2) {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }
    }
  }
  
  &.active,
  &.finished {
    &:not(.error):not(.pending) {
      ${StyledMenuStepsItemLine} {
        background: ${props => props.theme.colors.primary_5};
      }
      ${StyledMenuStepsItemIcon} {
        svg {
          & > path {
            &:not([mask]) {
              fill: ${props => props.theme.colors.primary_6};
            }
            &[mask] {
              stroke: ${props => props.theme.colors.primary_6};
            }
          }
        }
      }
    }
  }
  &.error {
    ${StyledMenuStepsItemLine} {
      background: ${props => props.theme.colors.danger_d};
    }
    ${StyledMenuStepsItemIcon} {
      svg {
        circle {
          &:nth-of-type(1) {
            stroke: none;
            fill: ${props => props.theme.colors.danger};
          }
        }
      }
    }
    .title {
      color: ${props => props.theme.colors.danger};
    }
  }
  &.pending {
    ${StyledMenuStepsItemLine} {
      background: ${props => props.theme.colors.warning};
    }
    ${StyledMenuStepsItemIcon} {
      svg {
        circle {
          &:nth-of-type(1) {
            stroke: ${props => props.theme.colors.warning};
          }
          &:nth-of-type(2) {
            fill: ${props => props.theme.colors.warning};
          }
        }
        path {
          fill: ${props => props.theme.colors.warning};
        }
      }
    }
    .title {
      color: ${props => props.theme.colors.warning};
    }
  }
`;

const StyledMenuStepsItemSpace = styled(Space)`
  width: 100%;
  position: relative;
  & > .ant-space-item {
    &:last-of-type {
      width: 100%;
    }
  }
`;


export {
  StyledMenuSteps,
  StyledMenuStepsItem,
  StyledMenuStepsItemSpace,
  StyledMenuStepsItemDetailsSpace,
  StyledMenuStepsItemIcon,
  StyledMenuStepsItemLine
};
