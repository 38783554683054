import styled from 'styled-components';

const StyledBalanceDetails = styled.span`
  font-size: 14px;
  margin-right: 6px;

  b {
    font-weight: 600;
  }
`;

export {
  StyledBalanceDetails
};
