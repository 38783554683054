import {Button} from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: transparent;
  min-width: 96px;
  padding-left: 4px;
  padding-right: 4px;
`;

export {
  StyledButton
};
