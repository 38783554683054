import styled from 'styled-components';

const StyledPieChart = styled.div`
`;

const BarLegend = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 20px;

  & > div {
    font-size: 14px;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
`;

const ChartContainer = styled.div`
  position: relative;
`;

const StyledTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: ${props => props.theme.colors.neutral_10};
  font-size: 28px;


  label {
    color: ${props => props.theme.colors.neutral_7};
    font-size: 12px;
    font-weight: 500;
  }
`;

const StyledLegendDot = styled.span`
  display: inline-block;
  margin-right: 8px;
  height: 100%;
  position: relative;
  width: 6px;

  &:before {
    background-color: ${props => props.color};
    border-radius: 50%;
    content: ' ';
    display: inline-block;
    height: 6px;
    width: 6px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}`;


export {
  BarLegend,
  ChartContainer,
  StyledPieChart,
  StyledTotal,
  StyledLegendDot
}
