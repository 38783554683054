import styled, { css } from 'styled-components';

const imageWidth = {
  'xsmall': 32,
  'small': 46,
  'medium': 72,
  'large': 96
};

const getWidth = props => {
  return css`
    width: ${props => imageWidth[props.size]}px;
  `;
};

const animatedStyle = () => {
  return css`
    animation: spinner-load-bounce 2s infinite ease-in-out;
    transform-origin: 50% 50%;

    @keyframes spinner-load-bounce {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
};

const StyledSpinnerImage = styled.div`
  width: 100%;
  position: relative;
  & img {
    transform-origin: 50% 50%;
    ${props => animatedStyle(props)};
    ${props => getWidth(props)};
  }
`;

export { StyledSpinnerImage };
