import { combineReducers } from 'redux';
import alertReducer from './alert.reducer';
import authReducer from './auth.reducer';
import cardReducer from './card.reducer';
import companyReducer from './company.reducer';
import expenseReducer from './expense.reducer';
import mainReducer from './main.reducer';
import overviewReducer from './overview.reducer';
import subscriptionReducer from './subscription.reducer';
import transactionReducer from './transaction.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  card: cardReducer,
  company: companyReducer,
  expense: expenseReducer,
  main: mainReducer,
  overview: overviewReducer,
  subscription: subscriptionReducer,
  transaction: transactionReducer,
  user: userReducer
});

export default rootReducer;
