import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {StyledSubscriptionTable, StyledSubscriptionTableActions} from './StyledSubscriptionTable';
import {Button, Form, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {CheckOutlined, CloseOutlined, DeleteOutlined} from '@ant-design/icons';
import SubscriptionTableEditableCell from '../SubscriptionTableEditableCell';
import {helpers} from '../../../../../helpers';
import {subscriptionRowActionConstants} from '../../../../../constants';
import {subscriptionsHelpers} from '../../subscriptionsHelpers';
import {EditIcon, EyeIcon} from '../../../../../icons';

const gObjProp = helpers.getObjProp;

const {DELETE, EDIT, VIEW} = subscriptionRowActionConstants;

const SubscriptionTable = ({
  columns,
  data,
  getEditableMenuItems,
  editable,
  handleAction,
  onRow,
  onRowEdit,
  tableOrder,
  employeeEmail,
  isAdmin,
  ...rest
}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(data);
  const [editedRow, setEditedRow] = useState({id: '', data: null, disabled: false});
  const [initialFormValues, setInitialFormValues] = useState({});
  const [t] = useTranslation('subscriptions');

  const {id: editedRowId, disabled: editedRowDisabled} = editedRow;
  const isEditing = (record) => record.id === editedRowId;

  useEffect(() => {
    if (tableOrder) {
      const field = tableOrder?.field || '';
      const order = tableOrder.order;
      const tableColumns = columns.map(column => {
        if (column.dataIndex === field) {
          return {
            ...column,
            sortOrder: order
          }
        } else {
          return column;
        }
      });
      setTableColumns(tableColumns);
    } else {
      setTableColumns(columns);
    }
  }, [columns, tableOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setDataSource(data), [data]);

  const onCancel = () => setEditedRow({...editedRow, id: '', data: null, disabled: false});

  const onSuccess = () => form.submit();

  const onSubmit = (fields) => {
    if (onRowEdit) {
      const data = helpers.getUpdatedFormValues({
        initialValues: initialFormValues,
        submittedValues: fields
      });
      if (Object.keys(data).length > 0) {
        setEditedRow({...editedRow, disabled: true});
        onRowEdit({
          id: editedRowId,
          data,
          successCallback: onCancel,
          errorCallback: () => setEditedRow({...editedRow, disabled: false})
        });
      } else {
        onCancel();
      }
    }
  }

  const onEdit = (e, record) => {
    e.stopPropagation();

    const owner = gObjProp(record.owner, 'email');
    const fieldsValues = {
      status: record.status,
      tags: record.tags,
      owner: owner
    }
    setEditedRow({...editedRow, id: record.id, data: record});
    setInitialFormValues(fieldsValues);
    form.setFieldsValue(fieldsValues);
  }

  const isEnabledEdit = (record) => {
    let enabled;
    if (record.placeholder) {
      enabled = false;
    } else if (isAdmin) {
      enabled = true;
    } else {
      const subscriptionOwnerEmail = gObjProp(record.owner, 'email');
      enabled = subscriptionOwnerEmail === employeeEmail;
    }
    return enabled;
  }

  const actionButtonProps = {
    disabled: editedRowDisabled,
    size: 'small',
    type: 'text'
  }

  const action = (props) => subscriptionsHelpers.getDropdownMenuItem({t, ...props});

  const getTableColumns = () => {
    let columns = [...tableColumns];
    if (editable) {
      columns = [
        ...columns,
        {
          dataIndex: 'actions',
          style: {
            paddingLeft: 0,
            paddingRight: 0
          },
          width: 56,
          render: (_, record) => {
            let menuItems;
            const enabledEdit = isEnabledEdit(record);
            const editable = isEditing(record);

            if (getEditableMenuItems) {
              menuItems = getEditableMenuItems(record)
            } else {
              menuItems = [
                action({key: VIEW, icon: <EyeIcon />}),
                action({key: EDIT, icon: <EditIcon />, className: 'gray'}),
                action({key: DELETE, icon: <DeleteOutlined />, className: 'delete'})
              ];
            }

            if (!enabledEdit) menuItems = menuItems.filter(i => i.key !== EDIT);

            const menuProps = {
              items: menuItems,
              onClick: ({key, domEvent}) => {
                if (key === EDIT) {
                  onEdit(domEvent, record);
                } else {
                  handleAction && handleAction({action: key, id: record.id, e: domEvent})
                }
              }
            };

            return (
              <StyledSubscriptionTableActions className={editable && 'editable'}>
                {editable ? (
                  <Space size='small'>
                    <Button
                      {...actionButtonProps}
                      className='success'
                      icon={<CheckOutlined />}
                      onClick={onSuccess}
                    />
                    <Button
                      {...actionButtonProps}
                      className='cancel'
                      icon={<CloseOutlined />}
                      onClick={onCancel}
                    />
                  </Space>
                ) : subscriptionsHelpers.getDropdownMenu(menuProps)}
              </StyledSubscriptionTableActions>
            );
          }
        }
      ];
    }

    columns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          fieldType: col.fieldType,
          dataIndex: col.dataIndex,
          editing: isEditing(record),
          disabled: editedRowDisabled
        }),
      };
    });
    return columns;
  }

  const table = (
    <StyledSubscriptionTable
      components={{
        body: {
          cell: SubscriptionTableEditableCell,
        },
      }}
      columns={getTableColumns()}
      dataSource={dataSource}
      editable={editable}
      rowKey='id'
      onRow={editedRowId ? undefined : onRow}
      {...rest}
    />
  );

  return editable ? (
    <Form
      component={false}
      form={form}
      onFinish={onSubmit}
    >
      {table}
    </Form>
  ) : table;
}

SubscriptionTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      editable: PropTypes.bool
    })
  ),
  data: PropTypes.array,
  getEditableMenuItems: PropTypes.func,
  editable: PropTypes.bool,
  handleAction: PropTypes.func,
  onRow: PropTypes.func,
  onRowEdit: PropTypes.func,
  tableOrder: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ascend', 'descend'])
  })
}

SubscriptionTable.defaultProps = {
  columns: [],
  data: [],
  editable: false
}

const mapStateToProps = state => {
  let {isAdmin, employee} = state.user;
  return {
    employeeEmail: employee.email,
    isAdmin
  }
}

export default connect(mapStateToProps, null)(SubscriptionTable);
