import styled from 'styled-components';
import {Space} from 'antd';

const StyledDocumentUploadRules = styled(Space)`
  position: relative;
  width: 100%;
  font-size: 16px;
  
  p {
    color: ${props => props.theme.colors.neutral_7};
    margin-bottom: 0;
  }
`;

const StyledDocumentUploadRulesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledDocumentUploadRulesListItem = styled.li`
  color: ${props => props.theme.colors.neutral_10};
  line-height: 24px;
  
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  
  .ant-space-item {
    display: flex;
  }
  
  svg {
    height: 24px;
    width: 24px;
    
    path {
      fill: ${props => props.variant === 'success' ? props.theme.colors.success : props.theme.colors.danger};
    }
  }
`;

export {
  StyledDocumentUploadRules,
  StyledDocumentUploadRulesList,
  StyledDocumentUploadRulesListItem
};
