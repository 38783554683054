import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Spin} from 'antd';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import InvoiceTabs from '../../components/pages/InvoicesPage/InvoiceTabs';
import InvoiceForward from '../../components/pages/InvoicesPage/InvoiceForward';
import PageDocumentDetails from '../../components/PageDocumentDetails/PageDocumentDetails';
import {companyActions, invoicesActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {transactionsHelpers} from '../../components/pages/TransactionsPage/transactionsHelpers';

const gObjProp = helpers.getObjProp;

const {getInvoiceEmail} = transactionsHelpers;

const defaultInvoiceForwardProps = {
  loading: true,
  email: undefined
}

const InvoicesPage = ({
  getCompanySettings,
  getEmployees,
  getInvoicesTotal,
  invoiceEmail
}) => {
  const [t] = useTranslation(['invoices', 'main']);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [invoiceForwardProps, setInvoiceForwardProps] = useState(defaultInvoiceForwardProps);

  useEffect(() => getEmployees(), [getEmployees]);

  useEffect(() => {
    if (invoiceEmail) {
      setInvoiceForwardProps({...invoiceForwardProps, loading: false, email: invoiceEmail});
    } else {
      setInvoiceForwardProps({...invoiceForwardProps, loading: true});
      getCompanySettings(
        (settings) => {
          setInvoiceForwardProps({
            ...invoiceForwardProps,
            email: getInvoiceEmail(settings),
            loading: false
          })
        },
        () => setInvoiceForwardProps({...defaultInvoiceForwardProps, loading: false})
      )
    }
  }, [getCompanySettings, invoiceEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleUpdateTotal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const finishLoading = () => loading && setLoading(false);

  const handleUpdateTotal = () => {
    getInvoicesTotal(
      (data) => {
        setTotal({
          all: gObjProp(data, 'total_count', 0),
          matched: gObjProp(data, 'matched_count', 0),
          unmatched: gObjProp(data, 'unmatched_count', 0),
        });
        finishLoading();
      },
      finishLoading
    );
  }

  return (
    <>
      <PageDocumentDetails title={t('main:pageTitles.invoicesList')} />
      <BalancePageHeader breadcrumbs={[{title: t('title')}]} />
      <Spin spinning={total === null && loading}>
        <InvoiceForward {...invoiceForwardProps} />
        <InvoiceTabs
          handleUpdateTotal={handleUpdateTotal}
          total={total}
          totalLoading={loading}
        />
      </Spin>
    </>
  );
}

const mapDispatchToProps = {
  getCompanySettings: companyActions.getCompanySettings,
  getInvoicesTotal: invoicesActions.getInvoicesTotal,
  getEmployees: companyActions.getEmployees,
}

const mapStateToProps = state => {
  return {
    invoiceEmail: getInvoiceEmail(state.company.settings)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);
