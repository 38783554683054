import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import SubscriptionTooltip from '../SubscriptionTooltip';
import {
  StyledSubscriptionChart,
  StyledSubscriptionChartLegend,
  StyledSubscriptionChartLegendItem,
  StyledSubscriptionChartLegendSurface
} from './StyledSubscriptionChart';
import {helpers} from '../../../../helpers';
import {overviewActions} from '../../../../state/actions';
import theme from '../../../../theme';

const {colors} = theme;

const tickColor = colors.neutral_6;
const actualColor = colors.primary_4;
const forecastColor = colors.tertiary_4;

const CustomizedAxisTick = (props) => {
  const { x, y, payload, getPriceValue, className, textAnchor } = props;
  const {value} = payload;
  const text = getPriceValue ? getPriceValue(value) : `€${payload.value}`;
  return (
    <text
      className={className}
      dy={4}
      fontSize={12}
      fill={tickColor}
      textAnchor={textAnchor}
      x={x}
      y={y}
    >
      {text}
    </text>
  )
}

const CustomizedDot = (props) => {
  const { cx, cy, payload, propName, fill } = props;
  if (!helpers.isNaV(payload[propName])) {
    return (
      <circle
        r='3.5'
        cx={cx}
        cy={cy}
        fill={fill}
      />
    );
  } else {
    return false;
  }
};

const defaultTooltipProps = {
  actualSpend: 0,
  forecastSpend: 0,
  loading: false,
  services: []
};


const axisProps = {
  stroke: tickColor,
  tickLine: false
};

const lineProps = {
  activeDot: {r: 5},
  fillOpacity: 0,
  strokeWidth: 2,
  type: 'linear'
};

const SubscriptionChart = ({
  data,
  getDailySubscriptions,
  expectedServices
}) => {
  const [t] = useTranslation('main');
  const [tooltipProps, setTooltipProps] = useState(defaultTooltipProps);
  const [spendingServices, setSpendingServices] = useState({});
  const [forecastServices, setForecastServices] = useState({});
  const [activeLabel, setActiveLabel] = useState(null);

  const getPrice = (value) => helpers.getMoneyView(value, undefined, undefined, true, false);

  const getFormattedServices = (services) => services.map(s => {
    const {value} = s;
    return {
      name: s.service,
      logo: s?.service_logo || '',
      value
    }
  });

  const lineChartMargin = useMemo(() => {
    let left = 0;
    const maxSpendingValue = Math.max(...data.map(d => Math.max(d.expected || 0, d.current || 0))) || 0;
    const maxSpendingValueLength = String(parseInt(maxSpendingValue)).length;
    if (maxSpendingValueLength > 4) {
      left = {
        5: 5,
        6: 10,
        7: 15,
      }[maxSpendingValueLength] || 20;
    }
    return {
      bottom: 0,
      left,
      top: 0,
      right: 0,
    }
  }, [data]);

  useEffect(() => {
    if (expectedServices) {
      let forecastServices = {};
      Object.keys(expectedServices).forEach(key => {
        forecastServices[key] = getFormattedServices(expectedServices[key]);
      })
      setForecastServices(forecastServices);
      // clear spending services
      if (!helpers.arraysIsEqual(expectedServices, forecastServices)) setSpendingServices({});
    }
  }, [expectedServices]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideTooltip = () => setTooltipProps(defaultTooltipProps);

  const handleOnMouseMove = (props) => {
    const {activeLabel: actLabel, isTooltipActive} = props;
    if (activeLabel === actLabel || !isTooltipActive) return;
    setActiveLabel(actLabel);
    handleDisplayTooltipContent(actLabel);
  }

  const getSpends = (selectedDay) => {
    let actualSpend = 0;
    if (!selectedDay.hasOwnProperty('current')) {
      const lastActualSpend = data.findLast(d => d.hasOwnProperty('current'));
      if (lastActualSpend) actualSpend = lastActualSpend.current;
    } else {
      actualSpend = selectedDay.current
    }
    return {
      actualSpend,
      forecastSpend: helpers.getObjProp(selectedDay, 'expected', 0)
    }
  }

  const calculateServices = (services) => {
    const actuals = services.find(s => s.name === 'actual');
    const forecasts = services.find(s => s.name === 'forecast');

    if (actuals && actuals.data && forecasts) {
      const servicesList = [...actuals.data];
      actuals.data = servicesList.map(service => {
        const {name, value} = service;
        const forecastSpend = forecasts.data.find(d => d.name === name);
        return {
          ...service,
          direction: forecastSpend && forecastSpend.value !== value ? value > forecastSpend.value ? 'up' : 'bottom' : null
        }
      });
    }
    return services;
  }

  const handleDisplayTooltipContent = (name) => {
    let previousItem;
    const index = data.findIndex(d => d.name === name);
    if (index >= 0) {
      const currentItem = data.find(d => d.name === name)
      const {current: currentPrice} = currentItem;
      const spends = getSpends(currentItem);
      let isNeedCall = true;

      const showTooltip = (services) => {
        setTooltipProps({
          ...tooltipProps,
          ...spends,
          loading: false,
          services: calculateServices(services)
        });
      }

      if (index > 0) {
        previousItem = data[index - 1];
        // check if current day price is the same as previous day
        if (previousItem.current === currentPrice) {
          isNeedCall = false;
        }
      } else if (index === 0 && currentPrice === 0) {
        // not need call if current day price is null
        isNeedCall = false;
      }
      const forecastServicesList = {name: 'forecast', data: forecastServices[name]};

      if (isNeedCall) {
        // call the API endpoint only if the current day has not been called before
        if (spendingServices.hasOwnProperty(name)) {
          showTooltip([{name: 'actual', data: spendingServices[name]}, forecastServicesList]);
        } else {
          setTooltipProps({...tooltipProps, loading: true});
          getDailySubscriptions(
            currentItem.date,
            null,
            (data) => {
              const dailyServices = getFormattedServices(data);
              showTooltip([{name: 'actual', data: dailyServices}, forecastServicesList]);
              // save current services to avoid repeated calls on the same day in the future
              setSpendingServices({
                ...spendingServices,
                [name]: dailyServices
              });
            },
            hideTooltip
          )
        }
      } else {
        showTooltip([forecastServicesList, {name: 'actual', data: spendingServices[name]}]);
      }
    }
  }

  return (
    <StyledSubscriptionChart
      direction='vertical'
      size='small'
    >
      <ResponsiveContainer
        height={330}
        width='100%'
      >
        <LineChart
          data={data}
          height={200}
          margin={lineChartMargin}
          onMouseMove={handleOnMouseMove}
          width={500}
        >
          <CartesianGrid
            strokeDasharray='4 4'
            strokeOpacity={1}
            strokeWidth={0.5}
          />
          <YAxis
            {...axisProps}
            orientation='left'
            padding={{top: 20, bottom: 0}}
            tick={<CustomizedAxisTick getPriceValue={getPrice} />}
            tickCount={9}
            tickSize={12}
          />
          <XAxis
            {...axisProps}
            dataKey='name'
            fontSize={14}
            padding={{left: 10, right: 10}}
            tickSize={8}
          />
          <Line
            {...lineProps}
            dataKey='expected'
            fill={forecastColor}
            stroke={forecastColor}
            strokeDasharray='5 5'
            dot={(props) => <CustomizedDot propName='expected' fill={forecastColor} {...props} />}
          />
          <Line
            {...lineProps}
            dataKey='current'
            fill={actualColor}
            stroke={actualColor}
            dot={(props) => <CustomizedDot propName='current' fill={actualColor} {...props} />}
          />
          <Tooltip
            animationEasing='ease'
            content={<SubscriptionTooltip {...tooltipProps} />}
            cursor={{ stroke: '#000', opacity: 0.6 }}
            offset={0}
          />
        </LineChart>
      </ResponsiveContainer>
      <StyledSubscriptionChartLegend>
        <Space size='middle'>
          <StyledSubscriptionChartLegendItem>
            <StyledSubscriptionChartLegendSurface className='actual' />
            {t('actual')}
          </StyledSubscriptionChartLegendItem>
          <StyledSubscriptionChartLegendItem>
            <StyledSubscriptionChartLegendSurface className='forecasted' />
            {t('forecasted')}
          </StyledSubscriptionChartLegendItem>
        </Space>
      </StyledSubscriptionChartLegend>
    </StyledSubscriptionChart>
  );
}

SubscriptionChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    expected: PropTypes.number.isRequired,
    current: PropTypes.number
  })),
  expectedServices: PropTypes.object
}

SubscriptionChart.defaultProps = {
  data: [],
  expectedServices: {}
}

const mapDispatchToProps = {
  getDailySubscriptions: overviewActions.getDailySubscriptions
}

export default connect(null, mapDispatchToProps)(SubscriptionChart);
