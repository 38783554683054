const SCAActionsConstants = Object.freeze({
  OVERVIEW_CHARTS_AND_TABLES_LOADING_ACTION: 'overview_charts_and_tables_loading',
  BALANCE_LOADING: 'long_sca_session',
  WALLET_DETAILS_LOADING_ACTION: 'user_wallet_details_loading',
  ADD_FUNDS_LOADING: 'add_funds_loading',
  // subscription action
  SUBSCRIPTION_CARD_CREATION_ACTION: 'subscription_card_creation',
  SUBSCRIPTION_CARD_RECREATE_ACTION: 'subscription_card_recreate',
  SUBSCRIPTION_CREATION_WITH_CARD_ACTION: 'subscription_creation_with_card',
  SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION: 'subscription_bulk_creation_with_card',
  SUBSCRIPTION_CHANGE_CARD_ACTION: 'subscription_change_card',
  SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION: 'subscription_change_payment_method',
  SUBSCRIPTION_CHANGE_STATUS_ACTION: 'subscription_change_status',
  SUBSCRIPTION_DELETE: 'subscription_delete',
  SUBSCRIPTION_SUBMIT: 'subscription_submit',
  SUBSCRIPTION_SUBMIT_IN_CREATION: 'subscription_submit_in_creation',
  // transaction actions
  TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION: 'transaction_older_90_days_loading',
  TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION: 'long_sca_session',
  TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION: 'transactions_download_pdf_and_csv',
  // card actions
  CARD_EDITION_ACTION: 'card_edition',
  LOCK_CARD_ACTION: 'lock_card',
  UNLOCK_CARD_ACTION: 'unlock_card',
  DISPLAY_CARD_DETAILS_ACTION: 'display_card_details',
  // user actions
  DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION: 'disable_user_with_treezor_account',
  ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION: 'enable_user_with_treezor_account'
});

const SCASessionTypesConstants = Object.freeze({
  LONG_SESSION: 'long_session', // long session means 90 days
  FIVE_MINUTES_SESSION: 'session', // session means every 5 minutes
  PER_OPERATION_SESSION: 'operation' // operation means every time
});

export {
  SCAActionsConstants,
  SCASessionTypesConstants
};
