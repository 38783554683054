import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import KeylessAuthModal from '../KeylessAuthModal';
import KeylessEnrollModal from '../KeylessEnrollModal';
import SmsAuthModal from '../SmsAuthModal';
import {helpers} from '../../../../helpers';
import {scaHelpers} from '../../../../scaHelpers';

const defaultModalProps = {
  keylessEnroll: false,
  keyless: false,
  sms: false
};

const AuthenticationWindow = ({
  authModalProps,
  isEnrolled,
  handleCancel,
  onSuccess,
  open,
  operationName
}) => {
  const [openModal, setOpenModal] = useState(defaultModalProps);

  useEffect(() => {
    if (open) {
      let keylessEnroll = false;
      let keyless = false;
      let sms = false;
      if (isEnrolled) {
        keyless = true;
      } else {
        keylessEnroll = true;
      }
      setOpenModal({
        ...openModal,
        keylessEnroll,
        keyless,
        sms
      });
    } else if (!open && !helpers.arraysIsEqual(openModal, defaultModalProps)) {
      setOpenModal(defaultModalProps);
    }
  }, [open, isEnrolled]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSendCode = () => setOpenModal({...openModal, keylessEnroll: false, keyless: false, sms: true});

  const handleOnSuccess = (status, {headerType, sessionKey}) => {
    if (onSuccess && status === 'success') {
      operationName && scaHelpers.storeOperationToken({headerType, operationName, sessionKey});
      onSuccess({
        headers: {
          'sca-header-type': headerType,
          'sca-session-key': sessionKey
        },
        sessionKey
      });
    }
  }

  const handleOnSuccessKeylessAuth = (status, message) => {
    if (status === 'success') {
      handleOnSuccess(
        status,
        {
          headerType: 'keyless',
          sessionKey: helpers.getObjProp(message, 'session_key')
        }
      );
    }
  }

  const handleOnSuccessSmsAuth = (token) =>
    handleOnSuccess('success', {headerType: 'sms', sessionKey: token});

  const handleFinishEnroll = () => setOpenModal({...openModal, keyless: true, keylessEnroll: false, sms: false});

  return (
    <>
      <KeylessEnrollModal
        onSendCode={onSendCode}
        onCancel={handleCancel}
        open={openModal.keylessEnroll}
        onFinish={handleFinishEnroll}
      />

      <SmsAuthModal
        {...authModalProps}
        open={openModal.sms}
        handleCancel={handleCancel}
        onSuccess={handleOnSuccessSmsAuth}
        operationName={operationName}
      />

      <KeylessAuthModal
        {...authModalProps}
        open={openModal.keyless}
        handleCancel={handleCancel}
        onButtonClick={onSendCode}
        onSuccess={handleOnSuccessKeylessAuth}
        operationName={operationName}
      />
    </>
  );
}

AuthenticationWindow.propTypes = {
  authModalProps: PropTypes.object,
  isEnrolled: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func
}

AuthenticationWindow.defaultProps = {
  isEnrolled: false
}

export default AuthenticationWindow;
