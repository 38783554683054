import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {serviceStatsActions} from '../../state/actions';
import {StyledOAuthCallbackPage, StyledOAuthCallbackPageLoading} from './StyledOAuthCallbackPage';

const OAuthCallbackPage = ({
  verifyOauth
}) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const trans = (key) => t(`quickStart:oauthCallback.${key}`);
  const location = useLocation();
  const [verification, setVerification] = useState({loading: true, success: false});

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    if (searchParams.state) {
      verifyOauth(
        'google',
        searchParams,
        () => setVerification({loading: false, success: true}),
        () => setVerification({loading: false, success: false})
      );
    }
  }, [location, verifyOauth]);

  return (
    <StyledOAuthCallbackPage>
      <h1>{trans('title1')}<br />{trans('title2')}</h1>
      {verification.loading ? (
        <StyledOAuthCallbackPageLoading />
      ) : verification.success ? (
        <>
          <p>{trans('description1')}</p>
          <div className='redirect-description'>
            {trans('description2')} <a href='/'>{trans('linkText')}</a>.
          </div>
        </>
      ) : (
        <div className='redirect-description'>
          {trans('errorDescription')}.
        </div>
      )}
    </StyledOAuthCallbackPage>
  );
}

const mapDispatchToProps = {
  verifyOauth: serviceStatsActions.verifyOauth
}

export default connect(null, mapDispatchToProps)(OAuthCallbackPage);

