import styled from 'styled-components';
import {Dropdown, Menu, Space} from 'antd';

const StyledNavigation = styled.div`
  background-color: #F9F9F9;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  color: ${props => props.theme.colors.neutral_1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
`;

const DropdownContainer = styled.div`
  padding: 8px;
  transition: padding .3s ease;
  &.collapsed {
    padding: 8px 8px 8px 6px;
  }
`;

const CompanyDetails = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  gap: 10px !important;
  font-size: 16px;
  font-weight: 500;
`;

const BottomNavigation = styled.div`
  padding: 16px 24px;
`;

const StyledMenu = styled(Menu)`
  background-color: transparent;
  margin-top: 16px;
  &.ant-menu-inline {
    border-inline-end: none !important;
  }
  &.ant-menu-inline-collapsed {
    border-inline-end: none !important;
    width: 72px;
    li.ant-menu-item {
      & > svg+span {
        display: inline-block;
        opacity: 0;
      }
    }
  }
  .ant-menu-title-content {
    font-weight: 500
  }
  li.ant-menu-item {
    & > svg {
      margin-right: 10px;
      width: 14px;
      & path {
        transition: .3s ease;
      }
    }

    &.ant-menu-item-selected {
      background-color: ${props => props.theme.colors.primary_2};
      color: ${props => props.theme.colors.primary_6};
      & > svg {
        & path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }
    &.ant-menu-item-disabled {
      & > svg {
        & path {
          fill: rgba(0, 0, 0, 0.25)!important;
        }
      }
    }
    &:not(.ant-menu-item-selected) {
      &:focus,&:active {
        background-color: ${props => props.theme.colors.primary_2} !important;
      }
    }
  }
`;

const StyledDropdownMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background-color: transparent;
    box-shadow: unset;
    margin-top: 16px;
    padding: 0;

    li.ant-dropdown-menu-item {
      padding: 9px 5px;
      & > svg {
        margin-right: 16px;
        width: 22px;
        & path {
          transition: .3s ease;
          &[stroke-linecap="round"] {
            stroke: ${props => props.theme.colors.neutral_6}
          }
          &:not([stroke-linecap="round"]) {
            fill: ${props => props.theme.colors.neutral_6}
          }
        }
      }
      &.ant-dropdown-menu-item-selected {
        background-color: ${props => props.theme.colors.primary_2};
        color: ${props => props.theme.colors.primary_6};
        & > svg {
          & path {
            &[stroke-linecap="round"] {
              stroke: ${props => props.theme.colors.primary_6}
            }
            &:not([stroke-linecap="round"]) {
              fill: ${props => props.theme.colors.primary_6}
            }
          }
        }
      }
      &.ant-dropdown-menu-item-disabled {
        & > svg {
          & path {
            fill: rgba(0, 0, 0, 0.25)!important;
          }
        }
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  padding: 12px 16px;
  transition: .3s ease;

  &:hover {
    border: 1px solid ${props => props.theme.colors.primary_5};
    cursor: pointer;
  }

  & .ant-space-item {
    display: flex;
    transition: .3s ease;
  }
  &.collapsed {
    border-color: transparent;
    & .ant-space-item {
      &:not(:first-of-type) {
        display: inline-block;
        opacity: 0;
      }
    }
  }
  &:not(.collapsed).ant-dropdown-open {
    background: linear-gradient(0deg, ${props => props.theme.colors.primary_2}, ${props => props.theme.colors.primary_2}),
                linear-gradient(0deg, ${props => props.theme.colors.primary_5}, ${props => props.theme.colors.primary_5});
    border: 1px solid ${props => props.theme.colors.primary_5};
  }
`;

const DropdownContent = styled.div`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_4};
  border-radius: ${props => props.theme.border_radius.standard};
  box-shadow: 0 9px 28px 8px #0000000D,
              0 6px 16px 0 #00000014,
              0 3px 6px -4px #0000001F;
  & > header {
    padding: 16px;
  }

  & > footer {
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 8px;

    button {
      width: calc(100% - 16px);
    }
  }
`;

const CompaniesList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 140px;
  margin-bottom: 8px;
  overflow-x: auto;
  padding: 0 4px 0 4px;
  width: 100%;

  & > div {
    border-radius: ${props => props.theme.border_radius.standard};
    padding: 8px 4px;
    transition: .3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
  }
`;

export {
  BottomNavigation,
  CompanyDetails,
  CompaniesList,
  DropdownContainer,
  DropdownContent,
  StyledDropdown,
  StyledMenu,
  StyledNavigation,
  StyledDropdownMenu
}
