import styled from 'styled-components';
import {Space} from 'antd';

const StyledTeamsTabContainer = styled(Space)`
  position: relative;
  width: 100%;
  
  .employee-details {
    font-weight: 400;
    line-height: 16px;
    .employee-details-name {
      color: ${props => props.theme.colors.neutral_10};
      font-size: 14px;
      line-height: 20px;
    }
    .employee-details-email {
      color: ${props => props.theme.colors.neutral_7};
      line-height: 12px;
    }
  }
  
  .ant-avatar-group {
    display: flex;
  }
  
  .actions {
    .ant-btn {
      &.primary {
        color: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

export {
  StyledTeamsTabContainer,
};
