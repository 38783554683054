import styled from 'styled-components';


const StyledToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledToolbarItem = styled.div`
  padding: 4px 8px;
  & > * {
    &:hover {
      cursor: pointer;
    }
  }
  &.notified {
    padding: 8px;
  }
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  & .ant-badge-count {
    transform: translate(60%, -65%);
    width: max-content;
  }
  & .ant-btn-icon-only {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    height: 24px;
    min-width: 24px;
    padding: 0;
    width: 24px;

    & .anticon {
      font-size: 14px;
    }

    &:not(:disabled):not(.ant-btn-disabled) {
      &:hover, &:active {
        background-color: ${props => props.theme.colors.primary_3};
        border-color: transparent;
        color: ${props => props.theme.colors.primary_7};
      }
    }
  }

  &.open {
    & .ant-btn-icon-only {
      &:not(:disabled):not(.ant-btn-disabled) {
        background-color: ${props => props.theme.colors.primary_3};
        border-color: transparent;
        color: ${props => props.theme.colors.primary_7};
      }
    }
  }
`;

export {
  StyledToolbar,
  StyledToolbarItem
};
