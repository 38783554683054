import React from 'react';

const H1LogoShortIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="11" y="11" width="13" height="13">
      <path
        d="M17.3435 11.856C14.1829 11.856 11.6259 14.432 11.6259 17.616C11.6259 20.8 14.1829 23.376 17.3435 23.376C20.5041 23.376 23.0611 20.8 23.0611 17.616V11.856H17.3435Z"
        fill="white"/>
    </mask>
    <g mask="url(#mask0_9_4)">
      <mask id="mask1_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
            height="4040">
        <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
      </mask>
      <g mask="url(#mask1_9_4)">
        <path d="M23.5059 11.408H11.1732V23.832H23.5059V11.408Z" fill="#0A145A"/>
      </g>
    </g>
    <mask id="mask2_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <path
        d="M5.90027 0.33606C2.73968 0.33606 0.182617 2.91206 0.182617 6.09606C0.182617 9.28006 2.73968 11.8561 5.90027 11.8561H11.6179V6.09606C11.6179 2.91206 9.06085 0.33606 5.90027 0.33606Z"
        fill="white"/>
    </mask>
    <g mask="url(#mask2_9_4)">
      <mask id="mask3_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
            height="4040">
        <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
      </mask>
      <g mask="url(#mask3_9_4)">
        <path d="M12.0626 -0.112061H-0.27002V12.3119H12.0626V-0.112061Z" fill="#0A145A"/>
      </g>
    </g>
    <mask id="mask4_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="11" y="0" width="13" height="12">
      <path
        d="M17.3435 0.33606H11.6259V6.09606C11.6259 9.28006 14.1829 11.8561 17.3435 11.8561C20.5041 11.8561 23.0611 9.28006 23.0611 6.09606C23.0611 2.91206 20.5041 0.33606 17.3435 0.33606Z"
        fill="white"/>
    </mask>
    <g mask="url(#mask4_9_4)">
      <mask id="mask5_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
            height="4040">
        <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
      </mask>
      <g mask="url(#mask5_9_4)">
        <path d="M23.5059 -0.112061H11.1732V12.3119H23.5059V-0.112061Z" fill="#0A145A"/>
      </g>
    </g>
    <mask id="mask6_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="11" width="12" height="13">
      <path
        d="M5.90027 11.856C2.73968 11.856 0.182617 14.432 0.182617 17.616C0.182617 20.8 2.73968 23.376 5.90027 23.376H11.6179V17.616C11.6179 14.432 9.06085 11.856 5.90027 11.856Z"
        fill="white"/>
    </mask>
    <g mask="url(#mask6_9_4)">
      <mask id="mask7_9_4" style={{mask: "luminance"}} maskUnits="userSpaceOnUse" x="-43" y="-20" width="647"
            height="4040">
        <path d="M603.085 -20H-42.8824V4019.64H603.085V-20Z" fill="white"/>
      </mask>
      <g mask="url(#mask7_9_4)">
        <path d="M12.0626 11.408H-0.27002V23.832H12.0626V11.408Z" fill="#0A145A"/>
      </g>
    </g>
  </svg>
);

export default H1LogoShortIcon;
